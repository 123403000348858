import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Badge, Button, Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { faFileClipboard, faFileText, faMessage, faStar } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import useApp from "../../utils/useApp";

interface CaseCardState {
  myCase: any;
  assessFilter: boolean;
  msgsFilter: boolean;
  nextFilter: boolean;
  caseStatus: string;
}

const CaseCard = ({ myCase, assessFilter, msgsFilter, nextFilter, caseStatus }: CaseCardState) => {
  const { auth }: any = useApp();
  let navigate = useNavigate();

  const editMyCase = (e: any) => {
    navigate(`/my-cases/${e.id}`);
  };

  const onClickAssessDue = (e: any) => {
    e.stopPropagation();
    navigate(`/my-cases/${myCase.id}?key=assessment`);
  };

  const onClickUnreadMessages = (e: any) => {
    e.stopPropagation();
    navigate(`/my-cases/${myCase.id}?key=messages`);
  };

  const onClickTaskReview = (e: any) => {
    e.stopPropagation();
    navigate(`/my-cases/${myCase.id}?key=goals`);
  };

  const onClickNotes = (e: any) => {
    e.stopPropagation();
    navigate(`/my-cases/${myCase.id}?key=notes`);
  };

  return (
    <Card
      className={caseStatus === "active" ? "card-cases mx-2 mt-3 button" : "card-cases-inactive mx-2 mt-3 button"}
      onClick={(e) => editMyCase(myCase)}
    >
      <Card.Body>
        <Card.Title className={"mt-0 mb-0 app-header-text text-truncate"}>{myCase.name}</Card.Title>
        <hr className="m-0" />
        <Row className="ps-2">
          <Col className="col-8 p-0">
            {auth?.profile?.role !== "support" && caseStatus === "active" && (
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Go To Assessments</Tooltip>}>
                <Button variant="link" className="text-decoration-none p-0 button-none" onClick={onClickAssessDue}>
                  <Card.Text
                    className="mt-2 mb-0 card-alert-text"
                    style={
                      myCase.assessment_due && assessFilter
                        ? { color: "#DA7E39" }
                        : myCase.assessment_due
                        ? { color: "#000000" }
                        : { color: "#b1bfc4" }
                    }
                  >
                    <FontAwesomeIcon icon={faFileClipboard} /> Assessment Due
                  </Card.Text>
                </Button>
              </OverlayTrigger>
            )}
            {caseStatus === "active" && (
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Go To Messages</Tooltip>}>
                <Button variant="link" className="text-decoration-none p-0 button-none text-truncate" onClick={onClickUnreadMessages}>
                  <Card.Text
                    className="mt-0 mb-0 card-alert-text"
                    style={
                      myCase.new_messages && msgsFilter
                        ? { color: "#DA7E39" }
                        : myCase.new_messages
                        ? { color: "#000000" }
                        : { color: "#b1bfc4" }
                    }
                  >
                    <FontAwesomeIcon icon={faMessage} /> Unread Messages
                  </Card.Text>
                </Button>
              </OverlayTrigger>
            )}
            {auth?.profile?.role !== "support" && caseStatus === "active" && (
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Go To Goals</Tooltip>}>
                <Button variant="link" className="text-decoration-none p-0 button-none text-truncate" onClick={onClickTaskReview}>
                  <Card.Text
                    className="mt-0 mb-0 card-alert-text"
                    style={
                      myCase.task_review && nextFilter
                        ? { color: "#DA7E39" }
                        : myCase.task_review
                        ? { color: "#000000" }
                        : { color: "#b1bfc4" }
                    }
                  >
                    <FontAwesomeIcon icon={faStar} /> Next Task Review
                  </Card.Text>
                </Button>
              </OverlayTrigger>
            )}
          </Col>
        </Row>
        {auth?.profile?.role !== "support" && (
          <Row className="position-absolute bottom-0 end-0">
            <Col className="text-end p-2 me-3">
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Go To Notes</Tooltip>}>
                <Button onClick={onClickNotes} className="button button-smaller app-tertiary-color p-0">
                  <FontAwesomeIcon icon={faFileText} />
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
        )}
        <Row className="position-absolute bottom-0">
          <Col className="text-end p-2">
            <Badge bg="secondary">{myCase?.primaryCM?.user_name}</Badge>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CaseCard;
