import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { postAssessment } from "../../../../services/httpClient";
import LikertScale from "./LikertScale";

interface WellBeingState {
  setViewAssessments: any;
  setCreateAssessmentWell: any;
  setCreateAssessmentCont: any;
  setCreateAssessmentCustom: any;
  fetchAssessments: any;
  readOnly?: boolean;
  questions: any;
  results?: any;
  setShowToast?: any;
  setToastText?: any;
  setToastBody?: any;
}

const AssessmentWellBeing = ({
  setViewAssessments,
  setCreateAssessmentWell,
  setCreateAssessmentCont,
  setCreateAssessmentCustom,
  fetchAssessments,
  readOnly = false,
  questions,
  results,
  setShowToast,
  setToastText,
  setToastBody,
}: WellBeingState) => {
  const { id } = useParams();
  const [likertValues, setLikertValues] = useState<any[]>([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const mainRef = useRef();

  const handleScroll = (ref: any) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  };

  useEffect(() => {
    if (readOnly) {
      const count = Object.keys(results.fields).length;
      var likertList = [];
      for (let i = 1; i <= count; i++) {
        var obj = {
          key: i,
          value: results.fields[i],
        };
        likertList.push(obj);
      }
      setLikertValues(likertList);
    } else {
      const likertVals = questions?.question.map((q: any) => {
        return {
          key: q.key,
          value: null,
        };
      });
      setLikertValues(likertVals);
    }

    handleScroll(mainRef);
  }, []);

  useEffect(() => {
    if (likertValues.length > 0) {
      if (likertValues.every((item) => item.value !== null && item.value !== undefined && item.value !== "")) {
        setSubmitDisabled(false);
      }
    }
  }, [likertValues]);

  const onClickSubmit = () => {
    const assessSubmission = {
      assessmenttype: "well_being",
      fields: likertValues.reduce((acc, item) => {
        acc[item.key] = item.value;
        return acc;
      }, {}),
    };

    postAssessment(assessSubmission, id).then((res: any) => {
      setViewAssessments(true);
      setCreateAssessmentWell(false);
      setCreateAssessmentCont(false);
      setCreateAssessmentCustom(false);
      fetchAssessments();
      setShowToast(true);
      setToastText("Assessment Created");
      setToastBody("Well Being");
    });
  };

  const beginText = [
    "Not Satisfied At All",
    "Extremely Unhappy",
    "Poor",
    "Poor",
    "Not At All Worthwhile",
    "Strongly Disagree",
    "Not True of Me",
    "Not True of Me",
    "Strongly Disagree",
    "Strongly Disagree",
    "Worry All of the Time",
    "Worry All of the Time",
  ];

  const endText = [
    "Completely Satisfied",
    "Extremely Happy",
    "Excellent",
    "Excellent",
    "Completely Worthwhile",
    "Strongly Agree",
    "Completely True of Me",
    "Completely True of Me",
    "Strongly Agree",
    "Strongly Agree",
    "Do Not Ever Worry",
    "Do Not Ever Worry",
  ];

  return (
    <>
      <Row ref={mainRef} className="my-0 py-0"></Row>
      {questions?.question.map((q: any, index: number) => (
        <Card className="bg-light">
          <Card.Body>
            <Row>
              <Row>
                <h5 className="pb-2 app-header-text">
                  <label className="app-orange-text">{index + 1 + "."}</label>
                  {" " + q.question}
                </h5>
              </Row>
              <LikertScale
                fields={q.fields}
                keyValue={likertValues[index]?.key}
                value={likertValues[index]?.value}
                setValues={setLikertValues}
                beginText={beginText[index] ? beginText[index] : ""}
                endText={endText[index] ? endText[index] : ""}
                readOnly={readOnly}
              />
            </Row>
          </Card.Body>
        </Card>
      ))}
      {!readOnly ? (
        <Row className="text-center pb-5">
          <Col></Col>
          <Col className="col-4">
            <Button className="app-secondary-color button" disabled={submitDisabled} onClick={() => onClickSubmit()}>
              Submit Results
            </Button>
          </Col>
          <Col></Col>
        </Row>
      ) : (
        <Row className="pb-5"></Row>
      )}
    </>
  );
};

export default AssessmentWellBeing;
