import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { Task } from "../../../types/task";
import PageTitle from "../../ui/PageTitle";
import { Goal } from "../../../types/goal";
import { deleteTaskTemplate, fetchGoalTemplates, postGoalTemplate, postTaskTemplate } from "../../../services/httpClient";
import useApp from "../../../utils/useApp";
import GoalTemplateCard from "./GoalTemplateCard";
import SearchBar from "../../ui/SearchBar";
import { trackDataEvent } from "../../../utils/analytics";
import CreateGoalCard from "../../ui/CreateGoalCard";
import SaveChangesModal from "../../ui/SaveChangesModal";

interface OrgTemplateState {
  searchRef: any;
  setOrgToSave: any;
  getData: boolean;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
}

const OrgGoalTemplates = ({ searchRef, setOrgToSave, getData, setShowToast, setToastText, setToastBody }: OrgTemplateState) => {
  const { auth, showMenu }: any = useApp();
  const [showCreateGoal, setShowCreateGoal] = useState<boolean>(false);
  const [goalId, setGoalId] = useState<string>("");
  const [tasks, setTasks] = useState<Task[]>([]);
  const [goalSaved, setGoalSaved] = useState<boolean>(false);
  const [goalTemplates, setGoalTemplates] = useState<Goal[]>([]);
  const [goalTemplatesFiltered, setGoalTemplatesFiltered] = useState<Goal[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (getData) {
      getGoalTemplates(true);
    }
  }, [getData]);

  useEffect(() => {
    getGoalTemplates(true);
  }, [auth?.profile]);

  useEffect(() => {
    trackDataEvent("filter", "goaltemplate_search");
  }, [searchText]);

  const getGoalTemplates = (loading: boolean) => {
    setIsLoading(loading);

    fetchGoalTemplates(auth?.profile?.userOrgID)
      .then((res: any) => {
        var temps = res[0].data;
        temps = temps.map((t: any) => {
          return { ...t, key: t.id };
        });
        setGoalTemplates(temps);
        setGoalTemplatesFiltered(temps);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onClickCreateGoal = () => {
    setShowCreateGoal(true);
  };

  const onClickCreateGoalDone = () => {
    setShowCreateGoal(false);
    getGoalTemplates(false);
  };

  const onClickSaveGoal = (goalName: string) => {
    const newGoal = {
      name: goalName,
    };

    postGoalTemplate(newGoal, auth?.profile?.userOrgID).then((res: any) => {
      setGoalId(res[0].data.goal_template_id);
      setGoalSaved(true);
      setShowToast(true);
      setToastText("Goal Template Created");
      setToastBody(goalName);
    });
  };

  const onClickRemoveTask = (t: Task) => {
    console.log(t);
    deleteTaskTemplate(auth?.profile?.userOrgID, goalId, t.id).then((res: any) => {
      setTasks((oldValues) => {
        return oldValues.filter((task) => task.name !== t.name);
      });
      setShowToast(true);
      setToastText("Task Deleted");
      setToastBody(t.name);
    });
  };

  const onClickSaveTask = (task: string) => {
    const newTask = {
      name: task,
    };

    postTaskTemplate(newTask, auth?.profile?.userOrgID, goalId).then((res: any) => {
      const newTaskId = {
        id: res[0].data.task_template_id,
        name: task,
      };
      setTasks([...tasks, newTaskId]);
      setShowToast(true);
      setToastText("Task Created");
      setToastBody(task);
    });
  };

  const onPerformSearchLogic = (text: string) => {
    const lowerText = text.toLowerCase();

    var myFiltered: any;

    var myCases = goalTemplates;

    //filter by name of case
    myFiltered = myCases?.filter((item: any) => {
      return item.name.toLowerCase().match(lowerText);
    });
    console.log(myFiltered);
    setGoalTemplatesFiltered(myFiltered);
  };

  return (
    <>
      {isLoading ? (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <>
          <PageTitle
            title="Goal Templates"
            type="section"
            buttonColor="primary"
            buttonTitle={showCreateGoal ? "Done" : "Create Template"}
            onButtonClick={showCreateGoal ? onClickCreateGoalDone : onClickCreateGoal}
          />
          <Row className="mb-3"></Row>
          {showCreateGoal && (
            <CreateGoalCard
              onClickSaveGoal={onClickSaveGoal}
              goalSaved={goalSaved}
              setGoalSaved={setGoalSaved}
              tasks={tasks}
              setTasks={setTasks}
              onClickRemoveTask={onClickRemoveTask}
              onClickSaveTask={onClickSaveTask}
            />
          )}
          {showCreateGoal && (
            <>
              <Row className="mb-5"></Row>
              <PageTitle title="Edit/View Templates" type="section" />
            </>
          )}
          <Row className="mt-3 mb-4">
            <Col xs={12}>
              <SearchBar
                cssClass="search"
                placeholder="Search Goal Template"
                searchText={searchText}
                setSearchText={setSearchText}
                searchLogic={onPerformSearchLogic}
                externalRef={searchRef}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12}>
              {goalTemplatesFiltered?.length === 0 ? (
                <p className="ps-3">No Current Templates</p>
              ) : (
                goalTemplatesFiltered.map((g: Goal, index: number) => (
                  <GoalTemplateCard
                    key={index}
                    goal={g}
                    getGoalTemplates={getGoalTemplates}
                    setOrgToSave={setOrgToSave}
                    setShowToast={setShowToast}
                    setToastText={setToastText}
                    setToastBody={setToastBody}
                  />
                ))
              )}
            </Col>
          </Row>
          <SaveChangesModal showModal={showModal} setShowModal={setShowModal} path="/settings" />
        </>
      )}
    </>
  );
};

export default OrgGoalTemplates;
