import React, { useEffect, useState } from "react";
import { currentUrl, unsavedChanges } from "../../App";
import { useLocation } from "react-router-dom";
import { Button, Col, Container, Row, Spinner, Stack } from "react-bootstrap";
import { fetchBeds, postBed } from "../../services/httpClient";
import useApp from "../../utils/useApp";
import Input from "../ui/Input";
import PageTitle from "../ui/PageTitle";
import BedManagementCard from "./BedManagementCard";

interface BedManagementState {
  setOrgToSave: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
  getData: boolean;
}

const BedManagement = ({ setOrgToSave, setShowToast, setToastText, setToastBody, getData }: BedManagementState) => {
  const location = useLocation();
  currentUrl.value = location.pathname;
  const [errMsg, setErrMsg] = useState("");
  const [beds, setBeds] = useState<any[]>([]);
  const [newBed, setNewBed] = useState<string>("");
  const [showAddBed, setShowAddBed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (getData) {
      setIsLoading(true);
      getBeds();
    }
  }, [getData]);

  const getBeds = () => {
    fetchBeds()
      .then((res: any) => {
        const bedList = res[0].data;
        setBeds(bedList);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onClickAddBed = () => {
    setShowAddBed(true);
  };

  const updateNewBed = (e: any) => {
    setNewBed(e.target.value);
    unsavedChanges.value = true;
    setOrgToSave(true);
  };

  const onClickSaveBed = (isDone: boolean = false) => {
    const bedObject = {
      name: newBed,
    };

    postBed(bedObject).then((res: any) => {
      setShowToast(true);
      setToastText("Bed Created");
      setToastBody(newBed);
      setNewBed("");
      unsavedChanges.value = false;
      setOrgToSave(false);
      if (isDone) {
        setShowAddBed(false);
      }
      getBeds();
    });
  };

  const onClickCancelAddBed = () => {
    setShowAddBed(false);
    setNewBed("");
    unsavedChanges.value = false;
    setOrgToSave(false);
  };

  return (
    <>
      {isLoading ? (
        <Container>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <>
          <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p>
          <>
            <PageTitle title="Bed Management" type="section" />
            <Row className="mt-2">
              <Col xs={12} lg={10} xl={8}>
                {beds.length === 0 ? (
                  <small>No Beds Created</small>
                ) : (
                  beds?.map((item: any, index: number) => (
                    <Row key={index}>
                      <Col xs={12}>
                        <Row className="mb-1"></Row>
                        <BedManagementCard
                          item={item}
                          getItems={getBeds}
                          setOrgToSave={setOrgToSave}
                          setErrMessage={setErrMsg}
                          setShowToast={setShowToast}
                          setToastText={setToastText}
                          setToastBody={setToastBody}
                        />
                      </Col>
                    </Row>
                  ))
                )}
              </Col>
            </Row>
            {showAddBed !== true && (
              <Row className="mt-3">
                <Col xs={4}>
                  <Button className="app-tertiary-color button" onClick={(e) => onClickAddBed()}>
                    Add Bed
                  </Button>
                </Col>
              </Row>
            )}
            {showAddBed === true && (
              <>
                <Row className="align-items-center my-3">
                  <Col xs={12} lg={10} xl={8}>
                    <Input
                      label="New Bed Name"
                      type="text"
                      value={newBed}
                      onUpdate={(e: any) => updateNewBed(e)}
                      hasFocus={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} lg={10} xl={8}>
                    <Stack direction="horizontal" gap={3}>
                      <Button className="app-secondary-color button" onClick={() => onClickSaveBed(false)}>
                        Save and Add Another
                      </Button>
                      <div className="vr" />
                      <Button className="app-secondary-color button" onClick={() => onClickSaveBed(true)}>
                        Save and Done
                      </Button>
                      <div className="vr" />
                      <Button className="app-primary-color button" onClick={() => onClickCancelAddBed()}>
                        Done
                      </Button>
                    </Stack>
                  </Col>
                </Row>
              </>
            )}
            <Row className="mb-5"></Row>
          </>
        </>
      )}
    </>
  );
};

export default BedManagement;
