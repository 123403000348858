import React, { useEffect, useRef, useState } from "react";
import { currentUrl } from "../../App";
import { useLocation } from "react-router-dom";
import { Button, Col, Container, InputGroup, Row, Spinner } from "react-bootstrap";
import { fetchCheckInOut, fetchUserCases } from "../../services/httpClient";
import CheckInCard from "./CheckInCard";
import SearchBar from "../ui/SearchBar";
import { signal } from "@preact/signals-react";

interface CheckState {
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
  getData: boolean;
}

export const resetCards = signal("");

const CheckIn = ({ setShowToast, setToastText, setToastBody, getData }: CheckState) => {
  const location = useLocation();
  currentUrl.value = location.pathname;
  const [errMsg, setErrMsg] = useState("");
  const [allPeople, setAllPeople] = useState<any[]>([]);
  const [filteredAllPeople, setFilteredAllPeople] = useState<any[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [viewIn, setViewIn] = useState<boolean>(false);
  const [viewOut, setViewOut] = useState<boolean>(false);
  const [viewAll, setViewAll] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (getData) {
      setIsLoading(true);
      setViewIn(false);
      setViewOut(false);
      setViewAll(true);
      getActivePeople(true);
    }
  }, [getData]);

  const getActivePeople = async (reload: boolean) => {
    if (reload) {
      setIsLoading(true);
    }

    return new Promise((resolve) => {
      fetchUserCases()
        .then((res: any) => {
          var cases = res[0].data;
          var activecases = cases.filter((c: any) => c.casestatus === "active");

          fetchCheckInOut()
            .then((res: any) => {
              const checks = res[0].data;

              var allPpl = activecases
                .flatMap((caseObj: any) => [
                  ...caseObj.family.map((f: any) => ({
                    ...f,
                    name: f.user_name,
                    id: f.user_id,
                    type: "guardian",
                    check_id: checks.some((c: any) => c.person_type === "guardian" && c.person_id === f.user_id)
                      ? checks.find((c: any) => c.person_type === "guardian" && c.person_id === f.user_id)?.id
                      : 0,
                    is_checked_out: checks.some((c: any) => c.person_type === "guardian" && c.person_id === f.user_id)
                      ? checks.find((c: any) => c.person_type === "guardian" && c.person_id === f.user_id)?.is_checked_out
                      : 1,
                    reason:
                      checks.some((c: any) => c.person_type === "guardian" && c.person_id === f.user_id) &&
                      checks.find((c: any) => c.person_type === "guardian" && c.person_id === f.user_id)?.reason,
                    check_date:
                      checks.some((c: any) => c.person_type === "guardian" && c.person_id === f.user_id) &&
                      checks.find((c: any) => c.person_type === "guardian" && c.person_id === f.user_id)?.check_date,
                  })),
                  ...caseObj.members.map((m: any) => ({
                    ...m,
                    name: m.member_name,
                    type: "member",
                    check_id: checks.some((c: any) => c.person_type === "member" && c.person_id === m.id)
                      ? checks.find((c: any) => c.person_type === "member" && c.person_id === m.id)?.id
                      : 0,
                    is_checked_out: checks.some((c: any) => c.person_type === "member" && c.person_id === m.id)
                      ? checks.find((c: any) => c.person_type === "member" && c.person_id === m.id)?.is_checked_out
                      : 1,
                    reason:
                      checks.some((c: any) => c.person_type === "member" && c.person_id === m.id) &&
                      checks.find((c: any) => c.person_type === "member" && c.person_id === m.id)?.reason,
                    check_date:
                      checks.some((c: any) => c.person_type === "member" && c.person_id === m.id) &&
                      checks.find((c: any) => c.person_type === "member" && c.person_id === m.id)?.check_date,
                    _checked_out: false,
                  })),
                ])
                .sort((a: any, b: any) => a.name.localeCompare(b.name));
              //convert time to local
              allPpl = allPpl.map((a: any) => {
                if (a?.check_date) {
                  const utcDate = new Date(a?.check_date + " UTC");
                  const localTime = utcDate.toLocaleString(undefined, {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  });

                  return { ...a, check_date: localTime };
                } else {
                  return a;
                }
              });
              allPpl = allPpl.filter((p: any) => p.is_banned !== 1);
              setAllPeople(allPpl);
              if (searchText) {
                const lowerText = searchText.toLowerCase();
                setFilteredAllPeople(searchLogicByPeople(lowerText, allPpl));
              } else {
                setFilteredAllPeople(allPpl);
              }

              setIsLoading(false);
              resolve("completed");
            })
            .catch((err: any) => {
              console.log(err);
              setIsLoading(false);
            });
        })
        .catch((err: any) => {
          console.log(err);
          setIsLoading(false);
        });
    });
  };

  const onPerformSearchLogicByPerson = (text: string) => {
    setViewIn(false);
    setViewOut(false);
    setViewAll(true);
    const lowerText = text.toLowerCase();

    setFilteredAllPeople(searchLogicByPeople(lowerText, allPeople));
  };

  const searchLogicByPeople = (text: string, searchObject: any) => {
    var myFiltered: any = searchObject?.filter((item: any) => {
      return item.name.toLowerCase().match(text);
    });
    return myFiltered;
  };

  const onClickCheckedIn = () => {
    resetCards.value = "in";
    setViewIn(true);
    setViewOut(false);
    setViewAll(false);
    setSearchText("");
    const checkedInPpl = allPeople.filter((p: any) => p.is_checked_out === 0);
    setFilteredAllPeople(checkedInPpl);
  };

  const onClickCheckedOut = () => {
    resetCards.value = "out";
    setViewIn(false);
    setViewOut(true);
    setViewAll(false);
    setSearchText("");
    const checkedInPpl = allPeople.filter((p: any) => p.is_checked_out === 1);
    setFilteredAllPeople(checkedInPpl);
  };

  const onClickViewAll = () => {
    resetCards.value = "all";
    setViewIn(false);
    setViewOut(false);
    setViewAll(true);
    setSearchText("");
    setFilteredAllPeople(allPeople);
  };

  return (
    <>
      {isLoading ? (
        <Container>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <>
          <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p>
          <>
            <Row className="mb-0">
              <Col className="col-12">
                <h4 className="app-header-text">Check In / Check Out</h4>
              </Col>
            </Row>
            <Row className="mt-3 mb-4">
              <Col xs={12}>
                <InputGroup>
                  <Col>
                    <SearchBar
                      cssClass="search"
                      placeholder="Search Person Name"
                      searchText={searchText}
                      setSearchText={setSearchText}
                      searchLogic={onPerformSearchLogicByPerson}
                      externalRef={searchRef}
                    />
                  </Col>
                  <small className="medium-text app-header-text me-2 align-self-center">View By</small>
                  <Button
                    className={
                      viewIn
                        ? "app-primary-color me-2 button button-icon-filter rounded"
                        : "app-primary-color-inverse me-2 button button-icon-filter rounded"
                    }
                    onClick={() => onClickCheckedIn()}
                  >
                    In
                  </Button>
                  <Button
                    className={
                      viewOut
                        ? "app-primary-color me-2 button button-icon-filter rounded align-items-center justify-content-center d-flex"
                        : "app-primary-color-inverse me-2 button button-icon-filter rounded align-items-center justify-content-center d-flex"
                    }
                    onClick={() => onClickCheckedOut()}
                  >
                    Out
                  </Button>
                  <Button
                    className={
                      viewAll
                        ? "app-primary-color me-2 button button-icon-filter rounded"
                        : "app-primary-color-inverse me-2 button button-icon-filter rounded"
                    }
                    onClick={() => onClickViewAll()}
                  >
                    All
                  </Button>
                </InputGroup>
              </Col>
            </Row>
            <Row className="justify-content-center mb-5">
              <Container className="card-container">
                {filteredAllPeople.length === 0 ? (
                  <small>No Active Members</small>
                ) : (
                  filteredAllPeople?.map((item: any, index: number) => (
                    <CheckInCard
                      key={index}
                      person={item}
                      getActivePeople={getActivePeople}
                      setShowToast={setShowToast}
                      setToastText={setToastText}
                      setToastBody={setToastBody}
                    />
                  ))
                )}
              </Container>
            </Row>
          </>
        </>
      )}
    </>
  );
};

export default CheckIn;
