import React, { useState } from "react";
import { Col, ListGroupItem, Row } from "react-bootstrap";
import QuestionPreview from "./QuestionPreview";

const AssessmentCardQuestion = ({ question }: any) => {
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [questionNumber, setQuestionNumber] = useState<number>((question.key as number) - 1);

  const onClickQuestion = (question: any) => {
    setShowPreview(!showPreview);
  };

  return (
    <ListGroupItem key={question?.id} className="mb-2 mx-3 cursor-pointer" onClick={() => onClickQuestion(question)}>
      {!showPreview && (
        <Row>
          <Col xs={10}>{question?.question}</Col>
        </Row>
      )}
      {showPreview && (
        <QuestionPreview qIndex={questionNumber} question={question?.question} likertValues={question?.fields} hideHeading={true} />
      )}
    </ListGroupItem>
  );
};

export default AssessmentCardQuestion;
