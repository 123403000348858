import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Tab, Nav } from "react-bootstrap";
import PageTitle from "../ui/PageTitle";
import ToastMsg from "../ui/ToastMsg";
import useApp from "../../utils/useApp";
import { trackNavigationEvent } from "../../utils/analytics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faBullseye, faClipboardQuestion, faUserFriends } from "@fortawesome/free-solid-svg-icons";
import OrgGoalTemplates from "./goaltemplates/OrgGoalTemplates";
import OrgDetails from "./details/OrgDetails";
import useMenuToggle from "../../utils/useMenuToggle";
import OrgHousehold from "./householdconfig/OrgHousehold";
import OrgCustomAssessments from "./assessments/OrgCustomAssessments";

const Settings = () => {
  const { showMenu }: any = useApp();
  const toggleMenu = useMenuToggle();
  const [activeDetails, setActiveDetails] = useState<boolean>(false);
  const [activeHousehold, setActiveHousehold] = useState<boolean>(false);
  const [activeGoals, setActiveGoals] = useState<boolean>(false);
  const [activeAssessTypes, setActiveAssessTypes] = useState<boolean>(false);
  const [orgToSave, setOrgToSave] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");
  const searchRef = useRef<HTMLInputElement>(null);
  //get active tab data states
  const [getHouseholdData, setGetHouseholdData] = useState<boolean>(false);
  const [getTemplateData, setGetTemplateData] = useState<boolean>(false);
  const [getAssessTypeData, setGetAssessTypeData] = useState<boolean>(false);

  useEffect(() => {
    setActiveDetails(true);
    setActiveHousehold(false);
    setActiveGoals(false);
    setActiveAssessTypes(false);

    trackNavigationEvent("switch_view", "org_details");
  }, []);

  const onNavClick = (route: string) => {
    if (route) {
      trackNavigationEvent("switch_view", "org_" + route);
    }

    switch (route) {
      case "details":
        setActiveDetails(true);
        setActiveHousehold(false);
        setActiveGoals(false);
        setActiveAssessTypes(false);
        setGetHouseholdData(false);
        setGetTemplateData(false);
        setGetAssessTypeData(false);
        break;
      case "household":
        setActiveDetails(false);
        setActiveHousehold(true);
        setActiveGoals(false);
        setActiveAssessTypes(false);
        setGetHouseholdData(true);
        setGetTemplateData(false);
        setGetAssessTypeData(false);
        break;
      case "goals":
        setActiveDetails(false);
        setActiveHousehold(false);
        setActiveGoals(true);
        setActiveAssessTypes(false);
        setGetHouseholdData(false);
        setGetTemplateData(true);
        setGetAssessTypeData(false);
        break;
      case "assessments":
        setActiveDetails(false);
        setActiveHousehold(false);
        setActiveGoals(false);
        setActiveAssessTypes(true);
        setGetHouseholdData(false);
        setGetTemplateData(false);
        setGetAssessTypeData(true);
        break;
      default:
        setActiveDetails(true);
        setActiveHousehold(false);
        setActiveGoals(false);
        setActiveAssessTypes(false);
        setGetHouseholdData(false);
        setGetTemplateData(false);
        setGetAssessTypeData(false);
        break;
    }
  };

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
        <Tab.Container defaultActiveKey={"details"}>
          <Row>
            <Col xs={12} md={3}>
              <Nav
                variant="pills"
                className={showMenu ? "flex-column sticky-top mycase-menu-open-sticky" : "flex-column sticky-top mycase-menu-sticky"}
              >
                <Nav.Item className="mb-2">
                  <PageTitle title={"Organization"} />
                </Nav.Item>
                <Nav.Item className="d-grid">
                  <Nav.Link
                    eventKey="details"
                    className={activeDetails ? "nav-vertical-active" : "nav-vertical"}
                    onClick={() => onNavClick("details")}
                    disabled={orgToSave === true ? true : false}
                  >
                    <>
                      <FontAwesomeIcon icon={faBriefcase} className={"me-2"} />
                      {toggleMenu ? "Details" : "Details"}
                    </>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-grid">
                  <Nav.Link
                    eventKey="household"
                    className={activeHousehold ? "nav-vertical-active" : "nav-vertical"}
                    onClick={() => onNavClick("household")}
                    disabled={orgToSave === true ? true : false}
                  >
                    <>
                      <FontAwesomeIcon icon={faUserFriends} className={"me-2"} />
                      {toggleMenu ? "Household Config" : "Household"}
                    </>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-grid">
                  <Nav.Link
                    eventKey="goals"
                    className={activeGoals ? "nav-vertical-active" : "nav-vertical"}
                    onClick={() => onNavClick("goals")}
                    disabled={orgToSave === true ? true : false}
                  >
                    <>
                      <FontAwesomeIcon icon={faBullseye} className={"me-2"} />
                      {toggleMenu ? "Goal Templates" : "Templates"}
                    </>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-grid">
                  <Nav.Link
                    eventKey="assessments"
                    className={activeAssessTypes ? "nav-vertical-active" : "nav-vertical"}
                    onClick={() => onNavClick("assessments")}
                    disabled={orgToSave === true ? true : false}
                  >
                    <>
                      <FontAwesomeIcon icon={faClipboardQuestion} className={"me-2"} />
                      {toggleMenu ? "Custom Assessments" : "Assessments"}
                    </>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col xs={12} md={9} className="mt-2">
              <Tab.Content>
                <Tab.Pane eventKey="details">
                  <OrgDetails
                    setOrgToSave={setOrgToSave}
                    setShowToast={setShowToast}
                    setToastText={setToastText}
                    setToastBody={setToastBody}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="household">
                  <OrgHousehold
                    setOrgToSave={setOrgToSave}
                    getData={getHouseholdData}
                    setShowToast={setShowToast}
                    setToastText={setToastText}
                    setToastBody={setToastBody}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="goals">
                  <OrgGoalTemplates
                    searchRef={searchRef}
                    setOrgToSave={setOrgToSave}
                    getData={getTemplateData}
                    setShowToast={setShowToast}
                    setToastText={setToastText}
                    setToastBody={setToastBody}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="assessments">
                  <OrgCustomAssessments
                    setOrgToSave={setOrgToSave}
                    getData={getAssessTypeData}
                    setShowToast={setShowToast}
                    setToastText={setToastText}
                    setToastBody={setToastBody}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
};

export default Settings;
