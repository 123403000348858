import { faAdd, faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, ListGroup, Row, Stack } from "react-bootstrap";
import { postAssessmentType } from "../../../services/httpClient";
import { unsavedChanges } from "../../../App";
import Input from "../../ui/Input";
import SelectObject from "../../ui/SelectObject";
import AssessmentQuestionItem from "./AssessmentQuestionItem";
import QuestionPreview from "./QuestionPreview";
import { trackFeatureEvent } from "../../../utils/analytics";
import FormCheck from "../../ui/FormCheckbox";

interface AssessmentState {
  getAssessTypes: any;
  answerScales: any;
  setOrgToSave: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
}

const CreateAssessmentCard = ({
  getAssessTypes,
  answerScales,
  setOrgToSave,
  setShowToast,
  setToastText,
  setToastBody,
}: AssessmentState) => {
  const [allowSaveAssessment, setAllowSaveAssessment] = useState<boolean>(false);
  const [assessmentName, setAssessmentName] = useState<string>();
  const [selectedScale, setSelectedScale] = useState<number>(0);
  const [enableAddQuestion, setEnableAddQuestion] = useState<boolean>(false);
  const [newQuestion, setNewQuestion] = useState<string>("");
  const [likertValues, setLikertValues] = useState<any[]>([]);
  const [useDefaultLikertValues, setUseDefaultLikertValues] = useState<boolean>(false);
  const [questions, setQuestions] = useState<any[]>([]);
  const [showAddQuestion, setShowAddQuestion] = useState<boolean>(true);
  const [showAnswerKeyValues, setShowAnswerKeyValues] = useState<boolean>(false);
  const [showQuestions, setShowQuestions] = useState<boolean>(false);
  const [showQuestionPreview, setShowQuestionPreview] = useState<boolean>(false);

  const resetLikertValues = (scaleId: number) => {
    const fieldSet = answerScales.filter((a: any) => a.id == scaleId);
    setLikertValues(fieldSet[0].fields);
  };

  const updateAssessmentName = (e: any) => {
    setAssessmentName(e.target.value);

    unsavedChanges.value = true;
    setOrgToSave(true);
  };

  const updateScale = (e: any) => {
    setSelectedScale(e.target.value as number);
    resetLikertValues(e.target.value as number);
    setShowQuestionPreview(true);

    unsavedChanges.value = true;
    setOrgToSave(true);
  };

  const onClickShowAddQuestion = () => {
    setNewQuestion("");
    if (!useDefaultLikertValues) {
      resetLikertValues(selectedScale);
    }
    setShowAddQuestion(true);
  };

  const updateQuestion = (e: any) => {
    setNewQuestion(e.target.value);
    setEnableAddQuestion(true);
    setShowQuestionPreview(true);
  };

  const onClickAddQuestion = () => {
    setQuestions((prev) => [...prev, { question: newQuestion, fields: likertValues }]);

    setEnableAddQuestion(false);
    setNewQuestion("");

    if (!useDefaultLikertValues) {
      resetLikertValues(selectedScale);
    }

    setShowQuestions(true);
    setShowAnswerKeyValues(false);
    setShowQuestionPreview(false);

    unsavedChanges.value = true;
    setOrgToSave(true);
  };

  const onClickCancelQuestion = () => {
    setNewQuestion("");
    if (!useDefaultLikertValues) {
      resetLikertValues(selectedScale);
    }

    setShowAddQuestion(false);
    setEnableAddQuestion(false);
    setShowQuestionPreview(false);
  };

  useEffect(() => {
    if (assessmentName && questions.length > 0 && selectedScale > 0) {
      setAllowSaveAssessment(true);
    } else {
      setAllowSaveAssessment(false);
    }
  }, [assessmentName, questions, selectedScale]);

  const onClickCustomKeys = () => {
    if (selectedScale) {
      setShowAnswerKeyValues(true);
    }
  };

  const updateAnswerKey = (e: any, index: number) => {
    setLikertValues((prev) => prev.map((item) => (item.value === index ? { ...item, value_description: e.target.value } : item)));
  };

  const onClickSave = () => {
    //get only the custom fields
    const filteredQuestions = questions.map((question) => ({
      ...question,
      fields: question.fields.filter((field: any) => field.value_description != field.value),
    }));

    const newType = {
      name: assessmentName,
      questions: filteredQuestions.map((q: any, index: number) => ({
        key: index + 1,
        question: q?.question,
        field_set_id: selectedScale,
        fields: q?.fields,
        order: index + 1,
      })),
    };

    console.log(newType);

    postAssessmentType(newType).then((res: any) => {
      setAllowSaveAssessment(false);
      unsavedChanges.value = false;
      setOrgToSave(false);
      trackFeatureEvent("button_click", "create_custom_assessment");
      getAssessTypes();
      setShowToast(true);
      setToastText("Assessment Created");
      setToastBody(assessmentName);
    });
  };

  const updateDefault = () => {
    setUseDefaultLikertValues(!useDefaultLikertValues);
  };

  const onClickQuestionSubmit = (e: any) => {
    e?.preventDefault();
    onClickAddQuestion();
  };

  return (
    <>
      <Card className="bg-light mb-3">
        <Card.Body>
          <Row className="mb-3">
            <Col xs={12}>
              <Input
                label="Assessment Name"
                type="textarea"
                value={assessmentName}
                onUpdate={(e: any) => updateAssessmentName(e)}
                hasFocus={true}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12}>
              <SelectObject label="Answer Scale" value={selectedScale} options={answerScales} onUpdate={(e: any) => updateScale(e)} />
            </Col>
          </Row>
          {questions?.length > 0 && (
            <>
              <Row>
                <h6 className="cursor-pointer ms-1" onClick={() => setShowQuestions(!showQuestions)}>
                  Questions <FontAwesomeIcon icon={!showQuestions ? faAngleRight : faAngleDown} className="ms-2" />
                </h6>
              </Row>
              {showQuestions && (
                <Row className="mb-2 mx-0">
                  <ListGroup className="px-0">
                    {questions.map((q: any, index: number) => (
                      <AssessmentQuestionItem
                        key={index}
                        question={q}
                        index={index}
                        setQuestions={setQuestions}
                        setShowAddQuestion={setShowAddQuestion}
                      />
                    ))}
                  </ListGroup>
                </Row>
              )}
            </>
          )}
          <Row className="mb-0">
            {showAddQuestion ? (
              <Col xs={12}>
                <h6 className="cursor-pointer ms-1">Create New Question</h6>
                <Form onSubmit={(e: any) => onClickQuestionSubmit(e)}>
                  <Stack direction="horizontal" gap={3}>
                    <Stack style={{ justifyContent: "center" }} className="col-6">
                      <Input label="Question" type="text" value={newQuestion} onUpdate={(e: any) => updateQuestion(e)} />
                    </Stack>
                    <Button
                      type="submit"
                      className="app-secondary-color button"
                      disabled={!enableAddQuestion}
                      onClick={() => onClickAddQuestion()}
                    >
                      Add
                    </Button>
                    <div className="vr" />
                    <Button className="app-primary-color button" onClick={() => onClickCancelQuestion()}>
                      Cancel
                    </Button>
                  </Stack>
                </Form>
                {selectedScale > 0 &&
                  (!showAnswerKeyValues ? (
                    <h6 className="app-tertiary-color-inverse cursor-pointer bg-light mt-2 mb-3 ms-1" onClick={() => onClickCustomKeys()}>
                      {"Edit Answer Key Descriptions"}
                    </h6>
                  ) : (
                    <Stack direction="horizontal" className="mt-3">
                      <h6 className="ms-1 me-3">Answer Key Descriptions</h6>
                      <FormCheck label="Set as Default" value={useDefaultLikertValues} onUpdate={(e: any) => updateDefault()} />
                    </Stack>
                  ))}

                {showAnswerKeyValues && (
                  <Stack style={{ justifyContent: "center" }} className="col-12" gap={3}>
                    {[...Array(likertValues.length)].map((_, index) => (
                      <Stack direction="horizontal" gap={3} className="col-8">
                        <Col>
                          <Input
                            label="Answer Key Value"
                            type="text"
                            value={likertValues[index]?.value_description}
                            onUpdate={(e: any) => updateAnswerKey(e, index)}
                          />
                        </Col>
                      </Stack>
                    ))}
                  </Stack>
                )}
              </Col>
            ) : (
              <>
                <Col xs={6}>
                  <p className="ps-3">{questions.length > 0 ? "" : "No Questions Added"}</p>
                </Col>
                <Col xs={6} className="text-end">
                  <Button className="app-tertiary-color button" onClick={() => onClickShowAddQuestion()}>
                    <FontAwesomeIcon icon={faAdd} />
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Card.Body>
      </Card>
      {showQuestionPreview && <QuestionPreview qIndex={questions.length} question={newQuestion} likertValues={likertValues} />}
      <Row>
        <Col className="text-center">
          <Button className="button app-primary-color button" onClick={() => onClickSave()} disabled={!allowSaveAssessment}>
            Create Custom Assessment
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default CreateAssessmentCard;
