import { faCommentMedical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, ListGroup, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  fetchAddlMessages,
  fetchConversations,
  fetchMessages,
  fetchUserCaseGuardians,
  fetchUserCaseSupports,
} from "../../../../services/httpClient";
import ConversationListItem from "./ConversationListItem";
import CreateGroup from "./CreateGroup";
import CurrentConversation from "./CurrentConversation";
import useApp from "../../../../utils/useApp";
import { User } from "../../../../types/user";

interface MessagesState {
  currentConversation: any;
  setCurrentConversation: any;
  setNewMessage?: any;
  primaryCM: any;
  secondaryCM: any;
  getData?: boolean;
  myCase: boolean;
}

const Messages = ({
  currentConversation,
  setCurrentConversation,
  setNewMessage,
  primaryCM,
  secondaryCM,
  getData,
  myCase = false,
}: MessagesState) => {
  const { id } = useParams();
  const { auth }: any = useApp();
  const [conversations, setConversations] = useState([{ id: 0, name: "", participants: [auth?.profile.id] }]);
  const [conversationList, setConversationList] = useState([{ id: 0, name: "", participants: [auth?.profile.id] }]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentMsgs, setCurrentMsgs] = useState<any[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [showConversation, setShowConversation] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [checkedUsers, setCheckedUsers] = useState<User[]>([]);

  useEffect(() => {
    if (getData) {
      setIsLoading(true);
      getConversations();
      let convInterval = setInterval(() => {
        getConversations();
      }, 30000);

      return () => {
        clearInterval(convInterval);
      };
    }
  }, [getData]);

  const getConversations = () => {
    fetchConversations(id)
      .then((res: any) => {
        const convList = res[0].data;
        const newMessageExists = convList.some((c: any) => c.new_messages === 1);

        if (auth?.profile?.role !== "support") {
          setNewMessage(newMessageExists);
        }

        setConversations(convList);
        getPossibleUsers();
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    conversations.sort((a: any, b: any) => {
      if (a.participants.length === b.participants.length) {
        return a.id - b.id;
      } else {
        return a.participants.length - b.participants.length;
      }
    });

    //Conversation List
    var convList = conversations;

    convList = convList.map((g: any) => {
      var names = g?.participants?.filter((p: any) => {
        return p.id !== auth?.profile.id;
      });

      var nameCount = 1;
      var nameString = "";
      names?.map((n: any) => {
        if (nameCount < names.length) {
          nameString += n.firstname + " " + n.lastname + ", ";
        } else {
          nameString += n.firstname + " " + n.lastname;
        }
        nameCount++;
        return names;
      });

      return { ...g, recipients: nameString };
    });

    setConversationList(convList);
  }, [conversations]);

  const onClickConversation = (conv: any) => {
    getMessages(conv);
    getConversations();
    setCurrentConversation(conv);
    setShowConversation(true);
    setShowEmojiPicker(false);
  };

  const getMessages = async (conv: any, firstId?: any) => {
    if (firstId) {
      await fetchAddlMessages(id, conv.id, firstId)
        .then((res: any) => {
          const msgList = res[0].data;
          var msgListUpdated: any[] = [];
          msgList.map((t: any) => {
            msgListUpdated = [
              ...msgListUpdated,
              {
                ...t,
                display_time: formatMessageTime(t.sent_datetime),
                display_date: formatMessageDate(t.sent_datetime),
              },
            ];
            return msgListUpdated;
          });

          setCurrentIndex(msgListUpdated.length);
          setCurrentMsgs([...msgListUpdated, ...currentMsgs]);
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else {
      await fetchMessages(id, conv.id)
        .then((res: any) => {
          const msgList = res[0].data;
          var msgListUpdated: any[] = [];
          msgList.map((t: any) => {
            msgListUpdated = [
              ...msgListUpdated,
              {
                ...t,
                display_time: formatMessageTime(t.sent_datetime),
                display_date: formatMessageDate(t.sent_datetime),
              },
            ];
            return msgListUpdated;
          });

          setCurrentIndex(msgListUpdated.length);
          setCurrentMsgs(msgListUpdated);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  function formatMessageDate(dateTime: string) {
    const utcTimeString = dateTime;

    const utcDate = new Date(utcTimeString);
    // const dtf = new Intl.DateTimeFormat();

    //const timeZone = dtf.resolvedOptions().timeZone;
    const utcOffsetMinutes = utcDate.getTimezoneOffset();
    const utcOffsetHours = utcOffsetMinutes / 60;

    // Calculate the time in the local time zone
    const localTime = new Date(utcDate.getTime() - utcOffsetHours * 60 * 60 * 1000);

    // Define an array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Define an array of weekday names
    const weekdayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    // Get the components of the date
    const weekday = weekdayNames[localTime.getDay()];
    const month = monthNames[localTime.getMonth()];
    const day = localTime.getDate();

    // Construct the formatted date string
    const formattedDate = `${weekday}, ${month} ${day}`;
    return formattedDate;
  }

  const getPossibleUsers = () => {
    fetchUserCaseGuardians(id)
      .then((res: any) => {
        var guardianList = res[0].data;
        //remove banned guardians
        guardianList = guardianList.filter((g: any) => g?.is_banned !== 1);

        fetchUserCaseSupports(id)
          .then((res: any) => {
            const supportList = res[0].data;
            var userList = [...guardianList, ...supportList];

            if (primaryCM) {
              userList = [...userList, primaryCM];
            }
            if (secondaryCM) {
              userList = [...userList, secondaryCM];
            }
            setUsers(userList);
            var support;
            supportList.map((i: any) => {
              if (auth.profile.role === "support" && auth.profile.id === i.user_id) {
                support = i;
              }
            });
            var checkedUserList: any[] = [];
            if (support) {
              checkedUserList = [support];
            }
            if (primaryCM) {
              checkedUserList = [...checkedUserList, primaryCM];
            }
            if (secondaryCM) {
              checkedUserList = [...checkedUserList, secondaryCM];
            }

            setCheckedUsers(checkedUserList);
            setIsLoading(false);
          })
          .catch((err: any) => {
            console.log(err);
            setIsLoading(false);
          });
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  function formatMessageTime(dateTime: string) {
    const utcTimeString = dateTime;

    const utcDate = new Date(utcTimeString);
    // const dtf = new Intl.DateTimeFormat();

    //const timeZone = dtf.resolvedOptions().timeZone;
    const utcOffsetMinutes = utcDate.getTimezoneOffset();
    const utcOffsetHours = utcOffsetMinutes / 60;

    // Calculate the time in the local time zone
    const localTime = new Date(utcDate.getTime() - utcOffsetHours * 60 * 60 * 1000);

    // Get the components of the date
    const hour = localTime.getHours();
    const minute = localTime.getMinutes();
    // const seconds = localTime.getSeconds();
    const isAM = hour < 12;
    const hour12 = isAM ? hour : hour === 12 ? 12 : hour - 12;
    const period = isAM ? "am" : "pm";

    // Construct the formatted date string
    const formattedDate = `${hour12}:${minute.toString().padStart(2, "0")} ${period}`;
    return formattedDate;
  }

  return (
    <>
      {isLoading ? (
        <Container>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <div>
          <h4 className="mb-3 app-header-text">Messages</h4>
          <div className="d-flex message-content">
            <div className="d-flex bg-light p-2 card message-list">
              <div className="message-header">
                <Row className="justify-content-center pt-3">
                  <Col className="text-center">
                    <h6 className="app-orange-text">Threads</h6>
                  </Col>
                </Row>
              </div>
              <div className="message-body">
                <ListGroup>
                  {isLoading === false &&
                    conversationList?.map((c: any, index: any) => (
                      <ConversationListItem
                        key={index}
                        conversation={c}
                        currentConversation={currentConversation}
                        onClick={onClickConversation}
                      />
                    ))}
                </ListGroup>
              </div>
              <div className="message-footer">
                <Row className="pt-2">
                  <Col className="text-start">
                    {myCase && (
                      <Button className="app-primary-color button" onClick={() => setShowConversation(false)}>
                        <FontAwesomeIcon icon={faCommentMedical} />
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
            <div className="d-flex bg-light p-2 card message-container">
              {showConversation ? (
                <CurrentConversation
                  conversation={currentConversation}
                  messages={currentMsgs}
                  getMessages={getMessages}
                  currentIndex={currentIndex}
                  myCase={myCase}
                  showEmojiPicker={showEmojiPicker}
                  setShowEmojiPicker={setShowEmojiPicker}
                />
              ) : (
                myCase && (
                  <CreateGroup
                    conversations={conversations}
                    setConversations={setConversations}
                    setShowConversation={setShowConversation}
                    setCurrentConversation={setCurrentConversation}
                    users={users}
                    checkedUsers={checkedUsers}
                    setCheckedUsers={setCheckedUsers}
                    getPossibleUsers={getPossibleUsers}
                  />
                )
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Messages;
