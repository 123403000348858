import React, { useState } from "react";
import { Button, Col, Row, Stack } from "react-bootstrap";
import { postProgram } from "../../../services/httpClient";
import Input from "../../ui/Input";
import ProgramCard from "./ProgramCard";
import { unsavedChanges } from "../../../App";

interface ProgramsState {
  programs: any;
  getPrograms: any;
  setOrgToSave: any;
  setErrMessage: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
}

const Programs = ({ programs, getPrograms, setOrgToSave, setErrMessage, setShowToast, setToastText, setToastBody }: ProgramsState) => {
  const [newProgram, setNewProgram] = useState<string>("");
  const [showAddProgram, setShowAddProgram] = useState(false);

  const onClickAddProgram = () => {
    setShowAddProgram(true);
  };

  const updateNewProgram = (e: any) => {
    setNewProgram(e.target.value);
    unsavedChanges.value = true;
    setOrgToSave(true);
  };

  const onClickSaveProgram = (isDone: boolean = false) => {
    const newProg = {
      name: newProgram,
    };

    postProgram(newProg).then((res: any) => {
      setShowToast(true);
      setToastText("Program Created");
      setToastBody(newProgram);
      setNewProgram("");
      unsavedChanges.value = false;
      setOrgToSave(false);
      if (isDone) {
        setShowAddProgram(false);
      }
      getPrograms(true);
    });
  };

  const onClickCancelAddProgram = () => {
    setShowAddProgram(false);
    setNewProgram("");
    unsavedChanges.value = false;
    setOrgToSave(false);
  };

  return (
    <>
      <Row>
        <Col xs={10}>
          {programs?.map((item: any, index: number) => (
            <Row key={index}>
              <Col xs={10}>
                <Row className="mb-1"></Row>
                <ProgramCard
                  item={item}
                  getItems={getPrograms}
                  setOrgToSave={setOrgToSave}
                  setErrMessage={setErrMessage}
                  setShowToast={setShowToast}
                  setToastText={setToastText}
                  setToastBody={setToastBody}
                />
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
      {showAddProgram !== true && (
        <Row className="mt-3">
          <Col xs={4}>
            <Button className="app-tertiary-color button" onClick={(e) => onClickAddProgram()}>
              Add Program
            </Button>
          </Col>
        </Row>
      )}
      {showAddProgram === true && (
        <>
          <Row className="align-items-center my-3">
            <Col xs={8}>
              <Input label="New Program" type="text" value={newProgram} onUpdate={(e: any) => updateNewProgram(e)} hasFocus={true} />
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <Stack direction="horizontal" gap={3}>
                <Button className="app-secondary-color button" onClick={() => onClickSaveProgram(false)}>
                  Save and Add Another
                </Button>
                <div className="vr" />
                <Button className="app-secondary-color button" onClick={() => onClickSaveProgram(true)}>
                  Save and Done
                </Button>
                <div className="vr" />
                <Button className="app-primary-color button" onClick={() => onClickCancelAddProgram()}>
                  Done
                </Button>
              </Stack>
            </Col>
          </Row>
        </>
      )}
      <Row className="mb-5"></Row>
    </>
  );
};

export default Programs;
