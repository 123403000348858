import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Textarea from "../../../ui/Textarea";
import SelectFamily from "./SelectFamily";
import DatePicker from "../../../ui/DatePicker";
import { User } from "../../../../types/user";
import Input from "../../../ui/Input";

interface InfractionState {
  note?: any;
  family: User[];
  setUpdatedNote: any;
  setSaveIsEnabled: any;
  setUserToSave: any;
  myCase?: boolean;
  editNote?: boolean;
}

const Infraction = ({ note, family, setUpdatedNote, setSaveIsEnabled, setUserToSave, myCase, editNote = false }: InfractionState) => {
  const mainRef = useRef();
  const [familyUser, setFamilyUser] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [noteDate, setNoteDate] = useState<string>("");

  const handleScroll = (ref: any) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  };

  useEffect(() => {
    handleScroll(mainRef);

    if (editNote) {
      setFamilyUser(note?.subject?.id + "|" + note?.subject?.type);
      setNoteDate(note?.fields?.date);
      setType(note?.fields?.type);
      setDescription(note?.fields?.description);
    }

    if (family.length === 1) {
      setFamilyUser(family[0].id + "|" + family[0].type);
    }
  }, []);

  useEffect(() => {
    const parts = familyUser.split("|");

    const note = {
      notetype: "infraction",
      subject: {
        id: parts[0],
        type: parts[1],
      },
      fields: {
        date: noteDate,
        type: type,
        description: description,
      },
    };

    setUpdatedNote(note);
  }, [familyUser, noteDate, type, description, setUpdatedNote]);

  const updateFamilyUser = (e: any) => {
    setFamilyUser(e.target.value);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateType = (e: any) => {
    setType(e.target.value);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateDescription = (e: any) => {
    setDescription(e.target.value);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateDate = () => {
    setSaveIsEnabled(true);
    setUserToSave(true);
  };

  useEffect(() => {
    if (!noteDate) {
      setSaveIsEnabled(false);
      setUserToSave(false);
    }
  }, [noteDate]);

  return (
    <>
      <Row className="mt-4" ref={mainRef}>
        <SelectFamily family={family} familyUser={familyUser} updateFamilyUser={updateFamilyUser} readOnly={myCase ? false : true} />
        <Col className="col-lg-4 col-md-6 col-sm-8">
          <DatePicker
            label="Date"
            currentValue={noteDate}
            setCurrentValue={setNoteDate}
            onUpdate={() => updateDate()}
            required={true}
            hasMaxDate={true}
            disabled={myCase ? false : true}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={6}>
          <Input label="Type" type="text" value={type} onUpdate={(e: any) => updateType(e)} disabled={myCase ? false : true} />
        </Col>
      </Row>
      <Row className="mb-5">
        <Textarea label="Description" value={description} onUpdate={(e: any) => updateDescription(e)} disabled={myCase ? false : true} />
      </Row>
    </>
  );
};

export default Infraction;
