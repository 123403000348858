import React, { useEffect, useRef, useState } from "react";
import { Badge, Card, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Note } from "../../../../types/note";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEdit } from "@fortawesome/free-solid-svg-icons";
import html2canvas from "html2canvas";

interface InfractionViewState {
  n: Note;
  setEdit?: any;
  setCurrent?: any;
  buildPDF: any;
  canvas: any;
  setCanvas: any;
  externalReportRunning?: boolean;
}

const InfractionView = ({ n, setEdit, setCurrent, buildPDF, canvas, setCanvas, externalReportRunning }: InfractionViewState) => {
  const [reportRunning, setReportRunning] = useState<boolean>(false);

  useEffect(() => {
    if (reportRunning) {
      buildPDF("Infraction");
      setReportRunning(false);
    }
  }, [canvas]);

  const onClickEdit = () => {
    setEdit(true);
    setCurrent(n);
  };

  const downloadReport = async (e: any) => {
    e.stopPropagation();

    setReportRunning(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));

    const reportSection = document.getElementById("note-card-" + n?.id);
    const downloadButton = document.getElementById("note-download-button-" + n?.id);
    const editButton = document.getElementById("note-edit-button-" + n?.id);

    if (reportSection) {
      if (downloadButton) {
        downloadButton.style.visibility = "hidden";
        downloadButton.style.display = "none";
      }
      if (editButton) {
        editButton.style.visibility = "hidden";
        editButton.style.display = "none";
      }

      const images = reportSection.getElementsByTagName("img");
      for (let i = 0; i < images.length; i++) {
        images[i].crossOrigin = "anonymous";
      }

      const canvas = await html2canvas(reportSection, { useCORS: true });
      setCanvas(canvas);

      if (downloadButton) {
        downloadButton.style.visibility = "visible";
        downloadButton.style.display = "inline-block";
      }
      if (editButton) {
        editButton.style.visibility = "visible";
        editButton.style.display = "inline-block";
      }
    } else {
      console.error("Report section not found");
      setReportRunning(false);
    }
  };

  return (
    <Card id={"note-card-" + n?.id} className="bg-light mb-3" onClick={(e) => onClickEdit()}>
      <Card.Body>
        <>
          <Row>
            <Col>
              <h5 className="app-orange-text">Infraction</h5>
            </Col>
            <Col className="text-end">
              <label className="note-type text-muted me-3">{n.fields.date}</label>
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Download Note</Tooltip>}>
                <FontAwesomeIcon
                  id={"note-download-button-" + n?.id}
                  className="text-muted cursor-pointer me-3"
                  icon={faDownload}
                  onClick={(e) => downloadReport(e)}
                />
              </OverlayTrigger>
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Edit Note</Tooltip>}>
                <FontAwesomeIcon
                  id={"note-edit-button-" + n?.id}
                  className="text-muted cursor-pointer"
                  icon={faEdit}
                  onClick={(e) => onClickEdit()}
                />
              </OverlayTrigger>
            </Col>
          </Row>
          <Row>
            <h6 className="app-header-text">{n?.fields?.type}</h6>
          </Row>
          <Row>
            <div
              id="capture-text"
              style={{
                height: reportRunning ? "auto" : externalReportRunning ? "auto" : "80px",
                pointerEvents: "none",
                backgroundColor: "#e9ecef",
                color: "#6c757d",
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                overflow: "hidden",
                border: "1px solid #ccc",
                padding: "5px",
              }}
            >
              {n.fields.description}
            </div>
          </Row>
          <Row className="mt-1">
            <Col className="col-6 text-start">
              <Badge bg="secondary">
                Created By {n.firstname} {n.lastname}
              </Badge>
            </Col>
            <Col className="col-6 text-end">
              <Badge bg="secondary">{n.subject?.name}</Badge>
            </Col>
          </Row>
        </>
      </Card.Body>
    </Card>
  );
};

export default InfractionView;
