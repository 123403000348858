import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Col, OverlayTrigger, Row, Stack, Tooltip } from "react-bootstrap";
import { postJob } from "../../../services/httpClient";
import Input from "../../ui/Input";
import JobCard from "./JobCard";
import { unsavedChanges } from "../../../App";

interface JobsState {
  jobs: any;
  getJobs: any;
  setOrgToSave: any;
  setErrMessage: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
}

const Jobs = ({ jobs, getJobs, setOrgToSave, setErrMessage, setShowToast, setToastText, setToastBody }: JobsState) => {
  const [newDept, setNewDept] = useState<string>("");
  const [newJob, setNewJob] = useState<string>("");
  const [newPositionTotal, setNewPositionTotal] = useState<number>();
  const [showAddJob, setShowAddJob] = useState(false);
  const [disableDepartment, setDisableDepartment] = useState(false);

  const onClickAddJob = (dept?: string) => {
    setShowAddJob(true);
    if (dept) {
      setDisableDepartment(true);
      setNewDept(dept);
    }
  };

  const updateNewDept = (e: any) => {
    setNewDept(e.target.value);
    unsavedChanges.value = true;
    setOrgToSave(true);
  };

  const updateNewJob = (e: any) => {
    setNewJob(e.target.value);
    unsavedChanges.value = true;
    setOrgToSave(true);
  };

  const updateNewPositionTotal = (e: any) => {
    setNewPositionTotal(e.target.value);
    unsavedChanges.value = true;
    setOrgToSave(true);
  };

  const onClickSaveJob = (isDone: boolean = false) => {
    const _job = {
      name: newJob,
      department: newDept,
      positions_total: newPositionTotal,
    };

    console.log(_job);

    postJob(_job).then((res: any) => {
      setShowToast(true);
      setToastText("Job Created");
      setToastBody(newJob);
      setNewJob("");
      setNewPositionTotal(undefined);
      unsavedChanges.value = false;
      setOrgToSave(false);
      if (isDone) {
        setNewDept("");
        setShowAddJob(false);
        setDisableDepartment(false);
      }
      getJobs();
    });
  };

  const onClickCancelAddJob = () => {
    setShowAddJob(false);
    setDisableDepartment(false);
    setNewDept("");
    setNewJob("");
    setNewPositionTotal(undefined);
    unsavedChanges.value = false;
    setOrgToSave(false);
  };

  return (
    <>
      <Row>
        <Col xs={10}>
          {jobs?.map((dept: any, index: number) => (
            <div key={index}>
              <h6 className="mt-2">{dept[0].department}</h6>
              {dept?.map((j: any, index: number) => (
                <Row key={index}>
                  <Col xs={10}>
                    <Row className="mb-1"></Row>
                    <JobCard
                      item={j}
                      getItems={getJobs}
                      setOrgToSave={setOrgToSave}
                      setErrMessage={setErrMessage}
                      setShowToast={setShowToast}
                      setToastText={setToastText}
                      setToastBody={setToastBody}
                    />
                  </Col>
                  {dept?.length - 1 === index && (
                    <Col xs={2} className="p-0 m-0 align-self-center">
                      <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Add</Tooltip>}>
                        <Button
                          className="app-tertiary-color button button-smaller d-flex justify-content-center align-items-center"
                          onClick={(e) => onClickAddJob(j?.department)}
                          disabled={showAddJob}
                        >
                          <FontAwesomeIcon icon={faAdd} />
                        </Button>
                      </OverlayTrigger>
                    </Col>
                  )}
                </Row>
              ))}
            </div>
          ))}
        </Col>
      </Row>
      {showAddJob !== true && (
        <Row className="mt-3">
          <Col xs={4}>
            <Button className="app-tertiary-color button" onClick={(e) => onClickAddJob("")} disabled={showAddJob}>
              Add Department and Job
            </Button>
          </Col>
        </Row>
      )}
      {showAddJob === true && (
        <>
          <Row className="align-items-center my-3">
            <Col xs={3}>
              <Input
                label="Department"
                type="text"
                value={newDept}
                disabled={disableDepartment}
                onUpdate={(e: any) => updateNewDept(e)}
                hasFocus={disableDepartment ? false : true}
              />
            </Col>
            <Col xs={5}>
              <Input
                label="New Job"
                type="text"
                value={newJob}
                onUpdate={(e: any) => updateNewJob(e)}
                hasFocus={disableDepartment ? true : false}
              />
            </Col>
            <Col xs={2}>
              <Input label="Positions #" type="number" value={newPositionTotal} onUpdate={(e: any) => updateNewPositionTotal(e)} />
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <Stack direction="horizontal" gap={3}>
                <Button className="app-secondary-color button" onClick={() => onClickSaveJob(false)}>
                  Save and Add Another
                </Button>
                <div className="vr" />
                <Button className="app-secondary-color button" onClick={() => onClickSaveJob(true)}>
                  Save and Done
                </Button>
                <div className="vr" />
                <Button className="app-primary-color button" onClick={() => onClickCancelAddJob()}>
                  Done
                </Button>
              </Stack>
            </Col>
          </Row>
        </>
      )}
      <Row className="mb-5"></Row>
    </>
  );
};

export default Jobs;
