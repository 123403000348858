import React, { useEffect, useState } from "react";
import useApp from "../../utils/useApp";
import { Badge, Col, Container, Row } from "react-bootstrap";
import PeopleCard from "./PeopleCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import ToastMsg from "../ui/ToastMsg";
import { fetchNoteTypes } from "../../services/httpClient";

interface PeopleViewState {
  allPeople: any[];
  myPeople: any[];
  filteredAllPeople: any[];
  setFilteredAllPeople: any;
  filteredMyPeople: any[];
  setFilteredMyPeople: any;
  clearFilters: boolean;
  banFilterClicked: boolean;
  setBanFilterClicked: any;
  setSearchText: any;
}

const PeopleView = ({
  allPeople,
  myPeople,
  filteredAllPeople,
  setFilteredAllPeople,
  filteredMyPeople,
  setFilteredMyPeople,
  clearFilters,
  banFilterClicked,
  setBanFilterClicked,
  setSearchText,
}: PeopleViewState) => {
  const { viewAllCases }: any = useApp();
  const [types, setTypes] = useState<any[]>([]);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");

  const onClickClearFilters = () => {
    setFilteredMyPeople(myPeople);
    setFilteredAllPeople(allPeople);
    setBanFilterClicked(false);
    setSearchText("");
  };

  useEffect(() => {
    setBanFilterClicked(false);
  }, [clearFilters]);

  useEffect(() => {
    getTypes();
  }, []);

  const getTypes = () => {
    fetchNoteTypes()
      .then((res: any) => {
        const userTypes = res[0].data.filter((n: any) => n?.type === "user");
        setTypes(userTypes);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      <Row className="mt-3">
        {banFilterClicked && (
          <Col className="col-12 text-center">
            <Badge className="app-tertiary-color-inverse" bg="none">
              Banned
            </Badge>
            <Badge className="app-tertiary-color ms-1 cursor-pointer" bg="none" onClick={onClickClearFilters}>
              <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
            </Badge>
          </Col>
        )}
      </Row>
      <Row>
        <Col></Col>
        <Col xs={9}>
          <hr className="mt-3" />
        </Col>
        <Col></Col>
      </Row>
      {viewAllCases ? (
        <Row className="justify-content-center mb-5">
          <Container className="card-container">
            {filteredAllPeople.map((p: any, index) => (
              <PeopleCard
                key={index}
                person={p}
                types={types}
                setShowToast={setShowToast}
                setToastText={setToastText}
                setToastBody={setToastBody}
              />
            ))}
          </Container>
        </Row>
      ) : (
        <Row className="justify-content-center mb-5">
          <Container className="card-container">
            {filteredMyPeople.map((p: any, index) => (
              <PeopleCard
                key={index}
                person={p}
                types={types}
                setShowToast={setShowToast}
                setToastText={setToastText}
                setToastBody={setToastBody}
              />
            ))}
          </Container>
        </Row>
      )}
      <Row className="my-5"></Row>
    </>
  );
};

export default PeopleView;
