import React from "react";
import { Card, Col, FloatingLabel, Form, Stack } from "react-bootstrap";
import { patchBed } from "../../services/httpClient";

interface CardState {
  item: any;
  getItems: any;
  beds: any;
  possibleOccupants: any;
  setErrMessage: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
}

const BedAssignmentCard = ({
  item,
  getItems,
  beds,
  possibleOccupants,
  setErrMessage,
  setShowToast,
  setToastText,
  setToastBody,
}: CardState) => {
  const updateSelectedOccupant = (e: any) => {
    const [id, type] = e.target.value.split("|");
    const _id = parseInt(id);

    const newOccupant = possibleOccupants.find((p: any) => p?.id === _id && p?.type === type);
    const updatedBed = {
      occupant_id: _id,
      occupant_type: type,
    };

    patchBed(updatedBed, item?.id)
      .then((res: any) => {
        setShowToast(true);
        setToastText("Bed Assigned");
        setToastBody(newOccupant ? newOccupant?.firstname + " " + newOccupant?.lastname : "Vacant");
        getItems();
      })
      .catch((err: any) => {
        setErrMessage(err?.response?.data?.msg);
      });
  };

  return (
    <Card key={item.name} className="bg-light mb-0">
      <Card.Body className="p-2">
        <Stack direction="horizontal" gap={3}>
          <Stack gap={2} className="align-self-center">
            <Card.Subtitle className="text-muted">
              <small>{item.name}</small>
            </Card.Subtitle>
          </Stack>
          <Col xs={8}>
            <FloatingLabel controlId="floatingInput" label="Occupant">
              <Form.Select
                className="decorated"
                value={item?.occupant_id + "|" + item?.occupant_type}
                onChange={(e: any) => updateSelectedOccupant(e)}
              >
                <option key={0} value={0}>
                  Vacant
                </option>
                {!possibleOccupants.some((i: any) => i.id === item?.occupant_id && i.type === item?.occupant_type) && item?.occupant_id && (
                  <option key={item.occupant_id + item.occupant_type} value={item.occupant_id + "|" + item.occupant_type}>
                    {item.firstname + " " + item.lastname}
                  </option>
                )}
                {possibleOccupants.map((i: any) => (
                  <option key={i.id + i.type} value={i.id + "|" + i.type}>
                    {i.firstname + " " + i.lastname}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Col>
        </Stack>
      </Card.Body>
    </Card>
  );
};

export default BedAssignmentCard;
