import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, ListGroup, Row, Tab } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { postAssessment } from "../../../../services/httpClient";
import Continuum from "./Continuum";
import useApp from "../../../../utils/useApp";

interface ContinuumState {
  setViewAssessments: any;
  setCreateAssessmentWell: any;
  setCreateAssessmentCont: any;
  setCreateAssessmentCustom: any;
  fetchAssessments: any;
  readOnly?: boolean;
  questions: any;
  results?: any;
  setShowToast?: any;
  setToastText?: any;
  setToastBody?: any;
}

const AssessmentContinuum = ({
  setViewAssessments,
  setCreateAssessmentWell,
  setCreateAssessmentCont,
  setCreateAssessmentCustom,
  fetchAssessments,
  readOnly = false,
  questions,
  results,
  setShowToast,
  setToastText,
  setToastBody,
}: ContinuumState) => {
  const { id } = useParams();
  const { showMenu }: any = useApp();
  const [valueIncome, setValueIncome] = useState();
  const [valueEducation, setValueEducation] = useState();
  const [valueEmployment, setValueEmployment] = useState();
  const [valueChildCare, setValueChildCare] = useState();
  const [valueHealthCare, setValueHealthCare] = useState();
  const [valueNutrition, setValueNutrition] = useState();
  const [valueHousing, setValueHousing] = useState();
  const [valueTransportation, setValueTransportation] = useState();
  const [valueSupport, setValueSupport] = useState();
  const [valueFamily, setValueFamily] = useState();

  const [submitDisabled, setSubmitDisabled] = useState(true);

  const mainRef = useRef();

  const handleScroll = (ref: any) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  };

  useEffect(() => {
    if (readOnly) {
      setValueIncome(results.fields.income);
      setValueEducation(results.fields.education);
      setValueEmployment(results.fields.employment);
      setValueChildCare(results.fields.childcare);
      setValueHealthCare(results.fields.healthcare);
      setValueNutrition(results.fields.nutrition);
      setValueHousing(results.fields.housing);
      setValueTransportation(results.fields.transportation);
      setValueSupport(results.fields.support);
      setValueFamily(results.fields.family);
    }

    handleScroll(mainRef);
  }, []);

  useEffect(() => {
    if (
      valueIncome &&
      valueEducation &&
      valueEmployment &&
      valueChildCare &&
      valueHealthCare &&
      valueNutrition &&
      valueHousing &&
      valueTransportation &&
      valueSupport &&
      valueFamily
    ) {
      setSubmitDisabled(false);
    }
  }, [
    valueIncome,
    valueEducation,
    valueEmployment,
    valueChildCare,
    valueHealthCare,
    valueNutrition,
    valueHousing,
    valueTransportation,
    valueSupport,
    valueFamily,
  ]);

  const onClickSubmit = () => {
    const assessSubmission = {
      assessmenttype: "continuum",
      fields: {
        income: valueIncome,
        education: valueEducation,
        employment: valueEmployment,
        childcare: valueChildCare,
        healthcare: valueHealthCare,
        nutrition: valueNutrition,
        housing: valueHousing,
        transportation: valueTransportation,
        support: valueSupport,
        family: valueFamily,
      },
    };

    postAssessment(assessSubmission, id).then((res: any) => {
      setViewAssessments(true);
      setCreateAssessmentWell(false);
      setCreateAssessmentCont(false);
      setCreateAssessmentCustom(false);
      fetchAssessments();
      setShowToast(true);
      setToastText("Assessment Created");
      setToastBody("Continuum");
    });
  };

  return (
    <>
      <Row ref={mainRef}></Row>
      <Card
        className={
          showMenu
            ? "bg-light sticky-top card-assessment-menu-open-sticky"
            : "bg-light sticky-top card-assessment-sticky"
        }
      >
        <Card.Body>
          <Row className="gx-0">
            <Tab.Container>
              <Row className="gx-0">
                <Col className="text-start">
                  <h5 className="app-header-text">
                    <FontAwesomeIcon icon={faArrowLeftLong} className="ms-3" /> Dependence
                  </h5>
                </Col>
                <Col className="text-end">
                  <h5 className="app-header-text">
                    Independence <FontAwesomeIcon icon={faArrowRightLong} className="me-3" />
                  </h5>
                </Col>
              </Row>
              <Row className="gx-0">
                <Col className="col-12">
                  <ListGroup horizontal>
                    <ListGroup.Item key="0" className="text-center app-secondary-color m-1" action>
                      In-Crisis
                    </ListGroup.Item>
                    <ListGroup.Item key="1" className="text-center app-secondary-color m-1" action>
                      At-Risk
                    </ListGroup.Item>
                    <ListGroup.Item key="2" className="text-center app-secondary-color m-1" action>
                      Safe
                    </ListGroup.Item>
                    <ListGroup.Item key="3" className="text-center app-secondary-color m-1" action>
                      Stable
                    </ListGroup.Item>
                    <ListGroup.Item key="4" className="text-center app-secondary-color m-1" action>
                      Thriving
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </Tab.Container>
          </Row>
        </Card.Body>
      </Card>

      <Card className="bg-light">
        <Card.Body>
          <Row className="gx-0">
            <Continuum
              type="Income"
              value={valueIncome}
              setValue={setValueIncome}
              inCrisisText="No assets or income"
              atRiskText="75-149% Poverty Level"
              safeText="150-219% Poverty Level"
              stableText="220-349% Poverty Level"
              thrivingText="+350% Poverty Level"
              readOnly={readOnly}
            />
          </Row>
          <Row className="gx-0">
            <Continuum
              type="Education"
              value={valueEducation}
              setValue={setValueEducation}
              inCrisisText="No skills or credentials"
              atRiskText="Seeking HSE, vocational training, or ESL skills"
              safeText="HS diploma, HSE or vocational training"
              stableText="Career and educational plan in place"
              thrivingText="Implementing career and educational plan"
              readOnly={readOnly}
            />
          </Row>
          <Row className="gx-0">
            <Continuum
              type="Employment"
              value={valueEmployment}
              setValue={setValueEmployment}
              inCrisisText="Unemployed"
              atRiskText="Seeking job or temp/seasonal job"
              safeText="Employment in semi stable job"
              stableText="Permanent and stable job"
              thrivingText="Permanent and stable employment of choice"
              readOnly={readOnly}
            />
          </Row>
          <Row className="gx-0">
            <Continuum
              type="Childcare"
              value={valueChildCare}
              setValue={setValueChildCare}
              inCrisisText="No childcare or unreliable childcare"
              atRiskText="Childcare is not safe, reliable, or affordable"
              safeText="Childcare is safe and somewhat affordable"
              stableText="Childcare is safe, reliable, and affordable"
              thrivingText="Childcare is safe, reliable, affordable, and high quality"
              readOnly={readOnly}
            />
          </Row>
          <Row className="gx-0">
            <Continuum
              type="Health Care & Health Status"
              value={valueHealthCare}
              setValue={setValueHealthCare}
              inCrisisText="No insurance or regular care; undiagnosed illness likely"
              atRiskText="Public insurance; diagnosed but untreated illness likely"
              safeText="Public insurance; treatment for diagnosed illness"
              stableText="Private insurance; choices limited; treatment for diagnosed illness"
              thrivingText="Private insurance; doctor of choice; regular preventive care"
              readOnly={readOnly}
            />
          </Row>
          <Row className="gx-0">
            <Continuum
              type="Nutrition"
              value={valueNutrition}
              setValue={setValueNutrition}
              inCrisisText="Food intake disrupted due to lack of resources"
              atRiskText="Inconsistent access to low quality food"
              safeText="Consistent access to low quality food"
              stableText="Consistent access to quality food with little variety"
              thrivingText="Consistent access to a variety of foods of choice"
              readOnly={readOnly}
            />
          </Row>
          <Row className="gx-0">
            <Continuum
              type="Housing"
              value={valueHousing}
              setValue={setValueHousing}
              inCrisisText="Homeless or unstable housing"
              atRiskText="Temporary or transitional housing"
              safeText="Subsidized housing with support for utilities"
              stableText="Stable housing and utilities"
              thrivingText="Stable and affordable housing and utilities"
              readOnly={readOnly}
            />
          </Row>
          <Row className="gx-0">
            <Continuum
              type="Transportation"
              value={valueTransportation}
              setValue={setValueTransportation}
              inCrisisText="No transportation or unreliable transportation"
              atRiskText="Transportation inconsistently available and/or safe"
              safeText="Transportation available and reliable, and mostly safe"
              stableText="Transportation is affordable, reliable, and always safe"
              thrivingText="Transportation is affordable, reliable, safe, and sustainable"
              readOnly={readOnly}
            />
          </Row>
          <Row className="gx-0">
            <Continuum
              type="Support Systems"
              value={valueSupport}
              setValue={setValueSupport}
              inCrisisText="Poor or no emotional and physical support from relatives, neighbors, and friends"
              atRiskText="Infrequent positive interactions with relatives, neighbors, and friends"
              safeText="Consistent positive interactions with relatives, neighbors, and friends"
              stableText="Consistent positive interactions with reciprocity"
              thrivingText="Frequent positive interactions with reciprocity"
              readOnly={readOnly}
            />
          </Row>
          <Row className="gx-0">
            <Continuum
              type="Family Interactions"
              value={valueFamily}
              setValue={setValueFamily}
              inCrisisText="No, few, or negative interactions with family members"
              atRiskText="Low levels of emotional and physical support from family members"
              safeText="Basic emotional and physical support from family members"
              stableText="Good emotional and physical support received from family members with reciprocity"
              thrivingText="Excellent emotional and physical support received from family members with reciprocity"
              readOnly={readOnly}
            />
          </Row>
          {/* <Row>Income - {valueIncome}</Row>
          <Row>Education - {valueEducation}</Row>
          <Row>Employment - {valueEmployment}</Row>
          <Row>Childcare - {valueChildCare}</Row>
          <Row>Health Care - {valueHealthCare}</Row>
          <Row>Nutrition - {valueNutrition}</Row>
          <Row>Housing - {valueHousing}</Row>
          <Row>Transportation - {valueTransportation}</Row>
          <Row>Support - {valueSupport}</Row>
          <Row>Family - {valueFamily}</Row> */}
        </Card.Body>
      </Card>
      {!readOnly ? (
        <Row className="text-center pb-5">
          <Col></Col>
          <Col className="col-4">
            <Button className="app-secondary-color button" disabled={submitDisabled} onClick={() => onClickSubmit()}>
              Submit Results
            </Button>
          </Col>
          <Col></Col>
        </Row>
      ) : (
        <Row className="pb-5"></Row>
      )}
    </>
  );
};

export default AssessmentContinuum;
