import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Card, Col, Form, ListGroupItem, OverlayTrigger, Row, Stack, Tooltip } from "react-bootstrap";
import Input from "../../ui/Input";
import QuestionPreview from "./QuestionPreview";

interface QuestionState {
  question: any;
  index: number;
  setQuestions: any;
  setShowAddQuestion: any;
}

const AssessmentQuestionItem = ({ question, index, setQuestions, setShowAddQuestion }: QuestionState) => {
  const [currentQuestion, setCurrentQuestion] = useState<string>(question?.question);
  const [currentLikertValues, setCurrentLikertValues] = useState<any[]>(question?.fields);
  const [isDeleting, setIsDeleting] = useState(false);
  const [allowSaveQuestion, setAllowSaveQuestion] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState(false);

  const updateQuestion = (e: any) => {
    setCurrentQuestion(e.target.value);
    setAllowSaveQuestion(true);
  };

  const updateAnswerKey = (e: any, index: number) => {
    setCurrentLikertValues((prev) => prev.map((item) => (item.value === index ? { ...item, value_description: e.target.value } : item)));
    setAllowSaveQuestion(true);
  };

  const onClickDelete = () => {
    setIsDeleting(true);
  };

  const onClickDeleteFinal = () => {
    setQuestions((prev: any) => prev.filter((p: any, i: number) => i !== index));
    setIsDeleting(false);
  };

  const onClickCancelDelete = () => {
    setIsDeleting(false);
  };

  const onClickEdit = () => {
    setIsEditing(true);
    setShowAddQuestion(false);
  };

  const onClickSave = () => {
    setQuestions((prev: any) =>
      prev.map((p: any, i: number) => (i === index ? { question: currentQuestion, fields: currentLikertValues } : p))
    );

    setIsEditing(false);
    setAllowSaveQuestion(false);
  };

  const onClickCancel = () => {
    setIsEditing(false);
    setAllowSaveQuestion(false);
  };

  const onClickQuestionSubmit = (e: any) => {
    e?.preventDefault();
    onClickSave();
  };

  const renderDelete = () => {
    return (
      <ListGroupItem key={index} className="mb-2">
        <Stack direction="horizontal" gap={3}>
          <Stack style={{ justifyContent: "center" }} className="col-6">
            <Card.Subtitle>Are you sure you want to delete this question?</Card.Subtitle>
          </Stack>
          <Button className="app-danger-color button" onClick={() => onClickDeleteFinal()}>
            Confirm Delete
          </Button>
          <div className="vr" />
          <Button className="app-primary-color button" onClick={() => onClickCancelDelete()}>
            Cancel
          </Button>
        </Stack>
      </ListGroupItem>
    );
  };

  return (
    <>
      {isDeleting ? (
        <>{renderDelete()}</>
      ) : (
        <ListGroupItem key={index} className="mb-2">
          {isEditing ? (
            <>
              <h6 className="ms-1 mt-3">Edit Question</h6>
              <Form onSubmit={(e: any) => onClickQuestionSubmit(e)}>
                <Stack direction="horizontal" gap={3} className="mb-3">
                  <Stack style={{ justifyContent: "center" }} className="col-6">
                    <Input label="Question" type="text" value={currentQuestion} onUpdate={(e: any) => updateQuestion(e)} />
                  </Stack>
                  <Button className="button app-secondary-color" onClick={() => onClickSave()} disabled={!allowSaveQuestion}>
                    Save
                  </Button>
                  <div className="vr" />
                  <Button className="button app-primary-color" onClick={() => onClickCancel()}>
                    Cancel
                  </Button>
                </Stack>
              </Form>
              <h6 className="ms-1 mt-2">Answer Key Descriptions</h6>
              <Stack style={{ justifyContent: "center" }} className="col-12 mb-3" gap={3}>
                {[...Array(question.fields.length)].map((_, index) => (
                  <Stack key={index} direction="horizontal" gap={3} className="col-8">
                    <Col>
                      <Input
                        label="Answer Key Value"
                        type="text"
                        value={currentLikertValues[index]?.value_description}
                        onUpdate={(e: any) => updateAnswerKey(e, index)}
                      />
                    </Col>
                  </Stack>
                ))}
              </Stack>
              <QuestionPreview qIndex={index} question={currentQuestion} likertValues={currentLikertValues} />
            </>
          ) : (
            <Row>
              <Col xs={10}>{question?.question}</Col>
              <Col xs={2} className=" align-self-center p-0 text-end">
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Edit Question</Tooltip>}>
                  <FontAwesomeIcon className={"text-muted me-3 cursor-pointer"} icon={faEdit} onClick={(e) => onClickEdit()} />
                </OverlayTrigger>
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Delete Question</Tooltip>}>
                  <FontAwesomeIcon className="text-muted cursor-pointer me-3" icon={faTrash} onClick={(e) => onClickDelete()} />
                </OverlayTrigger>
              </Col>
            </Row>
          )}
        </ListGroupItem>
      )}
    </>
  );
};

export default AssessmentQuestionItem;
