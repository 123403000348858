import React from "react";
import { Col, ListGroup, Row, Tab } from "react-bootstrap";

interface LikertState {
  fields?: any;
  keyValue: any;
  value: any;
  setValues: any;
  beginText: string;
  endText: string;
  readOnly: boolean;
}

const LikertScale = ({ fields, keyValue, value, setValues, beginText, endText, readOnly = false }: LikertState) => {
  const onClickScale = (keyValue: any, value: any) => {
    if (readOnly === false) {
      setValues((prev: any) => prev.map((item: any) => (item.key === keyValue ? { ...item, value: value } : item)));
    }
  };

  const LikertItem = ({ label, keyValue, value, currentValue, readOnly }: any) => {
    return (
      <ListGroup.Item
        key={value}
        className="text-center d-flex align-items-center justify-content-center"
        action
        href={"#link" + value}
        onClick={() => onClickScale(keyValue, value)}
        active={value == currentValue ? true : false}
        disabled={readOnly}
      >
        {label}
      </ListGroup.Item>
    );
  };

  return (
    <Tab.Container>
      <Row className="gx-0">
        <Col className="col-12">
          <ListGroup horizontal>
            {fields.map((f: any, index: number) => (
              <LikertItem
                key={index}
                label={f?.value_description}
                keyValue={keyValue}
                value={index}
                currentValue={value}
                readOnly={readOnly}
              />
            ))}
          </ListGroup>
        </Col>
      </Row>
      <Row className="pt-2 pb-2 gx-0">
        <Col className="text-start text-muted">
          <h6>{beginText}</h6>
        </Col>
        <Col className="text-end text-muted">
          <h6>{endText}</h6>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default LikertScale;
