import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Container, Stack, Button, Spinner, Card, Image } from "react-bootstrap";
import { useParams, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { fetchRoles, fetchSupportTypes, fetchUser, fetchUserCases, logout, patchUserData, postUserData } from "../../services/httpClient";
import Input from "../ui/Input";
import InputPhone from "../ui/InputPhone";
import PageTitle from "../ui/PageTitle";
import SelectObject from "../ui/SelectObject";
import ToastMsg from "../ui/ToastMsg";
import { Case } from "../../types/case";
import SaveChangesModal from "../ui/SaveChangesModal";
import useApp from "../../utils/useApp";
import { unsavedChanges, currentUrl } from "../../App";

const EditUser = () => {
  const location = useLocation();
  currentUrl.value = location.pathname;
  const { id } = useParams();
  const [profile] = useSearchParams();
  const idAsNumber = id && /^\d+$/.test(id) ? parseInt(id, 10) : null;
  const { auth, setAuth, showMenu }: any = useApp();
  const [editMode] = useState(id !== undefined ? true : false);
  const [isMyUserProfile] = useState(auth?.profile?.id === idAsNumber ? true : false);
  const [role, setRole] = useState<string>();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [preferredName, setPreferredName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<number | null>(null);
  const [relationship, setRelationship] = useState<string>("");
  const [birthDate, setBirthDate] = useState<string>();
  const [isGuardian, setIsGuardian] = useState<boolean>(false);
  const [gender, setGender] = useState<string>("");
  const [race, setRace] = useState<string>("");
  const [ethnicity, setEthnicity] = useState<string>("");
  const [profileId, setProfileId] = useState<string>();
  const [profileImage, setProfileImage] = useState<string>("");
  const [organization, setOrganization] = useState<string>("");
  const [supportType, setSupportType] = useState<string>("");
  const [twofactorSetup, setTwoFactorSetup] = useState<boolean>(false);
  const [cases, setCases] = useState<Case[]>([]);
  const [saveIsEnabled, setSaveIsEnabled] = useState<boolean>(false);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [isValidPhone, setIsValidPhone] = useState<boolean>(true);
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");
  const [isDisabling, setIsDisabling] = useState(false);
  const [isTwoFactorDisabled, setIsTwoFactorDisabled] = useState(false);
  const heading = editMode ? "Edit User" : "Create User";
  const [roles, setRoles] = useState<any[]>([]);
  const [supportTypes, setSupportTypes] = useState<any[]>([]);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [currentDescText, setCurrentDescText] = useState<string>("");
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  let navigate = useNavigate();

  const backUser = (e: any) => {
    if (saveIsEnabled) {
      setShowModal(true);
    } else {
      navigate(`/users`);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getRoles();
    getSupportTypes();
    setCurrentDescText(`Org Admin - Access Admin plus Organization
Admin - Access Case Manager plus Users, Cases, and Reports
Case Manager - Access to My Cases, Groups, and Announcements
Support - Access to Messaging in My Cases`);

    if (editMode) {
      getUser();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (saveIsEnabled) {
      unsavedChanges.value = true;
    } else {
      unsavedChanges.value = false;
    }
  }, [saveIsEnabled]);

  const getRoles = async () => {
    await fetchRoles().then((res: any) => {
      var roleList = res[0].data;
      roleList = roleList.filter((r: any) => r.key !== "family");

      //if !org_admin
      if (auth?.profile?.role !== "org_admin") {
        roleList = roleList.filter((r: any) => r.key !== "org_admin");
      }

      if (auth?.profile?.role === "case_manager") {
        roleList = roleList.filter((r: any) => r.key === "case_manager");
      }

      setRoles(roleList);
    });
  };

  const getSupportTypes = () => {
    fetchSupportTypes().then((res: any) => {
      setSupportTypes(res[0].data);
    });
  };

  function validateEmail(email: string) {
    var regexp = /\S+@\S+\.\S+/;
    return regexp.test(email);
  }

  function validatePhone(phone: string) {
    let length = phone.length;
    return length === 11;
  }

  const getUser = async () => {
    await fetchUser(id)
      .then((res: any) => {
        setRole(res[0].data.role);
        setFirstName(res[0].data.firstname);
        setLastName(res[0].data.lastname);
        setPreferredName(res[0].data.preferredname);
        setEmail(res[0].data.email);
        setPhone(res[0].data.phonenumber === "" ? null : res[0].data.phonenumber);
        setRelationship(res[0].data.relationship);
        setBirthDate(res[0].data.birthdate);
        setIsGuardian(res[0].data.is_guardian);
        setGender(res[0].data.gender);
        setRace(res[0].data.race);
        setEthnicity(res[0].data.ethnicity);
        setProfileId(res[0].data.profile_id);
        setOrganization(res[0].data.organization);
        setSupportType(res[0].data.supporttype);
        setTwoFactorSetup(res[0].data.twofactorSetup);

        if (res[0].data?.profile.length > 0) {
          res[0].data?.profile?.map((p: any) => {
            const base64String = "data:image/jpeg;base64, " + p?.data;
            setProfileImage(base64String);
          });
        } else {
          setProfileImage("");
        }
        getUserCases(res[0].data.id, res[0].data.role);

        //non org admins cannot edit org admins
        if (auth?.profile?.role !== "org_admin" && res[0].data?.role === "org_admin") {
          setIsFieldsDisabled(true);
        }

        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getUserCases = (id: number, role: string) => {
    fetchUserCases().then((res: any) => {
      var cases = res[0].data;
      var filteredCases: any[] = [];

      if (role === "support") {
        cases.forEach((c: any) => {
          c.support.forEach((s: any) => {
            if (s.user_id === id) {
              filteredCases = [...filteredCases, c];
            }
          });
        });
      } else if (role === "family") {
        cases.forEach((c: any) => {
          c.family.forEach((s: any) => {
            if (s.user_id === id) {
              filteredCases = [...filteredCases, c];
            }
          });
        });
      } else {
        filteredCases = cases.filter((c: any) => c.primaryCM?.user_id === id || c.secondaryCM?.user_id === id);
      }

      setCases(filteredCases);
    });
  };

  const saveUser = () => {
    setIsLoading(true);
    setErrMsg("");

    if (isValidEmail && isValidPhone) {
      const updatedUser = {
        role: role,
        email: email,
        firstname: firstName,
        lastname: lastName,
        preferredname: preferredName,
        phonenumber: phone,
        appaccess: true,
        relationship: relationship,
        birthdate: birthDate,
        gender: gender,
        race: race,
        ethnicity: ethnicity,
        is_guardian: isGuardian,
        organization: organization,
        supporttype: supportType,
      };

      if (editMode) {
        patchUserData(updatedUser, id)
          .then((res: any) => {
            setSaveIsEnabled(false);
            setShowToast(true);
            setToastText("User Updated");
            const toast = preferredName ? firstName + " '" + preferredName + "' " + lastName : firstName + " " + lastName;
            setToastBody(toast);

            if (isMyUserProfile) {
              setAuth((prevAuth: any) => ({
                ...prevAuth,
                profile: {
                  ...prevAuth.profile,
                  firstname: firstName,
                  preferredname: preferredName,
                },
              }));
            }
          })
          .catch((err: any) => {
            setIsLoading(false);
            setErrMsg(err?.response?.data?.msg);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        postUserData(updatedUser)
          .then((res: any) => {
            setSaveIsEnabled(false);
            setShowToast(true);
            setToastText("User Created");
            const toast = preferredName ? firstName + " '" + preferredName + "' " + lastName : firstName + " " + lastName;
            setToastBody(toast);
            clearUserState();
          })
          .catch((err: any) => {
            setIsLoading(false);
            setErrMsg(err?.response?.data?.msg);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    } else {
      setIsLoading(false);
      setErrMsg("Email or Phone is in Invalid Format");
    }
  };

  const clearUserState = () => {
    setRole("");
    setFirstName("");
    setLastName("");
    setPreferredName("");
    setEmail("");
    setPhone(null);
    setRelationship("");
    setBirthDate("");
    setIsGuardian(false);
    setGender("");
    setRace("");
    setEthnicity("");
    setOrganization("");
    setSupportType("");
  };

  function enableSave() {
    if (role && email && phone && isValidEmail && isValidPhone) {
      setSaveIsEnabled(true);
    } else {
      setSaveIsEnabled(false);
    }
  }

  const onClickDisable = () => {
    setIsDisabling(true);
  };

  const disableTwoFactor = () => {
    setIsLoading(true);

    const updatedUser = {
      totpsecret: "delete",
    };

    patchUserData(updatedUser, id)
      .then((res: any) => {
        setIsDisabling(false);
        setShowToast(true);
        setToastText("User Updated");
        const toast = "Two-Factor Reset";
        setToastBody(toast);
        setIsTwoFactorDisabled(true);

        if (isMyUserProfile) {
          logout()
            .then((res: any) => {
              navigate("/login");
              setTwoFactorSetup(false);
              setIsTwoFactorDisabled(false);
            })
            .catch((err: any) => {
              console.log(err);
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          setTimeout(() => {
            setTwoFactorSetup(false);
            setIsTwoFactorDisabled(false);
            setIsLoading(false);
          }, 3000);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onClickCancelDisable = () => {
    setIsDisabling(false);
  };

  const updateRole = (e: any) => {
    setRole(e.target.value);

    if (e.target.value === "family") {
      setIsGuardian(true);
    } else {
      setIsGuardian(false);
    }

    if (e.target.value && email && phone && isValidEmail && isValidPhone) {
      setSaveIsEnabled(true);
    } else {
      setSaveIsEnabled(false);
    }
  };

  const updateFirstName = (e: any) => {
    setFirstName(e.target.value);
    enableSave();
  };

  const updateLastName = (e: any) => {
    setLastName(e.target.value);
    enableSave();
  };

  const updatePreferredName = (e: any) => {
    setPreferredName(e.target.value);
    enableSave();
  };

  const updateEmail = (e: any) => {
    setEmail(e.target.value);

    let validEmail = validateEmail(e.target.value);
    if (validEmail) {
      setIsValidEmail(true);
      if (isValidPhone) {
        if (role && phone) {
          setSaveIsEnabled(true);
        } else {
          setSaveIsEnabled(false);
        }
      }
    } else {
      setIsValidEmail(false);
      setSaveIsEnabled(false);
    }
  };

  const updatePhone = (e: any) => {
    setPhone(e);
    let validPhone = validatePhone(e);
    if (validPhone) {
      setIsValidPhone(true);
      if (isValidEmail) {
        if (role && email) {
          setSaveIsEnabled(true);
        } else {
          setSaveIsEnabled(false);
        }
      }
    } else {
      setIsValidPhone(false);
      setSaveIsEnabled(false);
    }
  };

  const updateOrganization = (e: any) => {
    setOrganization(e.target.value);
    enableSave();
  };

  const updateSupportType = (e: any) => {
    setSupportType(e.target.value);
    enableSave();
  };

  const onClickSelect = (event: any) => {
    fileInputRef?.current?.click();
  };

  const handleFileChange = (event: any) => {
    setErrMsg("");
    setIsImageLoading(true);

    const file = event.target.files[0];
    if (file) {
      // Check if the file is an image
      if (file.type.startsWith("image/")) {
        // The file is an image; proceed with upload
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          if (reader.result) {
            uploadFileData(reader.result.toString().split(",")[1]);
          }
        };
        reader.onerror = function (error) {
          setIsImageLoading(false);
        };
      } else {
        // Not an image; display an error or ignore the file
        setErrMsg("Please select a valid image file");
        setIsImageLoading(false);
      }
    } else {
      setIsImageLoading(false);
    }
  };

  async function uploadFileData(data: any) {
    setProfileImage(data);

    var image;
    if (profileId) {
      image = {
        profile_id: profileId,
        profile_data: data,
      };
    } else {
      image = {
        profile_data: data,
      };
    }

    patchUserData(image, id)
      .then((res: any) => {
        // fetchHousehold();

        const base64String = "data:image/jpeg;base64, " + data;
        setProfileImage(base64String);

        setShowToast(true);
        const toast = preferredName ? firstName + " '" + preferredName + "' " + lastName : firstName + " " + lastName;
        setToastText("User Profile Image Updated");
        setToastBody(toast);
        setIsImageLoading(false);
      })
      .catch((err: any) => {
        setErrMsg(err?.response?.data?.msg);
        setIsImageLoading(false);
      });
  }

  const renderDisableTwoFactor = () => {
    return (
      <Col xs={8}>
        <Stack direction="horizontal" gap={3}>
          <Stack style={{ justifyContent: "center" }}>
            <Card.Subtitle>
              Are you sure you want to reset two-factor for {firstName} {lastName}? {isMyUserProfile ? "This will log you out." : ""}
            </Card.Subtitle>
          </Stack>
          <Button className="app-danger-color button" onClick={() => disableTwoFactor()}>
            Confirm Reset
          </Button>
          <div className="vr" />
          <Button className="app-primary-color button" onClick={() => onClickCancelDisable()}>
            Cancel
          </Button>
        </Stack>
      </Col>
    );
  };

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      {isLoading ? (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p>
          {profile.get("profile") !== "true" ? (
            <>
              <Row className="mb-3">
                <Col xs={6} className="text-start">
                  <Button className={"app-primary-color button"} onClick={(e) => backUser(e)}>
                    {"Back to Users"}
                  </Button>
                </Col>
                <Col xs={6} className="text-end">
                  {!isFieldsDisabled && (
                    <Button id="pagetitle-button" className={"app-secondary-color button"} onClick={saveUser} disabled={!saveIsEnabled}>
                      {"Save"}
                    </Button>
                  )}
                </Col>
              </Row>
              <PageTitle title={heading} descriptionText={currentDescText} />
            </>
          ) : (
            <PageTitle
              title={heading}
              buttonTitle="Save"
              onButtonClick={saveUser}
              buttonDisabled={!saveIsEnabled}
              buttonColor="secondary"
            />
          )}
          <Row className="h-auto">
            <Col xs={12} sm={12} md={12} lg={6}>
              <Stack gap={3}>
                <Row>
                  <Col xs={12}>
                    <SelectObject
                      label="Role"
                      value={role}
                      options={
                        isFieldsDisabled
                          ? [
                              {
                                id: 5,
                                name: "Org Admin",
                                key: "org_admin",
                              },
                            ]
                          : roles
                      }
                      onUpdate={(e: any) => updateRole(e)}
                      disabled={isFieldsDisabled}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <Input
                      label="First Name"
                      type="text"
                      value={firstName}
                      onUpdate={(e: any) => updateFirstName(e)}
                      disabled={isFieldsDisabled}
                    />
                  </Col>
                  <Col xs={6}>
                    <Input
                      label="Last Name"
                      type="text"
                      value={lastName}
                      onUpdate={(e: any) => updateLastName(e)}
                      disabled={isFieldsDisabled}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <Input
                      label="Email"
                      type="email"
                      value={email}
                      onUpdate={(e: any) => updateEmail(e)}
                      required={true}
                      disabled={isFieldsDisabled}
                    />
                  </Col>
                  <Col xs={6}>
                    <InputPhone
                      label="Phone"
                      value={phone}
                      onUpdate={(e: any) => updatePhone(e)}
                      required={true}
                      disabled={isFieldsDisabled}
                    />
                  </Col>
                </Row>
                {role === "support" && (
                  <Row>
                    <Col xs={6}>
                      <SelectObject
                        label="Support Type"
                        value={supportType}
                        options={supportTypes}
                        onUpdate={(e: any) => updateSupportType(e)}
                      />
                    </Col>
                    <Col xs={6}>
                      <Input label="Organization" type="text" value={organization} onUpdate={(e: any) => updateOrganization(e)} />
                    </Col>
                  </Row>
                )}
                <Row className="mb-3">
                  <Col xs={6}>
                    <Input
                      label="Preferred Name"
                      type="text"
                      value={preferredName}
                      onUpdate={(e: any) => updatePreferredName(e)}
                      disabled={isFieldsDisabled}
                    />
                  </Col>
                  {role !== "family" &&
                    !isFieldsDisabled &&
                    editMode &&
                    (twofactorSetup ? (
                      isDisabling ? (
                        <>{renderDisableTwoFactor()}</>
                      ) : (
                        <Col xs={6} className="align-self-center">
                          <Button className="app-tertiary-color me-3 button" onClick={(e) => onClickDisable()}>
                            Reset Two-Factor Authentication
                          </Button>
                        </Col>
                      )
                    ) : (
                      <Col xs={6} className="align-self-center">
                        <h5 className="m-0 p-0 successmsg ">Two-Factor Not Yet Configured</h5>
                      </Col>
                    ))}
                </Row>
              </Stack>
            </Col>
            <Col xs={4} sm={4} md={4} lg={3} xl={2}>
              {editMode && (
                <Stack gap={3}>
                  {isImageLoading ? (
                    <Button size="sm" className="spinner-button spinner-button-main">
                      <Spinner animation="border" style={{ color: "#F6893D" }} />
                    </Button>
                  ) : (
                    <>
                      {profileImage ? (
                        <Image src={profileImage} rounded className="image-profile" />
                      ) : (
                        <Image src="/blank-profile-picture.jpg" className="image-profile" />
                      )}
                      {!isFieldsDisabled && (
                        <Button className="app-primary-color-inverse button" onClick={(e: any) => onClickSelect(e)}>
                          {profileImage ? "Change Image" : "Upload Image"}
                        </Button>
                      )}
                    </>
                  )}
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleFileChange} // Handle the file change
                  />
                </Stack>
              )}
            </Col>
          </Row>
          {editMode && !isImageLoading && (
            <>
              {cases.length > 0 ? (
                <>
                  <h4 className="app-header-text mt-2">User Cases</h4>
                  <Row className="pb-5">
                    {cases.map((c: any) => (
                      <Col key={c.id} xs={6} sm={4} lg={3} className="mb-2">
                        <Card key={c.name} className="bg-light">
                          <Card.Body>
                            <Stack direction="horizontal" gap={3}>
                              <Stack gap={2}>
                                <Card.Subtitle>{c.name}</Card.Subtitle>
                                <Card.Subtitle className="text-muted">{c.status}</Card.Subtitle>
                              </Stack>
                            </Stack>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </>
              ) : (
                <h6 className="mt-2">No User Cases</h6>
              )}
            </>
          )}
        </Container>
      )}
      {isTwoFactorDisabled && isMyUserProfile && (
        <Row className="pt-5 mt-5">
          <Col>
            <h4 className="p-3 text-center successmsg ">Logging Out!</h4>
          </Col>
        </Row>
      )}
      <SaveChangesModal showModal={showModal} setShowModal={setShowModal} path="/users" />
    </>
  );
};

export default EditUser;
