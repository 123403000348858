import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap";
import Standard from "../my-cases/my-case/notes/Standard";
import Contact from "../my-cases/my-case/notes/Contact";
import { User } from "../../types/user";
import { fetchNoteTypes, postNote, postNoteDocument, postSummary } from "../../services/httpClient";
import SelectObject from "./SelectObject";
import Intervention from "../my-cases/my-case/notes/Intervention";
import Referral from "../my-cases/my-case/notes/Referral";
import StudentConnections from "../my-cases/my-case/notes/StudentConnections";
import Infraction from "../my-cases/my-case/notes/Infraction";

interface CreateNoteState {
  showModal: boolean;
  setShowModal: any;
  caseId: string;
  family: User[];
  types: any;
  setUserToSave: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
}

const CreateNoteModal = ({
  showModal,
  setShowModal,
  caseId,
  family,
  types,
  setUserToSave,
  setShowToast,
  setToastText,
  setToastBody,
}: CreateNoteState) => {
  const [type, setType] = useState<string>("");
  const [saveIsEnabled, setSaveIsEnabled] = useState<boolean>(false);
  const [newNote, setNewNote] = useState<any>({});
  const [docs, setDocs] = useState<any[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const updateType = (e: any) => {
    setType(e.target.value);
    setSaveIsEnabled(false);
  };

  const onClickSave = () => {
    setIsSaving(true);
    postNote(newNote, caseId)
      .then((res: any) => {
        const noteId = res[0].data.note_id;
        if (newNote.notetype === "standard" && docs.length > 0) {
          const length = docs.length;
          var counter = 0;
          docs.map((d: any) => {
            counter++;
            const name = d.name;
            const data = d.data;

            postNoteDocument(name, data, caseId, noteId).then((res: any) => {
              if (counter === length) {
                setUserToSave(false);
                setShowToast(true);
                setToastText("Note Created");
                setToastBody(title(newNote.notetype));
              }
            });
          });
        } else {
          setUserToSave(false);
          setShowToast(true);
          setToastText("Note Created");
          setToastBody(title(newNote.notetype));
        }

        setNewNote({});
        setDocs([]);
        setType("");
        setSaveIsEnabled(false);
        setIsSaving(false);
        setShowModal(false);

        postSummary(caseId)
          .then((res: any) => {
            var magic = res[0].data.summary.postResponse;
          })
          .catch((err: any) => {
            console.log(err);
          });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const title = (type: any) => {
    if (type === "student") {
      return "Student Connections";
    } else if (type === "intervention") {
      return "Intervention Follow-Up";
    } else {
      return type.charAt(0).toUpperCase() + type.slice(1);
    }
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Create Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="bg-light m-0">
            <Row className="p-3">
              <Col className="col-6">
                <SelectObject label="Note Type" value={type} options={types} onUpdate={(e: any) => updateType(e)} />
              </Col>
              <Col className="col-4"></Col>
              <Col className="col-2 d-flex justify-content-end align-items-center">
                <Button className="app-secondary-color button" onClick={() => onClickSave()} disabled={!saveIsEnabled}>
                  Save
                </Button>
              </Col>
            </Row>
          </Card>
          {isSaving ? (
            <Row className="pt-3">
              <Button size="sm" className="spinner-button">
                <Spinner animation="border" style={{ color: "#F6893D" }} />
              </Button>
            </Row>
          ) : (
            <>
              {type === "standard" && (
                <Standard
                  family={family}
                  documents={docs}
                  setDocuments={setDocs}
                  setUpdatedNote={setNewNote}
                  setUserToSave={setUserToSave}
                  setSaveIsEnabled={setSaveIsEnabled}
                  myCase={true}
                />
              )}
              {type === "contact" && (
                <Contact
                  family={family}
                  setUpdatedNote={setNewNote}
                  setUserToSave={setUserToSave}
                  setSaveIsEnabled={setSaveIsEnabled}
                  myCase={true}
                />
              )}
              {type === "intervention" && (
                <Intervention
                  family={family}
                  setUpdatedNote={setNewNote}
                  setSaveIsEnabled={setSaveIsEnabled}
                  setUserToSave={setUserToSave}
                  myCase={true}
                />
              )}
              {type === "referral" && (
                <Referral
                  family={family}
                  setUpdatedNote={setNewNote}
                  setSaveIsEnabled={setSaveIsEnabled}
                  setUserToSave={setUserToSave}
                  myCase={true}
                />
              )}
              {type === "student" && (
                <StudentConnections
                  family={family}
                  setUpdatedNote={setNewNote}
                  setSaveIsEnabled={setSaveIsEnabled}
                  setUserToSave={setUserToSave}
                  myCase={true}
                />
              )}
              {type === "infraction" && (
                <Infraction
                  family={family}
                  setUpdatedNote={setNewNote}
                  setSaveIsEnabled={setSaveIsEnabled}
                  setUserToSave={setUserToSave}
                  myCase={true}
                />
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateNoteModal;
