import React, { useEffect, useState } from "react";
import { currentUrl, unsavedChanges } from "../../App";
import { useLocation } from "react-router-dom";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { fetchBeds, fetchReportLog, postNote, postReportLog } from "../../services/httpClient";
import PageTitle from "../ui/PageTitle";
import BedInspectionCard from "./BedInspectionCard";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import InfractionView from "../my-cases/my-case/notes/InfractionView";
import { createRoot } from "react-dom/client";
import FormCheck from "../ui/FormCheckbox";

interface BedInspectionState {
  setOrgToSave: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
  getData: boolean;
}

const BedInspection = ({ setOrgToSave, setShowToast, setToastText, setToastBody, getData }: BedInspectionState) => {
  const location = useLocation();
  currentUrl.value = location.pathname;
  const [errMsg, setErrMsg] = useState("");
  const [beds, setBeds] = useState<any[]>([]);
  const [infractions, setInfractions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [canvas, setCanvas] = useState<any>();
  const [reportRunning, setReportRunning] = useState<boolean>(false);
  const [lastReport, setLastReport] = useState<any>();
  const [download, setDownload] = useState<boolean>(false);

  useEffect(() => {
    if (getData) {
      setIsLoading(true);
      setInfractions([]);
      getBeds();
    }
  }, [getData]);

  const getBeds = () => {
    fetchBeds()
      .then((res: any) => {
        const bedList = res[0].data;
        setBeds(bedList);

        fetchReportLog("Bed Inspection").then((res: any) => {
          var lastReport = res[0].data[0];
          if (lastReport) {
            const utcDate = new Date(lastReport?.created_at + " UTC");
            const localTime = utcDate.toLocaleString(undefined, {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });
            setLastReport({ ...lastReport, created_at: localTime });
          }
        });
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onClickSubmit = () => {
    setSubmitted(true);

    const report = {
      name: "Bed Inspection",
    };

    postReportLog(report).then((res: any) => {
      infractions.map((i: any, index: number) => {
        postNote(i.note, i?.case_id).then((res: any) => {
          if (infractions.length === index + 1) {
            if (download) {
              downloadReport(infractions);
            }
          }
        });
      });

      unsavedChanges.value = false;
      setOrgToSave(false);
      setShowToast(true);
      setToastText("Bed Inspection Submitted");
      setToastBody(infractions.length + " Infraction(s) Created");
      setIsLoading(true);
      getBeds();
    });
  };

  useEffect(() => {
    if (reportRunning) {
      buildPDF("Infraction");
    }
    setReportRunning(false);
  }, [canvas]);

  function buildPDF(type: string) {
    try {
      const pdf = new jsPDF("p", "mm", "a4");
      const margin = 10;
      const headerHeight = 20; // Adjust as needed
      const pageHeight = pdf.internal.pageSize.getHeight();
      const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * margin;

      canvas.forEach((c: any, index: number) => {
        const imgData = c.toDataURL("image/png");
        const imgProps = pdf.getImageProperties(imgData);
        const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;
        let yPosition = margin + headerHeight;

        if (index > 0) {
          pdf.addPage();
        }

        // Add header to the first page
        pdf.setFontSize(12);
        pdf.text(type + " Report", margin, margin + 7);

        // Helper function to crop canvas
        const cropCanvas = (sourceCanvas: any, cropX: number, cropY: number, cropWidth: number, cropHeight: number) => {
          const tempCanvas = document.createElement("canvas");
          tempCanvas.width = cropWidth;
          tempCanvas.height = cropHeight;
          const ctx = tempCanvas.getContext("2d");
          ctx?.drawImage(sourceCanvas, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
          return tempCanvas;
        };

        // Check if the content fits on one page
        if (imgHeight <= pageHeight - yPosition) {
          pdf.addImage(imgData, "PNG", margin, yPosition, pdfWidth, imgHeight);
        } else {
          // Handle multi-page case
          let remainingHeight = c.height;
          let position = 0;
          const pageCanvasHeight = 1800;

          while (remainingHeight > 0) {
            const pageCanvas = cropCanvas(c, 0, position, c.width, pageCanvasHeight);
            const pageImgData = pageCanvas.toDataURL("image/png");
            pdf.addImage(pageImgData, "PNG", margin, yPosition, pdfWidth, pageCanvasHeight * (pdfWidth / c.width));

            remainingHeight -= pageCanvasHeight;
            position += pageCanvasHeight;

            if (remainingHeight > 0) {
              pdf.addPage();
              yPosition = margin + headerHeight;
              pdf.text(type + " Report", margin + 45, margin + 7);
            }
          }
        }
      });

      const formattedDate = new Date()
        .toISOString()
        .replace(/[-:.TZ]/g, "")
        .slice(0, 14);

      pdf.save(type + "_" + formattedDate + ".pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  }

  const downloadReport = async (infractions: any) => {
    setReportRunning(true);
    const canvases: HTMLCanvasElement[] = [];

    for (let i = 0; i < infractions.length; i++) {
      const infraction = infractions[i];

      //hidden container
      const hiddenContainer = document.createElement("div");
      hiddenContainer.style.position = "absolute";
      hiddenContainer.style.left = "-9999px"; // Off-screen
      hiddenContainer.style.top = "0";
      hiddenContainer.style.width = "800px";
      document.body.appendChild(hiddenContainer);

      // Render the HiddenReport component inside this container
      const root = createRoot(hiddenContainer);
      root.render(
        <Row className="mx-0">
          <InfractionView n={infraction} buildPDF={buildPDF} canvas={canvas} setCanvas={setCanvas} externalReportRunning={true} />
        </Row>
      );

      // Wait a short time to ensure React renders
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const reportSection = document.getElementById("note-card-" + infraction?.id);
      const downloadButton = document.getElementById("note-download-button-" + infraction?.id);
      const editButton = document.getElementById("note-edit-button-" + infraction?.id);

      if (reportSection) {
        if (downloadButton) {
          downloadButton.style.visibility = "hidden";
          downloadButton.style.display = "none";
        }
        if (editButton) {
          editButton.style.visibility = "hidden";
          editButton.style.display = "none";
        }

        const images = reportSection.getElementsByTagName("img");
        for (let i = 0; i < images.length; i++) {
          images[i].crossOrigin = "anonymous";
        }

        const canvas = await html2canvas(reportSection, { useCORS: true });
        canvases.push(canvas);

        if (downloadButton) {
          downloadButton.style.visibility = "visible";
          downloadButton.style.display = "inline-block";
        }
        if (editButton) {
          editButton.style.visibility = "visible";
          editButton.style.display = "inline-block";
        }

        // Clean up
        root.unmount();
        document.body.removeChild(hiddenContainer);
      } else {
        console.error("Report section not found");
      }
    }

    setCanvas(canvases);
  };

  return (
    <>
      {isLoading ? (
        <Container>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <>
          <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p>
          <>
            <PageTitle title="Bed Inspection" type="section" />
            <h6 className="app-header-text">
              {" "}
              {lastReport?.created_at ? "Last Checked " + lastReport?.created_at : "No Report Created"}{" "}
              {lastReport?.firstname && lastReport?.lastname ? "By " + lastReport?.firstname + " " + lastReport?.lastname : ""}{" "}
            </h6>
            <Row className="pb-3 mt-2">
              <Col xs={12} lg={10} xl={8}>
                {beds.length === 0 ? (
                  <small>No Beds Created</small>
                ) : (
                  beds?.map((item: any, index: number) => (
                    <Row key={index}>
                      <Col xs={12}>
                        <Row className="mb-1"></Row>
                        <BedInspectionCard
                          item={item}
                          setInfractions={setInfractions}
                          submitted={submitted}
                          setOrgToSave={setOrgToSave}
                          setShowToast={setShowToast}
                          setToastText={setToastText}
                          setToastBody={setToastBody}
                        />
                      </Col>
                    </Row>
                  ))
                )}
              </Col>
            </Row>
            <Row className="pb-5">
              {beds.length > 0 && (
                <Col xs={12} lg={10} xl={8} className="d-flex align-items-center justify-content-center">
                  <Button className="app-secondary-color button me-3" onClick={() => onClickSubmit()} disabled={submitted}>
                    {infractions.length === 0 ? "Submit with No Infractions" : "Submit with Infractions"}
                  </Button>
                  {infractions.length > 0 && (
                    <FormCheck
                      label="Download Infractions"
                      value={download}
                      onUpdate={(e: any) => setDownload(!download)}
                      disabled={submitted}
                    />
                  )}
                </Col>
              )}
            </Row>
          </>
        </>
      )}
    </>
  );
};

export default BedInspection;
