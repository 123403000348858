import React, { useEffect, useState } from "react";
import { Button, Col, Container, InputGroup, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import SearchBar from "../ui/SearchBar";
import { fetchUserCases } from "../../services/httpClient";
import { Case } from "../../types/case";
import useApp from "../../utils/useApp";
import { trackDataEvent, trackNavigationEvent } from "../../utils/analytics";
import CaseView from "./CaseView";
import PeopleView from "./PeopleView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faBriefcase, faFolderOpen, faSquareXmark, faUserFriends } from "@fortawesome/free-solid-svg-icons";

const MyCases = () => {
  const { auth, showMenu, viewAllCases, setViewAllCases }: any = useApp();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [viewByCase, setViewByCase] = useState<boolean>(true);
  const [viewActive, setViewActive] = useState<boolean>(true);
  const [activeCases, setActiveCases] = useState<Case[]>([]);
  const [inactiveCases, setInactiveCases] = useState<Case[]>([]);
  // const [allCases, setAllCases] = useState<Case[]>([]);
  const [myCases, setMyCases] = useState<Case[]>([]);
  const [otherCases, setOtherCases] = useState<Case[]>([]);
  const [filteredMyCases, setFilteredMyCases] = useState<Case[]>([]);
  const [filteredOtherCases, setFilteredOtherCases] = useState<Case[]>([]);
  const [allPeople, setAllPeople] = useState<any[]>([]);
  const [myPeople, setMyPeople] = useState<any[]>([]);
  const [filteredAllPeople, setFilteredAllPeople] = useState<any[]>([]);
  const [filteredMyPeople, setFilteredMyPeople] = useState<any[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [clearFilters, setClearFilters] = useState<boolean>(false);
  const [casesFiltered, setCasesFiltered] = useState<boolean>(false);
  const [banFilterClicked, setBanFilterClicked] = useState<boolean>(false);

  useEffect(() => {
    trackDataEvent("filter", "mycase_search");
  }, [searchText]);

  useEffect(() => {
    getUserCases();
  }, [auth?.profile]);

  function performFiltering(cases: Case[]) {
    const mycases = cases.filter((c: any) => c.primaryCM?.user_id === auth.profile.id || c.secondaryCM?.user_id === auth.profile.id);
    setMyCases(mycases);

    const othercases = cases.filter((c: any) => !mycases.some((ci: any) => ci.id === c.id));
    setOtherCases(othercases);

    setFilteredMyCases(mycases);
    setFilteredOtherCases(othercases);

    const myPpl = mycases
      .flatMap((caseObj: any) => [
        ...caseObj.family.map((f: any) => ({ ...f, name: f.user_name, case_id: caseObj.id })),
        ...caseObj.members.map((m: any) => ({ ...m, name: m.member_name, case_id: caseObj.id })),
      ])
      .sort((a: any, b: any) => a.name.localeCompare(b.name));
    setMyPeople(myPpl);
    setFilteredMyPeople(myPpl);
    const allPpl = cases
      .flatMap((caseObj: any) => [
        ...caseObj.family.map((f: any) => ({ ...f, name: f.user_name, case_id: caseObj.id })),
        ...caseObj.members.map((m: any) => ({ ...m, name: m.member_name, case_id: caseObj.id })),
      ])
      .sort((a: any, b: any) => a.name.localeCompare(b.name));
    setAllPeople(allPpl);
    setFilteredAllPeople(allPpl);
  }

  const getUserCases = () => {
    setIsLoading(true);

    fetchUserCases()
      .then((res: any) => {
        var cases = res[0].data;
        var inactivecases = cases.filter((c: any) => c.casestatus === "inactive");
        setInactiveCases(inactivecases);
        var activecases = cases.filter((c: any) => c.casestatus === "active");
        setActiveCases(activecases);
        // setAllCases(activecases);

        if (auth?.profile?.role === "support") {
          const mycases = activecases.filter((c: any) => c.support[0]?.user_id === auth.profile.id);
          setMyCases(mycases);
          setFilteredMyCases(mycases);

          const myPpl = mycases
            .flatMap((caseObj: any) => [
              ...caseObj.family.map((f: any) => ({ ...f, name: f.user_name, case_id: caseObj.id })),
              ...caseObj.members.map((m: any) => ({ ...m, name: m.member_name, case_id: caseObj.id })),
            ])
            .sort((a: any, b: any) => a.name.localeCompare(b.name));
          setMyPeople(myPpl);
          setFilteredMyPeople(myPpl);
        } else {
          performFiltering(activecases);
        }

        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onClickViewCases = () => {
    if (viewAllCases) {
      setViewAllCases(false);
      setFilteredMyCases(myCases);
      trackNavigationEvent("switch_view", "view_my_cases");
    } else {
      setViewAllCases(true);
      setFilteredMyCases(myCases);
      setFilteredOtherCases(otherCases);
      trackNavigationEvent("switch_view", "view_all_cases");
    }

    setClearFilters(true);
    setSearchText("");
  };

  const onPerformSearchLogicByCase = (text: string) => {
    const lowerText = text.toLowerCase();
    var finalMyCases = casesFiltered ? filteredMyCases : myCases;
    setFilteredMyCases(searchLogicByCase(lowerText, finalMyCases));

    var finalOtherCases = casesFiltered ? filteredOtherCases : otherCases;
    setFilteredOtherCases(searchLogicByCase(lowerText, finalOtherCases));
  };

  const searchLogicByCase = (text: string, searchObject: any) => {
    //filter by name, user, and member of case
    var myFiltered: any = searchObject?.filter((item: any) => {
      return (
        item.name.toLowerCase().match(text) ||
        item?.family.some((fam: any) => fam?.user_name.toLowerCase().match(text)) ||
        item?.members.some((mem: any) => mem?.member_name.toLowerCase().match(text))
      );
    });
    return myFiltered;
  };

  const onPerformSearchLogicByPerson = (text: string) => {
    const lowerText = text.toLowerCase();

    var finalMyPpl = banFilterClicked ? filteredMyPeople : myPeople;
    setFilteredMyPeople(searchLogicByPeople(lowerText, finalMyPpl));

    var finalAllPpl = banFilterClicked ? filteredAllPeople : allPeople;
    setFilteredAllPeople(searchLogicByPeople(lowerText, finalAllPpl));
  };

  const searchLogicByPeople = (text: string, searchObject: any) => {
    //filter by name of person
    var myFiltered: any = searchObject?.filter((item: any) => {
      return item.name.toLowerCase().match(text);
    });
    return myFiltered;
  };

  const onClickViewActive = (active: boolean) => {
    setViewActive(active);
    setViewAllCases(true);
    setClearFilters(true);
    setSearchText("");
    if (active) {
      performFiltering(activeCases);
    } else {
      performFiltering(inactiveCases);
    }

    if (active === false) {
      trackNavigationEvent("switch_view", "view_inactive");
    }
  };

  const onClickViewBy = (byCase: boolean) => {
    setViewByCase(byCase);
    setClearFilters(true);
    setSearchText("");
    setFilteredMyCases(myCases);
    setFilteredOtherCases(otherCases);
    setFilteredMyPeople(myPeople);
    setFilteredAllPeople(allPeople);

    if (byCase === false) {
      trackNavigationEvent("switch_view", "view_by_people");
    }
  };

  const onClickFilterBy = (type: string) => {
    if (type === "ban") {
      setBanFilterClicked(true);
      var myPpl = myPeople;
      var allPpl = allPeople;

      myPpl = myPpl.filter((m: any) => m.is_banned === 1);
      allPpl = allPpl.filter((m: any) => m.is_banned === 1);

      setFilteredMyPeople(myPpl);
      setFilteredAllPeople(allPpl);
    }
  };

  return (
    <>
      {isLoading ? (
        <Container className={showMenu ? "lg div-main-menu custom-container" : "lg div-main custom-container"}>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <Container className={showMenu ? "lg div-main-menu custom-container" : "lg div-main custom-container"}>
          {auth?.profile?.role !== "support" && (
            <Row>
              <Col xs={6}>
                {viewActive ? (
                  <h2 className="hero-heading app-header-text">
                    {viewAllCases ? "All Cases (" + activeCases.length + ")" : "My Cases (" + myCases.length + ")"}
                  </h2>
                ) : (
                  <h2 className="hero-heading app-header-text">{"Inactive Cases (" + inactiveCases.length + ")"}</h2>
                )}
              </Col>
              <Col xs={6} className="d-flex align-items-end justify-content-end">
                {viewActive && (
                  <Button
                    className="app-primary-color button me-2"
                    onClick={auth?.profile?.role !== "support" ? (e) => onClickViewCases() : () => null}
                  >
                    {viewAllCases ? "View My Cases" : "View All Cases"}
                  </Button>
                )}
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Active Cases</Tooltip>}>
                  <Button
                    className={
                      viewActive
                        ? "app-primary-color button button-icon-filter rounded me-2"
                        : "app-primary-color-inverse button button-icon-filter rounded me-2"
                    }
                    onClick={() => onClickViewActive(true)}
                  >
                    <FontAwesomeIcon icon={faBriefcase} />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Inactive Cases</Tooltip>}>
                  <Button
                    className={
                      viewActive
                        ? "app-primary-color-inverse button button-icon-filter rounded me-2"
                        : "app-primary-color button button-icon-filter rounded me-2"
                    }
                    onClick={() => onClickViewActive(false)}
                  >
                    <FontAwesomeIcon icon={faSquareXmark} />
                  </Button>
                </OverlayTrigger>
              </Col>
            </Row>
          )}
          <Row className="mt-3 mb-4">
            <Col xs={1}></Col>
            <Col xs={10}>
              <InputGroup>
                <small className="medium-text app-header-text me-2 align-self-center">View By</small>
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Case</Tooltip>}>
                  <Button
                    className={
                      viewByCase
                        ? "app-primary-color button button-icon-filter rounded me-2"
                        : "app-primary-color-inverse button button-icon-filter rounded me-2"
                    }
                    onClick={() => onClickViewBy(true)}
                  >
                    <FontAwesomeIcon icon={faFolderOpen} />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Person</Tooltip>}>
                  <Button
                    className={
                      !viewByCase
                        ? "app-primary-color button button-icon-filter rounded"
                        : "app-primary-color-inverse button button-icon-filter rounded"
                    }
                    onClick={(e) => onClickViewBy(false)}
                  >
                    <FontAwesomeIcon icon={faUserFriends} />
                  </Button>
                </OverlayTrigger>
                <Col>
                  <SearchBar
                    cssClass="search"
                    placeholder={viewByCase ? "Search Case Name or Person Name" : "Search Person Name"}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    searchLogic={viewByCase ? onPerformSearchLogicByCase : onPerformSearchLogicByPerson}
                  />
                </Col>
                {!viewByCase && (
                  <>
                    {/* <small className="medium-text app-header-text me-2 align-self-center">Filter By</small> */}
                    {/* <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Banned</Tooltip>}> */}
                    <Button
                      className={
                        banFilterClicked ? "app-primary-color button rounded me-2" : "app-primary-color-inverse button rounded me-2"
                      }
                      onClick={() => onClickFilterBy("ban")}
                    >
                      <FontAwesomeIcon icon={faBan} /> Banned
                    </Button>
                    {/* </OverlayTrigger> */}
                  </>
                )}
              </InputGroup>
            </Col>
            <Col xs={1}></Col>
          </Row>

          {viewByCase ? (
            <CaseView
              allCases={viewActive ? activeCases : inactiveCases}
              myCases={myCases}
              otherCases={otherCases}
              filteredMyCases={filteredMyCases}
              setFilteredMyCases={setFilteredMyCases}
              filteredOtherCases={filteredOtherCases}
              setFilteredOtherCases={setFilteredOtherCases}
              setSearchText={setSearchText}
              clearFilters={clearFilters}
              setCasesFiltered={setCasesFiltered}
              caseStatus={viewActive ? "active" : "inactive"}
            />
          ) : (
            <PeopleView
              allPeople={allPeople}
              myPeople={myPeople}
              filteredAllPeople={filteredAllPeople}
              setFilteredAllPeople={setFilteredAllPeople}
              filteredMyPeople={filteredMyPeople}
              setFilteredMyPeople={setFilteredMyPeople}
              clearFilters={clearFilters}
              banFilterClicked={banFilterClicked}
              setBanFilterClicked={setBanFilterClicked}
              setSearchText={setSearchText}
            />
          )}
        </Container>
      )}
    </>
  );
};

export default MyCases;
