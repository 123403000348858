import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import PageTitle from "../../ui/PageTitle";
import { fetchAssessmentTypes, fetchFieldSets } from "../../../services/httpClient";
import useApp from "../../../utils/useApp";
import SaveChangesModal from "../../ui/SaveChangesModal";
import { AssessmentType } from "../../../types/assessmenttype";
import AssessmentCard from "./AssessmentCard";
import CreateAssessmentCard from "./CreateAssessmentCard";
import { unsavedChanges } from "../../../App";

interface CustomState {
  setOrgToSave: any;
  getData: boolean;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
}

const OrgCustomAssessments = ({ setOrgToSave, getData, setShowToast, setToastText, setToastBody }: CustomState) => {
  const { auth, showMenu }: any = useApp();
  const [showCreateType, setShowCreateType] = useState<boolean>(false);
  const [assessTypes, setAssessTypes] = useState<AssessmentType[]>([]);
  const [answerScales, setAnswerScales] = useState<any[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (getData) {
      getAssessmentTypes();
      getAnswerScales();
    }
  }, [getData]);

  useEffect(() => {
    getAssessmentTypes();
  }, [auth?.profile]);

  const getAssessmentTypes = () => {
    setIsLoading(true);

    fetchAssessmentTypes()
      .then((res: any) => {
        var types = res[0].data;
        types = types.filter((t: any) => {
          return t?.organization_id !== null;
        });
        setAssessTypes(types);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getAnswerScales = () => {
    fetchFieldSets()
      .then((res: any) => {
        var sets = res[0].data;

        interface AnswerScale {
          id: number;
          name: string;
          key: number;
          field_id: number;
          value_description: string;
          value: string;
          order: number;
        }

        const group = new Map();
        sets.forEach(({ id, name, key, field_id, value_description, value, order }: AnswerScale) => {
          if (!group.has(id)) {
            group.set(id, { id, name, key, fields: [] });
          }
          group.get(id).fields.push({ field_id, value_description, value, order });
        });

        const finalSet = Array.from(group.values());
        setAnswerScales(finalSet);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const onClickCreateType = () => {
    setShowCreateType(true);
  };

  const onClickCreateTypeDone = () => {
    if (unsavedChanges.value) {
      setShowModal(true);
    } else {
      setShowCreateType(false);

      unsavedChanges.value = false;
      setOrgToSave(false);
      getAssessmentTypes();
    }
  };

  const onLeaveCreateAssessments = () => {
    setShowCreateType(false);
    unsavedChanges.value = false;
    setOrgToSave(false);
  };

  return (
    <>
      {isLoading ? (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <>
          <PageTitle
            title="Custom Assessments"
            type="section"
            buttonColor="primary"
            buttonTitle={showCreateType ? "Done" : "Create Assessment"}
            onButtonClick={showCreateType ? onClickCreateTypeDone : onClickCreateType}
          />
          <Row className="mb-3"></Row>
          {showCreateType && (
            <CreateAssessmentCard
              getAssessTypes={getAssessmentTypes}
              answerScales={answerScales}
              setOrgToSave={setOrgToSave}
              setShowToast={setShowToast}
              setToastText={setToastText}
              setToastBody={setToastBody}
            />
          )}
          {showCreateType && (
            <>
              <Row className="mb-5"></Row>
              <PageTitle title="View Assessments" type="section" />
            </>
          )}
          <Row className="mt-3">
            <Col xs={12}>
              {assessTypes?.length === 0 ? (
                <p className="ps-3">No Custom Assessments</p>
              ) : (
                assessTypes.map((t: AssessmentType, index: number) => <AssessmentCard key={index} type={t} />)
              )}
            </Col>
          </Row>
          <SaveChangesModal showModal={showModal} setShowModal={setShowModal} onLeave={onLeaveCreateAssessments} />
        </>
      )}
    </>
  );
};

export default OrgCustomAssessments;
