import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Stack, Image, Modal, Spinner } from "react-bootstrap";
import Input from "../ui/Input";
import { patchCheckInOut, postCheckIn } from "../../services/httpClient";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { resetCards } from "./CheckIn";

interface CardState {
  person: any;
  getActivePeople: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
}

const CheckInCard = ({ person, getActivePeople, setShowToast, setToastText, setToastBody }: CardState) => {
  const [showImage, setShowImage] = useState(false);
  const [showCheck, setShowCheck] = useState(false);
  const [checkOutNote, setCheckOutNote] = useState<string>(person?.reason);
  const [currentImage, setCurrentImage] = useState<string>("");
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  useEffect(() => {
    setShowCheck(false);
  }, [resetCards.value]);

  const updateNote = (e: any) => {
    setCheckOutNote(e.target.value);
  };

  const handleImageClick = (e: any, image: string) => {
    e.stopPropagation();
    setCurrentImage(image);
    setShowImage(true);
  };

  const handleClose = () => {
    setShowImage(false);
  };

  const onClickCard = () => {
    setShowCheck(true);
  };

  const onClickCheckIn = () => {
    setShowCheck(false);
    setIsUpdating(true);

    const checkObj = {
      person_type: person?.type,
      person_id: person?.id,
      is_checked_out: false,
    };

    if (person?.check_id === 0) {
      postCheckIn(checkObj).then(async (res: any) => {
        setCheckOutNote("");
        setShowToast(true);
        setToastText("Check In");
        setToastBody(person?.name);
        const result = await getActivePeople(false);
        setIsUpdating(false);
      });
    } else {
      patchCheckInOut(checkObj, person?.check_id).then(async (res: any) => {
        setCheckOutNote("");
        setShowToast(true);
        setToastText("Check In");
        setToastBody(person?.name);
        const result = await getActivePeople(false);
        setIsUpdating(false);
      });
    }
  };

  const onClickCheckOut = () => {
    setShowCheck(false);
    setIsUpdating(true);

    const checkObj = {
      person_type: person?.type,
      person_id: person?.id,
      is_checked_out: true,
      reason: checkOutNote,
    };

    patchCheckInOut(checkObj, person?.check_id)
      .then(async (res: any) => {
        setShowToast(true);
        setToastText("Check Out");
        setToastBody(person?.name);
        const result = await getActivePeople(false);
        setIsUpdating(false);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const onClickCancel = () => {
    setShowCheck(false);
    setCheckOutNote("");
  };

  return (
    <div key={person?.id}>
      <div>
        <Card className={"bg-light card-checkin mx-2 mt-3 p-3 button cursor-pointer"} onClick={() => onClickCard()}>
          <Card.Body className="ms-2 p-0">
            <Row className="align-items-center h-100">
              <Col xs={9} className="m-0 p-0">
                <Stack direction="horizontal">
                  {person?.profile[0]?.data ? (
                    <Image
                      className="image-people me-2 cursor-pointer"
                      src={"data:image/jpeg;base64, " + person?.profile[0]?.data}
                      onClick={(e: any) => handleImageClick(e, "data:image/jpeg;base64, " + person?.profile[0]?.data)}
                    />
                  ) : (
                    <Image src="/blank-profile-picture.jpg" className="image-people me-2" />
                  )}
                  <Stack className="align-self-center">
                    <h6 className={"my-0 app-header-text text-wrap first-letter text-truncate"}>{person.name}</h6>
                    <p className="text-muted first-letter mb-0 small-text">{person.reason}</p>
                  </Stack>
                </Stack>
              </Col>
              <Col xs={3} className="text-end">
                {isUpdating ? (
                  <Button size="sm" className="border-0 bg-light">
                    <Spinner animation="border" style={{ color: "#F6893D" }} />
                  </Button>
                ) : person?.is_checked_out ? (
                  <>
                    <h6 className="app-danger-color-inverse bg-light">
                      Out
                      <FontAwesomeIcon icon={faCircleXmark} className="ms-2 app-danger-color-inverse" />
                    </h6>
                    <p className="text-muted first-letter mb-0 smaller-text">{person.check_date}</p>
                  </>
                ) : (
                  <>
                    <h6 className="app-secondary-color-inverse bg-light">
                      In
                      <FontAwesomeIcon icon={faCircleCheck} className="ms-2 app-secondary-color-inverse" />
                    </h6>
                    <p className="text-muted first-letter mb-0 smaller-text">{person.check_date}</p>
                  </>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {showCheck &&
          (person.is_checked_out ? (
            <Row className="my-3">
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <Stack direction="horizontal" gap={3}>
                  <Button className="app-secondary-color button" onClick={() => onClickCheckIn()}>
                    Check In
                  </Button>
                  <div className="vr" />
                  <Button className="app-primary-color button" onClick={() => onClickCancel()}>
                    Cancel
                  </Button>
                </Stack>
              </Col>
            </Row>
          ) : (
            <>
              <Row className="align-items-center my-3 mx-1">
                <Col xs={12}>
                  <Input
                    label={"Check Out Reason"}
                    type="text"
                    value={checkOutNote}
                    onUpdate={(e: any) => updateNote(e)}
                    hasFocus={true}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={12} className="d-flex align-items-center justify-content-center">
                  <Stack direction="horizontal" gap={3}>
                    <Button className="app-secondary-color button" onClick={() => onClickCheckOut()}>
                      Check Out
                    </Button>
                    <div className="vr" />
                    <Button className="app-primary-color button" onClick={() => onClickCancel()}>
                      Cancel
                    </Button>
                  </Stack>
                </Col>
              </Row>
            </>
          ))}
      </div>
      <Modal show={showImage} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Image src={currentImage} fluid alt="Selected" />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CheckInCard;
