import { faBan, faFileText, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Card, Col, Row, Stack, Image, Modal, OverlayTrigger, Button, Tooltip } from "react-bootstrap";
import CreateNoteModal from "../ui/CreateNoteModal";
import { User } from "../../types/user";
import { useNavigate } from "react-router-dom";

interface PeopleCardState {
  person: any;
  types: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
}

const PeopleCard = ({ person, types, setShowToast, setToastText, setToastBody }: PeopleCardState) => {
  const [show, setShow] = useState(false);
  const [currentImage, setCurrentImage] = useState<string>("");
  const [showCreateNote, setShowCreateNote] = useState<boolean>(false);
  const [userToSave, setUserToSave] = useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<User[]>([person]);

  let navigate = useNavigate();

  const editMyCase = (e: any) => {
    navigate(`/my-cases/${e.case_id}`);
  };

  useEffect(() => {
    const personObject = {
      id: person.user_id ? person.user_id : person.id,
      type: person.user_id ? "guardian" : "member",
      role: "",
      firstname: person.name,
      lastname: "",
      name: person.name,
    };
    setSelectedMember([personObject]);
  }, []);

  const handleImageClick = (e: any, image: string) => {
    e.stopPropagation();
    setCurrentImage(image);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const onClickCreateNote = (e: any) => {
    e.stopPropagation();
    setShowCreateNote(true);
  };

  return (
    <>
      <Card
        className={
          person?.is_banned
            ? "bg-light card-people card-danger mx-2 mt-3 p-3 button cursor-pointer"
            : "bg-light card-people mx-2 mt-3 p-3 button cursor-pointer"
        }
        onClick={(e) => editMyCase(person)}
      >
        <Card.Body className="ms-2 p-0">
          <Row className="align-items-center h-100">
            <Col xs={"auto"} className="m-0 p-0">
              {person?.profile[0]?.data ? (
                <Image
                  className="image-people me-2 cursor-pointer"
                  src={"data:image/jpeg;base64, " + person?.profile[0]?.data}
                  onClick={(e: any) => handleImageClick(e, "data:image/jpeg;base64, " + person?.profile[0]?.data)}
                />
              ) : (
                <Image src="/blank-profile-picture.jpg" fluid className="image-people me-2" />
              )}
            </Col>
            <Col xs={8} className="p-0">
              <h6 className={"my-0 app-header-text text-wrap first-letter text-truncate"}>{person.name}</h6>
              <p className="text-muted first-letter mb-0">{person.relationship}</p>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 text-end">
              {person?.is_banned === 1 ? (
                <div className="position-absolute top-0 end-0">
                  <Stack direction="horizontal">
                    <small className="small-text app-danger-text me-1 mt-2 bg-light">Banned</small>
                    <FontAwesomeIcon icon={faBan} className="app-danger-text me-2 mt-2 bg-light" />
                  </Stack>
                </div>
              ) : (
                person.is_guardian === 1 && (
                  // (person.is_primary ? (
                  //   <div className="position-absolute top-0 end-0">
                  //     <Stack direction="horizontal">
                  //       <small className="small-text app-primary-color-inverse me-1 mt-2 bg-light">Primary</small>
                  //       <FontAwesomeIcon icon={faUserShield} className="app-primary-color-inverse me-2 mt-2 bg-light" />
                  //     </Stack>
                  //   </div>
                  // ) : (
                  <div className="position-absolute top-0 end-0">
                    <Stack direction="horizontal">
                      <FontAwesomeIcon icon={faUserShield} className="app-primary-color-inverse me-2 mt-2" />
                    </Stack>
                  </div>
                )
                // ))
              )}
            </Col>
          </Row>
          <Row className="position-absolute bottom-0 end-0">
            <Col className="text-end p-2 me-3">
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Create Note</Tooltip>}>
                <Button onClick={(e: any) => onClickCreateNote(e)} className="button button-smaller app-tertiary-color p-0 cursor-pointer">
                  <FontAwesomeIcon icon={faFileText} />
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Image src={currentImage} fluid alt="Selected" />
        </Modal.Body>
      </Modal>
      <CreateNoteModal
        showModal={showCreateNote}
        setShowModal={setShowCreateNote}
        caseId={person?.case_id}
        family={selectedMember}
        types={types}
        setUserToSave={setUserToSave}
        setShowToast={setShowToast}
        setToastText={setToastText}
        setToastBody={setToastBody}
      />
    </>
  );
};

export default PeopleCard;
