import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, FloatingLabel, Alert, Card, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { login } from "../../services/httpClient";
import useApp from "../../utils/useApp";

declare global {
  interface Window {
    pendo?: {
      initialize: (config: any) => void;
    };
  }
}

const Login = () => {
  const { setAuth }: any = useApp();
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef?.current?.focus();
    localStorage.removeItem("auth");
  }, []);

  let navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    login(user, pwd)
      .then((res: any) => {
        const profile = res[0].data;
        console.log(profile);

        //pendo
        const industry = profile.includeHMIS ? "HMIS" : "Other";
        if (window.pendo && typeof window.pendo.initialize === "function") {
          window.pendo.initialize({
            visitor: {
              id: profile.id,
              role: profile.role,
            },
            account: {
              id: profile.id,
              industry: industry,
            },
          });
        }

        setAuth({ profile });
        if (profile?.twofactorSetup) {
          navigate("/authenticate");
        } else {
          navigate("/twofactor-setup");
        }
      })
      .catch((err: any) => {
        if (!err?.response) {
          setErrMsg("No Server Response");
        } else if (err?.response?.status === 401) {
          setErrMsg("User is Not Authorized");
        } else {
          setErrMsg("Login Failed");
        }
      });
  };

  return (
    <div className="h-100 app-primary-alt-color align-content-center">
      <Row className="w-100 pb-5 d-flex align-item-center justify-content-center m-0">
        <Col xs={10} sm={8} md={6} lg={5} xl={4} xxl={4} className="pb-5">
          <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p>
          <Card className="shadow-sm rounded-lg d-block">
            <Image src="/AndGo_Logo_Color_Horizontal.png" fluid className="pt-5 px-5 pb-5 " />
            <Form onSubmit={handleSubmit}>
              <FloatingLabel label="Email Address" className="mx-3 mb-3 app-header-text">
                <Form.Control
                  type="email"
                  placeholder="Email Address"
                  id="user"
                  onChange={(e) => {
                    setUser(e.target.value);
                    setErrMsg("");
                  }}
                  value={user}
                  ref={inputRef}
                  required
                />
              </FloatingLabel>
              <FloatingLabel label="Password" className="mx-3 mb-3 app-header-text">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  id="pwd"
                  onChange={(e) => {
                    setPwd(e.target.value);
                    setErrMsg("");
                  }}
                  value={pwd}
                  required
                />
              </FloatingLabel>
              <Alert key="light" variant="light" className="p-0 me-3" style={{ float: "right" }}>
                <Alert.Link href="/send-reset">Reset Password</Alert.Link>
              </Alert>
              <div className="d-grid w-100">
                <Button className="button app-tertiary-color text-center mx-3 mb-3 p-3" type="submit">
                  Login
                </Button>
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
