import React, { useEffect, useState } from "react";
import { Container, Tab, Nav, Row, Col, Button, Spinner } from "react-bootstrap";
import { User } from "../../types/user";
import { fetchUserCases, fetchUsers } from "../../services/httpClient";
import PageTitle from "../ui/PageTitle";
import UserTab from "./UserTab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserCog, faUserLock, faUserShield, faUserTie } from "@fortawesome/free-solid-svg-icons";
import ToastMsg from "../ui/ToastMsg";
import useApp from "../../utils/useApp";
import { trackNavigationEvent } from "../../utils/analytics";
import GuardianTab from "./GuardianTab";
import { useNavigate } from "react-router-dom";
import useMenuToggle from "../../utils/useMenuToggle";

const Users = () => {
  const { showMenu }: any = useApp();
  const toggleMenu = useMenuToggle();
  const [users, setUsers] = useState<User[]>([]);
  const [cases, setCases] = useState<any[]>([]);
  const [orgAdminUsers, setOrgAdminUsers] = useState<User[]>([]);
  const [adminUsers, setAdminUsers] = useState<User[]>([]);
  const [caseManagerUsers, setCaseManagerUsers] = useState<User[]>([]);
  const [supportUsers, setSupportUsers] = useState<User[]>([]);
  const [guardianUsers, setGuardianUsers] = useState<User[]>([]);
  const [numberUsers, setNumberUsers] = useState<number>(0);
  const [activeOrgAdmin, setActiveOrgAdmin] = useState<boolean>(false);
  const [activeAdmin, setActiveAdmin] = useState<boolean>(false);
  const [activeCaseManagers, setActiveCaseManagers] = useState<boolean>(false);
  const [activeSupport, setActiveSupport] = useState<boolean>(false);
  const [activeGuardian, setActiveGuardian] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");
  const [currentDescText, setCurrentDescText] = useState<string>("");

  useEffect(() => {
    setCurrentDescText(`Org Admin - Access Admin plus Organization
Admin - Access Case Manager plus Users, Cases, and Reports
Case Manager - Access to My Cases, Groups, and Announcements
Support - Access to Messaging in My Cases
Primary Adults - Access to their own case`);
    setActiveOrgAdmin(true);
    setActiveAdmin(false);
    setActiveCaseManagers(false);
    setActiveSupport(false);
    setActiveGuardian(false);
    getUsers();

    trackNavigationEvent("switch_view", "admin_users");
  }, []);

  useEffect(() => {
    var filteredCases: any[] = [];
    var workingUsers: User[];
    workingUsers = users.filter((x: any) => x.role === "org_admin");
    if (cases) {
      const updatedUsers = workingUsers.map((user) => {
        filteredCases = cases.filter(
          (c: any) => c.primaryCM?.user_id === user?.id || c.secondaryCM?.user_id === user?.id
        );

        return {
          ...user,
          cases: filteredCases,
        };
      });

      setOrgAdminUsers(updatedUsers);
    } else {
      setOrgAdminUsers(workingUsers);
    }

    workingUsers = users.filter((x: any) => x.role === "admin");
    if (cases) {
      const updatedUsers = workingUsers.map((user) => {
        filteredCases = cases.filter(
          (c: any) => c.primaryCM?.user_id === user?.id || c.secondaryCM?.user_id === user?.id
        );

        return {
          ...user,
          cases: filteredCases,
        };
      });

      setAdminUsers(updatedUsers);
    } else {
      setAdminUsers(workingUsers);
    }

    workingUsers = users.filter((x: any) => x.role === "case_manager");
    if (cases) {
      const updatedUsers = workingUsers.map((user) => {
        filteredCases = cases.filter(
          (c: any) => c.primaryCM?.user_id === user?.id || c.secondaryCM?.user_id === user?.id
        );

        return {
          ...user,
          cases: filteredCases,
        };
      });

      setCaseManagerUsers(updatedUsers);
    } else {
      setCaseManagerUsers(workingUsers);
    }

    setSupportUsers(users.filter((x: any) => x.role === "support"));

    const totalUsers = users.filter(
      (x: any) => x.role === "support" || x.role === "case_manager" || x.role === "admin" || x.role === "org_admin"
    );
    setNumberUsers(totalUsers.length);

    workingUsers = users.filter((x: any) => x.role === "family");
    if (cases) {
      const updatedUsers = workingUsers.map((user) => {
        let caseName = null;

        cases.forEach((c: any) => {
          if (c.family.some((f: any) => f.user_id === user.id)) {
            caseName = c.name;
          }
        });

        return {
          ...user,
          casename: caseName,
        };
      });

      setGuardianUsers(updatedUsers);
    } else {
      setGuardianUsers(workingUsers);
    }
  }, [users]);

  let navigate = useNavigate();

  const CreateNewUser = (e: any) => {
    navigate(`/users/user`);
  };

  const getUsers = async () => {
    setIsLoading(true);

    await getCases();
    fetchUsers()
      .then((res: any) => {
        const userList = res[0].data;
        setUsers(userList);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getCases = async () => {
    await fetchUserCases()
      .then((res: any) => {
        var c = res[0].data;
        setCases(c);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const onNavClick = (route: string) => {
    if (route) {
      trackNavigationEvent("switch_view", route + "_users");
    }

    switch (route) {
      case "org_admin":
        setActiveOrgAdmin(true);
        setActiveAdmin(false);
        setActiveCaseManagers(false);
        setActiveSupport(false);
        setActiveGuardian(false);
        break;
      case "admin":
        setActiveOrgAdmin(false);
        setActiveAdmin(true);
        setActiveCaseManagers(false);
        setActiveSupport(false);
        setActiveGuardian(false);
        break;
      case "case_manager":
        setActiveOrgAdmin(false);
        setActiveAdmin(false);
        setActiveCaseManagers(true);
        setActiveSupport(false);
        setActiveGuardian(false);
        break;
      case "support":
        setActiveOrgAdmin(false);
        setActiveAdmin(false);
        setActiveCaseManagers(false);
        setActiveSupport(true);
        setActiveGuardian(false);
        break;
      case "guardian":
        setActiveOrgAdmin(false);
        setActiveAdmin(false);
        setActiveCaseManagers(false);
        setActiveSupport(false);
        setActiveGuardian(true);
        break;
      default:
        setActiveOrgAdmin(true);
        setActiveAdmin(false);
        setActiveCaseManagers(false);
        setActiveSupport(false);
        setActiveGuardian(false);
        break;
    }
  };

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      {isLoading ? (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <Tab.Container defaultActiveKey="org_admin">
            <Row>
              <PageTitle
                title={"Users (" + numberUsers + ")"}
                buttonTitle="Create User"
                onButtonClick={CreateNewUser}
                descriptionText={currentDescText}
              />
            </Row>
            <Row className="pt-2">
              <Col xs={12} sm={12} md={3}>
                <Nav
                  variant="pills"
                  className={
                    showMenu
                      ? "flex-column sticky-top mycase-menu-open-sticky"
                      : "flex-column sticky-top mycase-menu-sticky"
                  }
                >
                  <Nav.Item className="d-grid">
                    <Nav.Link
                      eventKey="org_admin"
                      className={activeOrgAdmin ? "nav-vertical-active" : "nav-vertical"}
                      onClick={() => onNavClick("org_admin")}
                    >
                      <>
                        <FontAwesomeIcon icon={faUserLock} className={"me-2"} />
                        {toggleMenu ? "Org Admin (" + orgAdminUsers.length + ")" : "Org Admin"}
                      </>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="d-grid">
                    <Nav.Link
                      eventKey="admin"
                      className={activeAdmin ? "nav-vertical-active" : "nav-vertical"}
                      onClick={() => onNavClick("admin")}
                    >
                      <>
                        <FontAwesomeIcon icon={faUserCog} className={"me-2"} />
                        {toggleMenu ? "Admin (" + adminUsers.length + ")" : "Admin"}
                      </>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="d-grid">
                    <Nav.Link
                      eventKey="case_manager"
                      className={activeCaseManagers ? "nav-vertical-active" : "nav-vertical"}
                      onClick={() => onNavClick("case_manager")}
                    >
                      <>
                        <FontAwesomeIcon icon={faUser} className={"me-2"} />
                        {toggleMenu ? "Case Manager (" + caseManagerUsers.length + ")" : "Case Manager"}
                      </>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="d-grid">
                    <Nav.Link
                      eventKey="support"
                      className={activeSupport ? "nav-vertical-active" : "nav-vertical"}
                      onClick={() => onNavClick("support")}
                    >
                      <>
                        <FontAwesomeIcon icon={faUserTie} className={"me-2"} />
                        {toggleMenu ? "Support (" + supportUsers.length + ")" : "Support"}
                      </>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <hr />
                  </Nav.Item>
                  <Nav.Item className="d-grid">
                    <Nav.Link
                      eventKey="guardian"
                      className={activeGuardian ? "nav-vertical-active mb-3" : "nav-vertical mb-3"}
                      onClick={() => onNavClick("guardian")}
                    >
                      <>
                        <FontAwesomeIcon icon={faUserShield} className={"me-2"} />
                        {toggleMenu ? "Primary Adults" : "Primary"}
                      </>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col xs={12} sm={12} md={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="org_admin">
                    <UserTab
                      users={orgAdminUsers}
                      setUsers={setUsers}
                      type="org_admin"
                      displayType="Org Admin"
                      setShowToast={setShowToast}
                      setToastText={setToastText}
                      setToastBody={setToastBody}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="admin">
                    <UserTab
                      users={adminUsers}
                      setUsers={setUsers}
                      type="admin"
                      displayType="Admin"
                      setShowToast={setShowToast}
                      setToastText={setToastText}
                      setToastBody={setToastBody}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="case_manager">
                    <UserTab
                      users={caseManagerUsers}
                      setUsers={setUsers}
                      type="case_manager"
                      displayType="Case Manager"
                      setShowToast={setShowToast}
                      setToastText={setToastText}
                      setToastBody={setToastBody}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="support">
                    <UserTab
                      users={supportUsers}
                      setUsers={setUsers}
                      type="support"
                      displayType="Support"
                      setShowToast={setShowToast}
                      setToastText={setToastText}
                      setToastBody={setToastBody}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="guardian">
                    <GuardianTab users={guardianUsers} />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      )}
    </>
  );
};

export default Users;
