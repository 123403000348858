import React, { useEffect, useState } from "react";
import { Row, Col, Container, Stack, Button, Spinner } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchCaseStatuses, fetchUsers, patchCaseData, postCaseData, postUserData } from "../../services/httpClient";
import PageTitle from "../ui/PageTitle";
import SelectObject from "../ui/SelectObject";
import ToastMsg from "../ui/ToastMsg";
import Input from "../ui/Input";
import SaveChangesModal from "../ui/SaveChangesModal";
import { unsavedChanges, currentUrl } from "../../App";
import useApp from "../../utils/useApp";
import InputPhone from "../ui/InputPhone";
import FormCheck from "../ui/FormCheckbox";

const CreateCase = () => {
  const { showMenu }: any = useApp();
  const location = useLocation();
  currentUrl.value = location.pathname;
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<number | null>(null);
  const [suggestionOne, setSuggestionOne] = useState<string>("");
  const [suggestionTwo, setSuggestionTwo] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [primaryCM, setPrimaryCM] = useState<string>("");
  const [secondaryCM, setSecondaryCM] = useState<string>("");
  const [status, setStatus] = useState<string>("inactive");
  const [appAccess, setAppAccess] = useState<boolean>(false);
  const [cmUsers, setCmUsers] = useState<any[]>([]);
  const [statuses, setStatuses] = useState<any[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [isValidPhone, setIsValidPhone] = useState<boolean>(true);
  const [saveIsEnabled, setSaveIsEnabled] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");

  let navigate = useNavigate();

  const backCase = () => {
    if (saveIsEnabled) {
      setShowModal(true);
    } else {
      navigate(`/cases`);
    }
  };

  const editCase = (caseId: string) => {
    navigate(`/cases/case/${caseId}`);
  };

  useEffect(() => {
    getCaseManagers();
    getCaseStatuses();
  }, []);

  const getCaseManagers = () => {
    fetchUsers()
      .then((res: any) => {
        var filteredUsers = res[0].data.filter((i: any) => i.role === "org_admin" || i.role === "admin" || i.role === "case_manager");
        filteredUsers = filteredUsers.map((i: any) => {
          return {
            id: i.id,
            key: i.id,
            name: i.firstname + " " + i.lastname,
          };
        });
        setCmUsers(filteredUsers);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCaseStatuses = async () => {
    await fetchCaseStatuses().then((res: any) => {
      setStatuses(res[0].data);
    });
  };

  useEffect(() => {
    if (saveIsEnabled) {
      unsavedChanges.value = true;
    } else {
      unsavedChanges.value = false;
    }
  }, [saveIsEnabled]);

  const updateFirstName = (e: any) => {
    setFirstName(e.target.value);

    if (e.target.value && lastName && name) {
      if (appAccess) {
        if (isValidEmail && isValidPhone) {
          setSaveIsEnabled(true);
        } else {
          setSaveIsEnabled(false);
        }
      } else {
        setSaveIsEnabled(true);
      }
    } else {
      setSaveIsEnabled(false);
    }
  };

  const updateLastName = (e: any) => {
    setLastName(e.target.value);

    const suggestionOne = e.target.value + " Family";
    setSuggestionOne(suggestionOne);
    const suggestionTwo = e.target.value + ", " + firstName;
    setSuggestionTwo(suggestionTwo);

    if (e.target.value && firstName && name) {
      if (appAccess) {
        if (isValidEmail && isValidPhone) {
          setSaveIsEnabled(true);
        } else {
          setSaveIsEnabled(false);
        }
      } else {
        setSaveIsEnabled(true);
      }
    } else {
      setSaveIsEnabled(false);
    }
  };

  function validateEmail(email: string, access: boolean) {
    var regexp = /\S+@\S+\.\S+/;
    if (access) {
      return regexp.test(email);
    } else {
      return regexp.test(email) || email.length === 0;
    }
  }

  function validatePhone(phoneNum: string, access: boolean) {
    let length = phoneNum.length;
    if (access) {
      return length === 11;
    } else {
      return length === 11 || length === 1;
    }
  }

  const updateEmail = (e: any) => {
    setEmail(e.target.value);
    let validEmail = validateEmail(e.target.value, appAccess);

    if (validEmail) {
      setIsValidEmail(true);
      if (appAccess) {
        if (isValidPhone) {
          if (phone && firstName && lastName && name) {
            setSaveIsEnabled(true);
          } else {
            setSaveIsEnabled(false);
          }
        }
      } else {
        if (firstName && lastName && name) {
          if (validEmail && isValidPhone) {
            setSaveIsEnabled(true);
          } else {
            setSaveIsEnabled(false);
          }
        }
      }
    } else {
      setIsValidEmail(false);
      setSaveIsEnabled(false);
    }
  };

  const updatePhone = (e: any) => {
    setPhone(e);
    let validPhone = validatePhone(e, appAccess);

    if (validPhone) {
      setIsValidPhone(true);

      if (appAccess) {
        if (isValidEmail) {
          if (email && firstName && lastName && name) {
            setSaveIsEnabled(true);
          } else {
            setSaveIsEnabled(false);
          }
        }
      } else {
        if (firstName && lastName && name) {
          if (isValidEmail && validPhone) {
            setSaveIsEnabled(true);
          } else {
            setSaveIsEnabled(false);
          }
        }
      }
    } else {
      setIsValidPhone(false);
      setSaveIsEnabled(false);
    }
  };

  const updateAppAccess = () => {
    if (!appAccess) {
      const validEmail = validateEmail(email, true);
      const validPhone = validatePhone(phone ? phone.toString() : "", true);
      setIsValidEmail(validEmail);
      setIsValidPhone(validPhone);

      if (firstName && lastName && name && email && phone) {
        if (validEmail && validPhone) {
          setSaveIsEnabled(true);
        } else {
          setSaveIsEnabled(false);
        }
      } else {
        setSaveIsEnabled(false);
      }
    } else {
      if (firstName && lastName && name) {
        const validEmail = validateEmail(email, false);
        const validPhone = validatePhone(phone ? phone.toString() : "", false);
        setIsValidEmail(validEmail);
        setIsValidPhone(validPhone);

        if (validEmail && validPhone) {
          setSaveIsEnabled(true);
        } else {
          setSaveIsEnabled(false);
        }
      } else {
        setSaveIsEnabled(false);
      }
    }

    setAppAccess(!appAccess);
  };

  const updateName = (e: any) => {
    setName(e.target.value);

    if (e.target.value && firstName && lastName) {
      if (appAccess) {
        if (isValidEmail && isValidPhone) {
          setSaveIsEnabled(true);
        } else {
          setSaveIsEnabled(false);
        }
      } else {
        setSaveIsEnabled(true);
      }
    } else {
      setSaveIsEnabled(false);
    }
  };

  const updatePrimaryCM = (e: any) => {
    setPrimaryCM(e.target.value);
  };

  const updateSecondaryCM = (e: any) => {
    setSecondaryCM(e.target.value);
  };

  const updateCaseStatus = (e: any) => {
    setStatus(e.target.value);
  };

  const onClickSuggestionOne = () => {
    setName(suggestionOne);

    if (firstName && lastName) {
      if (appAccess) {
        if (isValidEmail && isValidPhone) {
          setSaveIsEnabled(true);
        } else {
          setSaveIsEnabled(false);
        }
      } else {
        setSaveIsEnabled(true);
      }
    }
  };

  const onClickSuggestionTwo = () => {
    setName(suggestionTwo);

    if (firstName && lastName) {
      if (appAccess) {
        if (isValidEmail && isValidPhone) {
          setSaveIsEnabled(true);
        } else {
          setSaveIsEnabled(false);
        }
      } else {
        setSaveIsEnabled(true);
      }
    }
  };

  const saveCase = () => {
    if (isValidEmail && isValidPhone) {
      setIsLoading(true);

      const newCase = {
        name: name,
        primaryCM: primaryCM,
        secondaryCM: secondaryCM,
        casestatus: status,
      };

      postCaseData(newCase)
        .then((res: any) => {
          const caseId = res[0].data.case_id;

          var updatedUser: any = {
            role: "family",
            appaccess: appAccess,
            email: email,
            firstname: firstName,
            lastname: lastName,
            phonenumber: phone == 1 ? null : phone,
            is_guardian: true,
          };

          postUserData(updatedUser)
            .then((res: any) => {
              const userId = res[0].data.user_id;

              const updatedCase = {
                family: [userId],
              };

              patchCaseData(updatedCase, caseId)
                .then((res: any) => {
                  setSaveIsEnabled(false);
                  setShowToast(true);
                  setToastText("Case Created");
                  setToastBody(name);
                  editCase(caseId);
                })
                .catch((err: any) => {
                  console.log(err);
                })
                .finally(() => {
                  setIsLoading(false);
                  unsavedChanges.value = false;
                });
            })
            .catch((err: any) => {
              console.log(err);
            });
        })
        .catch((err: any) => {
          console.log(err);
          setIsLoading(false);
          setErrMsg(err?.response?.data?.msg);
        });
    } else {
      setErrMsg("Email or Phone is in Invalid Format");
    }
  };

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      {isLoading ? (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p>
          <Row className="mb-3">
            <Col xs={6} className="text-start">
              <Button className={"app-primary-color button"} onClick={(e) => backCase()}>
                {"Back to Cases"}
              </Button>
            </Col>
            <Col xs={6} className="text-end">
              <Button id="pagetitle-button" className={"app-secondary-color button"} onClick={saveCase} disabled={!saveIsEnabled}>
                {"Save"}
              </Button>
            </Col>
          </Row>
          <PageTitle title={"Create Case"} />
          <Row>
            <Col xs={12} className="mb-3">
              <Stack gap={3}>
                <h4 className="app-header-text mb-0">Primary Adult</h4>
                <Row>
                  <Col xs={6} lg={4} xl={3}>
                    <Input label="First Name" type="text" value={firstName} onUpdate={(e: any) => updateFirstName(e)} required={true} />
                  </Col>
                  <Col xs={6} lg={4} xl={3}>
                    <Input label="Last Name" type="text" value={lastName} onUpdate={(e: any) => updateLastName(e)} required={true} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} lg={4} xl={3}>
                    <Input label="Email" type="text" value={email} onUpdate={(e: any) => updateEmail(e)} required={appAccess} />
                  </Col>
                  <Col xs={6} lg={4} xl={3}>
                    <InputPhone label="Phone" value={phone} onUpdate={(e: any) => updatePhone(e)} required={appAccess} />
                  </Col>
                  <Col xs={12} md={4} className="mt-2 mb-0">
                    <FormCheck label="Mobile App Access" value={appAccess} onUpdate={(e: any) => updateAppAccess()} />
                  </Col>
                </Row>
                <h4 className="app-header-text mb-0">Details</h4>
                {lastName && (
                  <Row>
                    <Col xs={12} className="d-flex gap-3">
                      <h6>Select a Suggested Case Name:</h6>
                      <h6 className="app-tertiary-color-inverse cursor-pointer" onClick={() => onClickSuggestionOne()}>
                        {suggestionOne}
                      </h6>
                      <h6 className="app-tertiary-color-inverse cursor-pointer" onClick={() => onClickSuggestionTwo()}>
                        {suggestionTwo}
                      </h6>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs={12} lg={8} xl={6}>
                    <Input label="Name" type="text" value={name} onUpdate={(e: any) => updateName(e)} required={true} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} lg={4} xl={3}>
                    <SelectObject
                      label="Primary Case Manager"
                      value={primaryCM}
                      options={cmUsers}
                      onUpdate={(e: any) => updatePrimaryCM(e)}
                    />
                  </Col>
                  <Col xs={6} lg={4} xl={3}>
                    <SelectObject
                      label="Secondary Case Manager"
                      value={secondaryCM}
                      options={cmUsers}
                      onUpdate={(e: any) => updateSecondaryCM(e)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} lg={4} xl={3}>
                    <SelectObject
                      label="Status"
                      value={status}
                      options={statuses}
                      onUpdate={(e: any) => updateCaseStatus(e)}
                      skipInitialOption={true}
                    />
                  </Col>
                </Row>
              </Stack>
            </Col>
          </Row>
          <SaveChangesModal showModal={showModal} setShowModal={setShowModal} path="/cases" />
        </Container>
      )}
    </>
  );
};

export default CreateCase;
