import React, { useState } from "react";
import { Button, Card, Col, OverlayTrigger, Row, Stack, Tooltip } from "react-bootstrap";
import { unsavedChanges } from "../../App";
import Textarea from "../ui/Textarea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import useApp from "../../utils/useApp";

interface CardState {
  item: any;
  setInfractions: any;
  submitted: boolean;
  setOrgToSave: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
}

const BedInspectionCard = ({ item, setInfractions, submitted, setOrgToSave, setShowToast, setToastText, setToastBody }: CardState) => {
  const { auth }: any = useApp();
  const [failed, setFailed] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [infractionSaved, setInfractionSaved] = useState<boolean>(false);
  const [note, setNote] = useState<string>("");
  const [prevNote, setPrevNote] = useState<string>("");

  const onClickFail = () => {
    setFailed(true);
  };

  const onClickEdit = () => {
    setPrevNote(note);
    setIsEditing(true);
  };

  const updateNote = (e: any) => {
    setNote(e.target.value);
    unsavedChanges.value = true;
    setOrgToSave(true);
  };

  const onClickSaveNote = (editing: boolean) => {
    const today = new Date();
    const todayString = today.toISOString().split("T")[0];

    const newNote = {
      notetype: "infraction",
      subject: {
        id: item.occupant_id,
        type: item.occupant_type,
      },
      fields: {
        type: "Bed",
        description: note,
        date: todayString,
      },
    };

    if (editing) {
      setInfractions((prev: any) =>
        prev.map((p: any) =>
          p.id === item.id
            ? {
                note: { ...newNote },
                ...newNote,
                id: item.id,
                case_id: item.case_id,
                firstname: auth?.profile.firstname + " " + auth?.profile.lastname,
                subject: { ...newNote.subject, name: item.name + " - " + item.firstname + " " + item.lastname, id: item.id },
              }
            : p
        )
      );
    } else {
      setInfractions((prev: any) => [
        ...prev,
        {
          note: { ...newNote },
          ...newNote,
          id: item.id,
          case_id: item.case_id,
          firstname: auth?.profile.firstname + " " + auth?.profile.lastname,
          subject: { ...newNote.subject, name: item.name + " - " + item.firstname + " " + item.lastname, id: item.id },
        },
      ]);
    }

    setPrevNote(note);
    setInfractionSaved(true);
    setFailed(false);
    setIsEditing(false);
  };

  const onClickCancelNote = () => {
    if (isEditing) {
      setNote(prevNote);
      setIsEditing(false);
    } else {
      setNote("");
      unsavedChanges.value = false;
      setOrgToSave(false);
      setFailed(false);
    }
  };

  return (
    <>
      <Card key={item.name} className="bg-light mb-0">
        <Card.Body className="p-2">
          <Stack direction="horizontal" gap={3}>
            <Stack gap={2} className="align-self-center">
              <Card.Subtitle className="text-muted">
                <small>{item.name}</small>
              </Card.Subtitle>
            </Stack>
            {!item.occupant_id && <small className="fw-light fst-italic p-0 m-0">{"Vacant"}</small>}
            {infractionSaved ? (
              <>
                <small className="app-danger-color-inverse bg-light">Infraction Reported</small>
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Edit</Tooltip>}>
                  <FontAwesomeIcon className="text-muted me-1 cursor-pointer" icon={faEdit} onClick={(e) => onClickEdit()} />
                </OverlayTrigger>
              </>
            ) : (
              item.occupant_id && (
                <Button className={"app-danger-color button"} onClick={() => onClickFail()} disabled={submitted}>
                  Fail
                </Button>
              )
            )}
          </Stack>
        </Card.Body>
      </Card>
      {(failed || isEditing) && (
        <>
          <Row className="align-items-center mb-3">
            <Col xs={12}>
              <Textarea label="Infraction Note" value={note} onUpdate={(e: any) => updateNote(e)} />
            </Col>
          </Row>
          <Row className="mb-3 ms-0">
            <Col xs={8}>
              <Stack direction="horizontal" gap={3}>
                <Button className="app-secondary-color button" onClick={() => onClickSaveNote(isEditing)}>
                  Save
                </Button>
                <div className="vr" />
                <Button className="app-primary-color button" onClick={() => onClickCancelNote()}>
                  Cancel
                </Button>
              </Stack>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default BedInspectionCard;
