import React, { useEffect, useState } from "react";
import { Card, Col, Row, Image, Stack } from "react-bootstrap";
import { User } from "../../../../types/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faInfoCircle, faUserShield } from "@fortawesome/free-solid-svg-icons";

interface HouseholdCardState {
  user: User;
  setEditGuardian: any;
  setEditChild: any;
  setEditHousehold: any;
  setCreate: any;
  currentUser: User | undefined;
  setCurrentUser: any;
  userToSave: boolean;
  setShowModal: any;
}

const HouseholdCard = ({
  user,
  setEditGuardian,
  setEditChild,
  setEditHousehold,
  setCreate,
  currentUser,
  setCurrentUser,
  userToSave,
  setShowModal,
}: HouseholdCardState) => {
  const [age, setAge] = useState<number | null>(15);
  const [numberGroups, setNumberGroups] = useState<number>(0);
  const [groups, setGroups] = useState<string[]>([]);
  const [showGroupIcon, setShowGroupIcon] = useState<boolean>(false);
  const [profileImage, setProfileImage] = useState<string>("");

  useEffect(() => {
    user?.profile?.map((p: any) => {
      const base64String = "data:image/jpeg;base64, " + p?.data;
      setProfileImage(base64String);
    });

    // Check if birthdate is valid
    if (user?.birthdate && user?.birthdate !== "0000-00-00") {
      const calculateAge = (birthdate: string) => {
        const birthDateObj = new Date(birthdate);
        const today = new Date();

        let age = today.getFullYear() - birthDateObj.getFullYear();
        const monthDiff = today.getMonth() - birthDateObj.getMonth();

        // Adjust age if the birthdate hasn't occurred yet this year
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
          age--;
        }
        return age;
      };

      setAge(calculateAge(user.birthdate));
    } else {
      setAge(null); // Don't calculate age if birthdate is invalid
    }

    if (user.groups) {
      setNumberGroups(user?.groups?.length);
      const firstTwo = user.groups.slice(0, 2);
      setGroups(firstTwo);
      if (user?.groups?.length > 2) {
        setShowGroupIcon(true);
      }
    }
  }, [user]);

  const handleOnClick = () => {
    if (userToSave) {
      setShowModal(true);
    } else {
      if (currentUser?.id === user?.id && currentUser?.relationship === user?.relationship) {
        setCurrentUser(undefined);
      } else {
        setCurrentUser(user);
      }

      if (currentUser?.id === user?.id && currentUser?.relationship === user?.relationship) {
        setEditGuardian(false);
        setEditChild(false);
        setEditHousehold(false);
      } else if (user.is_guardian) {
        setEditGuardian(true);
        setEditChild(false);
        setEditHousehold(false);
      } else if (user.relationship === "child") {
        setEditGuardian(false);
        setEditChild(true);
        setEditHousehold(false);
      } else {
        setEditGuardian(false);
        setEditChild(false);
        setEditHousehold(true);
      }

      setCreate(false);
      setShowModal(false);
    }
  };

  const handleViewAllGroups = (e: any) => {
    e.stopPropagation();

    if (user.groups) {
      setGroups(user.groups);
      setShowGroupIcon(false);
    }
  };

  return (
    <Card
      className={user?.is_banned ? "bg-light card-household card-danger w-100 h-100" : "bg-light card-household w-100 h-100"}
      onClick={handleOnClick}
    >
      <Card.Body className="d-flex flex-column">
        <Row className="me-0">
          <Col className="col-12">
            <h5
              className={
                currentUser?.id === user?.id && currentUser?.relationship === user?.relationship ? "app-orange-text" : "app-header-text"
              }
            >
              {user.firstname} {user.preferredname ? "'" + user.preferredname + "'" : ""} {user.lastname}
            </h5>
          </Col>
        </Row>
        <Row>
          <Col className="col-12 text-end">
            {user?.is_banned === 1 && (
              <div className="position-absolute top-0 end-0">
                <Stack direction="horizontal">
                  <small className="small-text app-danger-text me-1 mt-2">Banned</small>
                  <FontAwesomeIcon icon={faBan} className="app-danger-text me-2 mt-2" />
                </Stack>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col className={"col-8"}>
            {user.relationship === "child" ? (
              <Row className="mb-1">
                <h6 className="text-muted first-letter mb-0">
                  {user?.grade && age ? `Grade ${user?.grade} - Age ${age}` : user?.grade ? `Grade ${user?.grade}` : age && `Age ${age}`}
                </h6>
              </Row>
            ) : (
              <Row className="mb-1">
                <h6 className="text-muted first-letter mb-0">{user.relationship}</h6>
              </Row>
            )}
            <Row>
              <p className="pt-0 pb-0 mb-0">
                <small>{user.email}</small>
              </p>
              <p className="pt-0 pb-0 mb-0">
                <small>
                  {user.phonenumber === "1"
                    ? ""
                    : user.phonenumber
                    ? user.phonenumber.replace(/(\d{1})(\d{3})(\d{3})(\d{3})/, "+$1 ($2) $3-$4")
                    : ""}
                </small>
              </p>
              <Row>
                <hr className="ms-2 mt-2 mb-2" />
              </Row>
              {numberGroups > 0 &&
                groups.map((g: any, index: number) => (
                  <p key={index} className="pt-0 pb-0 mb-0 text-muted">
                    <small>{g.name}</small>
                    {index === 1 && showGroupIcon && (
                      <FontAwesomeIcon icon={faInfoCircle} className="ms-2 z-index-custom" onClick={(e: any) => handleViewAllGroups(e)} />
                    )}
                  </p>
                ))}
            </Row>
          </Col>
          <Col className="col-4">
            {profileImage ? <Image src={profileImage} thumbnail /> : <Image src="/blank-profile-picture.jpg" thumbnail />}
          </Col>
        </Row>
        {user.is_guardian === 1 && (
          <Row>
            {/* <Col className="col-2 text-start">
              {user?.is_banned === 1 && (
                <div className="position-absolute bottom-0 start-0">
                  <Stack direction="horizontal">
                    <FontAwesomeIcon icon={faBan} className="app-danger-text ms-2 mb-2" />
                    <small className="small-text app-danger-text ms-1 mb-2">Banned</small>
                  </Stack>
                </div>
              )}
            </Col> */}
            <Col className="col-12 text-end">
              {user.is_primary ? (
                <div className="position-absolute bottom-0 end-0">
                  <Stack direction="horizontal">
                    <small className="small-text app-primary-color-inverse me-1 mb-2 bg-light">Main Contact</small>
                    <FontAwesomeIcon icon={faUserShield} className="app-primary-color-inverse me-2 mb-2" />
                  </Stack>
                </div>
              ) : (
                <div className="position-absolute bottom-0 end-0">
                  <Stack direction="horizontal">
                    <text className="p-0 m-0 me-2 app-primary-color-inverse">
                      <small> </small>
                    </text>
                    <FontAwesomeIcon icon={faUserShield} className="app-primary-color-inverse me-2 mb-2" />
                  </Stack>
                </div>
              )}
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default HouseholdCard;
