import React, { useEffect, useState } from "react";
import { Row, Col, Container, Stack, Button, Spinner } from "react-bootstrap";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { fetchCase, fetchCaseStatuses, fetchUsers, patchCaseData, postCaseData } from "../../services/httpClient";
import PageTitle from "../ui/PageTitle";
import SelectObject from "../ui/SelectObject";
import ToastMsg from "../ui/ToastMsg";
import CreateFamilyGuardian from "./CreateFamilyGuardian";
import FamilyGuardianCard from "./FamilyGuardianCard";
import Input from "../ui/Input";
import SaveChangesModal from "../ui/SaveChangesModal";
import { unsavedChanges, currentUrl } from "../../App";
import useApp from "../../utils/useApp";
import states from "../../data/states.json";

interface State {
  key: string;
  name: string;
}

const EditCase = () => {
  const { showMenu }: any = useApp();
  const location = useLocation();
  currentUrl.value = location.pathname;
  const { id } = useParams();
  const [editMode, setEditMode] = useState(id !== undefined ? true : false);
  const [name, setName] = useState<string>("");
  const [primaryCM, setPrimaryCM] = useState<string>("");
  const [secondaryCM, setSecondaryCM] = useState<string>("");
  const [status, setStatus] = useState<string>("inactive");
  const [family, setFamily] = useState<any[]>([]);
  const [addressOne, setAddressOne] = useState<string>("");
  const [addressTwo, setAddressTwo] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const statesList: State[] = states;
  const [cmUsers, setCmUsers] = useState<any[]>([]);
  const [statuses, setStatuses] = useState<any[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showFamilySection, setShowFamilySection] = useState<boolean>(false);
  const [showCreateFamily, setShowCreateFamily] = useState<boolean>(false);
  const [saveIsEnabled, setSaveIsEnabled] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");

  const heading = editMode ? "Edit Case" : "Create Case";

  let navigate = useNavigate();

  const backCase = () => {
    if (saveIsEnabled) {
      setShowModal(true);
    } else {
      navigate(`/cases`);
      setShowCreateFamily(false);
    }
  };

  const editCase = (caseId: string) => {
    navigate(`/cases/case/${caseId}`);
  };

  useEffect(() => {
    getCase();
  }, []);

  const getCase = () => {
    var users: any[] = [];
    setIsLoading(true);
    getCaseStatuses();

    if (editMode) {
      setShowFamilySection(true);

      fetchCase(id)
        .then((res: any) => {
          const currentCase = res[0].data;
          setName(currentCase.name);
          setPrimaryCM(currentCase.primaryCM?.user_id);
          setSecondaryCM(currentCase.secondaryCM?.user_id);
          setStatus(currentCase.casestatus);
          setAddressOne(currentCase.address_line_one);
          setAddressTwo(currentCase.address_line_two);
          setCity(currentCase.city);
          setState(currentCase.state);
          setZip(currentCase.zip);

          fetchUsers()
            .then((res: any) => {
              users = res[0].data;
              var filteredUsers = res[0].data.filter((i: any) => i.role === "org_admin" || i.role === "admin" || i.role === "case_manager");
              filteredUsers = filteredUsers.map((i: any) => {
                return {
                  id: i.id,
                  key: i.id,
                  name: i.firstname + " " + i.lastname,
                };
              });
              setCmUsers(filteredUsers);

              var guardians: any[] = [];
              currentCase.family.map((f: any) => {
                const filteredGuardian = users.filter((u: any) => {
                  return u.id === f.user_id;
                });

                guardians.push(filteredGuardian[0]);
              });
              setFamily(guardians);
            })
            .finally(() => {
              setIsLoading(false);
            });
        })
        .catch((err: any) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      fetchUsers()
        .then((res: any) => {
          users = res[0].data;
          var filteredUsers = res[0].data.filter((i: any) => i.role === "org_admin" || i.role === "admin" || i.role === "case_manager");
          filteredUsers = filteredUsers.map((i: any) => {
            return {
              id: i.id,
              key: i.id,
              name: i.firstname + " " + i.lastname,
            };
          });
          setCmUsers(filteredUsers);
        })
        .catch((err: any) => {
          console.log(err);
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (saveIsEnabled) {
      unsavedChanges.value = true;
    } else {
      unsavedChanges.value = false;
    }
  }, [saveIsEnabled]);

  const getCaseStatuses = async () => {
    await fetchCaseStatuses().then((res: any) => {
      setStatuses(res[0].data);
    });
  };

  const saveCase = () => {
    setIsLoading(true);

    const updatedCase = {
      name: name,
      primaryCM: primaryCM,
      secondaryCM: secondaryCM,
      casestatus: status,
      address_line_one: addressOne,
      address_line_two: addressTwo,
      city: city,
      state: state,
      zip: zip,
      family: family.map((i: any) => {
        return i.id;
      }),
    };

    if (editMode) {
      patchCaseData(updatedCase, id)
        .then((res: any) => {
          setSaveIsEnabled(false);
          setShowToast(true);
          setToastText("Case Updated");
          setToastBody(name);
        })
        .catch((err: any) => {
          console.log(err);
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      postCaseData(updatedCase)
        .then((res: any) => {
          setSaveIsEnabled(false);
          setShowToast(true);
          setToastText("Case Created");
          setToastBody(name);
          setShowFamilySection(true);
          setShowCreateFamily(true);
          setIsLoading(false);
          setEditMode(true);
          editCase(res[0].data?.case_id);
        })
        .catch((err: any) => {
          console.log(err);
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const updateName = (e: any) => {
    setName(e.target.value);
    if (e.target.value) {
      setSaveIsEnabled(true);
    } else {
      setSaveIsEnabled(false);
    }
  };

  const updatePrimaryCM = (e: any) => {
    setPrimaryCM(e.target.value);
    enableSave();
  };

  const updateSecondaryCM = (e: any) => {
    setSecondaryCM(e.target.value);
    enableSave();
  };

  const updateCaseStatus = (e: any) => {
    setStatus(e.target.value);
    enableSave();
  };

  const updateAddressOne = (e: any) => {
    setAddressOne(e.target.value);
    enableSave();
  };

  const updateAddressTwo = (e: any) => {
    setAddressTwo(e.target.value);
    enableSave();
  };

  const updateCity = (e: any) => {
    setCity(e.target.value);
    enableSave();
  };

  const updateState = (e: any) => {
    setState(e.target.value);
    enableSave();
  };

  const updateZip = (e: any) => {
    setZip(e.target.value);
    enableSave();
  };

  const onClickCreateFamily = () => {
    setShowCreateFamily(true);
  };

  function enableSave() {
    if (name) {
      setSaveIsEnabled(true);
    } else {
      setSaveIsEnabled(false);
    }
  }

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      {isLoading ? (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p>
          <Row className="mb-3">
            <Col xs={6} className="text-start">
              <Button className={"app-primary-color button"} onClick={(e) => backCase()}>
                {"Back to Cases"}
              </Button>
            </Col>
            <Col xs={6} className="text-end">
              <Button id="pagetitle-button" className={"app-secondary-color button"} onClick={saveCase} disabled={!saveIsEnabled}>
                {"Save"}
              </Button>
            </Col>
          </Row>
          <PageTitle title={heading} />
          <Row>
            <Col xs={12} lg={6} className="mb-3">
              <h4 className="app-header-text mt-0">Details</h4>
              <Stack gap={3}>
                <Input label="Name" type="text" value={name} onUpdate={(e: any) => updateName(e)} required={true} />
                <Row>
                  <Col xs={6}>
                    <SelectObject
                      label="Primary Case Manager"
                      value={primaryCM}
                      options={cmUsers}
                      onUpdate={(e: any) => updatePrimaryCM(e)}
                    />
                  </Col>
                  <Col xs={6}>
                    <SelectObject
                      label="Secondary Case Manager"
                      value={secondaryCM}
                      options={cmUsers}
                      onUpdate={(e: any) => updateSecondaryCM(e)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <SelectObject
                      label="Status"
                      value={status}
                      options={statuses}
                      onUpdate={(e: any) => updateCaseStatus(e)}
                      skipInitialOption={true}
                    />
                  </Col>
                </Row>
              </Stack>
            </Col>
            <Col xs={12} lg={6}>
              <h4 className="app-header-text">Address</h4>
              <Stack gap={3}>
                <Input label="Address Line One" type="text" value={addressOne} onUpdate={(e: any) => updateAddressOne(e)} />
                <Input label="Address Line Two" type="text" value={addressTwo} onUpdate={(e: any) => updateAddressTwo(e)} />
                <Row>
                  <Col xs={4}>
                    <Input label="City" type="text" value={city} onUpdate={(e: any) => updateCity(e)} />
                  </Col>
                  <Col xs={4}>
                    <SelectObject label="State" value={state} options={statesList} onUpdate={(e: any) => updateState(e)} />
                  </Col>
                  <Col xs={4}>
                    <Input label="Zip" type="text" value={zip} onUpdate={(e: any) => updateZip(e)} />
                  </Col>
                </Row>
              </Stack>
            </Col>
          </Row>
          {showFamilySection && (
            <>
              <h4 className="mt-3 app-header-text">Primary Adults</h4>
              {family.length > 0 && (
                <>
                  <Row className="mb-2">
                    {family.map((user: any, index: number) => (
                      <FamilyGuardianCard key={index} user={user} family={family} setFamily={setFamily} />
                    ))}
                  </Row>
                </>
              )}
              {!showCreateFamily && (
                <Row className="mb-5">
                  <Col xs={4} className="mb-5">
                    <Button className="app-tertiary-color me-3 button" onClick={(e) => onClickCreateFamily()}>
                      Create Primary Adult
                    </Button>
                  </Col>
                </Row>
              )}
            </>
          )}
          {showCreateFamily && <CreateFamilyGuardian setShowCreateFamily={setShowCreateFamily} family={family} setFamily={setFamily} />}
          <SaveChangesModal showModal={showModal} setShowModal={setShowModal} path="/cases" />
        </Container>
      )}
    </>
  );
};

export default EditCase;
