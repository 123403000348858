import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Card, Col, ListGroup, Row, Stack } from "react-bootstrap";
import { AssessmentType } from "../../../types/assessmenttype";
import AssessmentCardQuestion from "./AssessmentCardQuestion";

interface AssessmentState {
  type: AssessmentType;
}

const AssessmentCard = ({ type }: AssessmentState) => {
  const [showQuestions, setShowQuestions] = useState<boolean>(false);

  return (
    <>
      <Card className="bg-light mb-3" key={type?.id}>
        <Card.Body>
          <Row>
            <Col xs={12}>
              <Stack direction="horizontal" gap={3}>
                <Stack style={{ justifyContent: "center" }} className="col-6">
                  <h5 className="app-orange-text ms-3">{type.name}</h5>
                </Stack>
              </Stack>
            </Col>
          </Row>
          <Row>
            <h6 className="text-muted text-capitalize ms-3">{"Answer Scale: " + type?.question[0]?.fields[0]?.name} </h6>
          </Row>
          <Row>
            {type?.question?.length > 0 ? (
              <h6 className="ms-3 cursor-pointer" onClick={() => setShowQuestions(!showQuestions)}>
                Questions <FontAwesomeIcon icon={!showQuestions ? faAngleRight : faAngleDown} className="ms-2" />
              </h6>
            ) : (
              <h6 className="ms-3">Questions</h6>
            )}
          </Row>
          {showQuestions && (
            <Row className="mb-2">
              <ListGroup className="pe-0">
                {type?.question.map((t: any) => (
                  <AssessmentCardQuestion question={t} />
                ))}
              </ListGroup>
            </Row>
          )}
        </Card.Body>
      </Card>
      {/* )} */}
    </>
  );
};

export default AssessmentCard;
