import React, { useEffect, useState } from "react";
import { Button, Card, Stack } from "react-bootstrap";
import { unsavedChanges } from "../../App";

interface CardState {
  item: any;
  missingBeds: any;
  setMissingBeds: any;
  resetCards: boolean;
  setOrgToSave: any;
}

const BedOccupancyCard = ({ item, missingBeds, setMissingBeds, resetCards, setOrgToSave }: CardState) => {
  const [isMissing, setIsMissing] = useState<boolean>(false);

  useEffect(() => {
    setIsMissing(false);
  }, [resetCards]);

  const onClickMissing = () => {
    if (isMissing) {
      setIsMissing(false);
      if (missingBeds.length === 1) {
        unsavedChanges.value = false;
        setOrgToSave(false);
      } else {
        unsavedChanges.value = true;
        setOrgToSave(true);
      }
      setMissingBeds((prev: any) => prev.filter((bed: any) => bed.id !== item.id));
    } else {
      setIsMissing(true);
      setMissingBeds((prev: any) => [...prev, item]);
      unsavedChanges.value = true;
      setOrgToSave(true);
    }
  };

  return (
    <Card key={item.name} className="bg-light mb-0">
      <Card.Body className="p-2">
        <Stack direction="horizontal" gap={3}>
          <Stack gap={2} className="align-self-center">
            <Card.Subtitle className="text-muted">
              <small>{item.name}</small>
            </Card.Subtitle>
          </Stack>
          {!item.occupant_id && <small className="fw-light fst-italic p-0 m-0">{"Vacant"}</small>}
          {item.occupant_id && (
            <Button
              className={isMissing ? "app-tertiary-color button" : "app-tertiary-color-inverse button"}
              onClick={() => onClickMissing()}
            >
              Missing
            </Button>
          )}
        </Stack>
      </Card.Body>
    </Card>
  );
};

export default BedOccupancyCard;
