import React from "react";
import { Col, ListGroup, Row, Tab } from "react-bootstrap";

interface LikertState {
  fields?: any;
}

const LikertScalePreview = ({ fields }: LikertState) => {
  const LikertItem = ({ label }: any) => {
    return (
      <ListGroup.Item
        key={0}
        className="text-center d-flex align-items-center justify-content-center"
        action
        href={"#link" + 0}
        active={false}
        disabled={true}
      >
        {label}
      </ListGroup.Item>
    );
  };

  return (
    <Tab.Container>
      <Row className="gx-0">
        <Col className="col-12">
          <ListGroup horizontal>
            {fields.map((f: any, index: number) => (
              <LikertItem key={index} label={f?.value_description} />
            ))}
          </ListGroup>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default LikertScalePreview;
