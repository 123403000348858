import React from "react";
import { Card, Row } from "react-bootstrap";
import LikertScalePreview from "./LikertScalePreview";

interface PreviewState {
  qIndex: number;
  question: string;
  likertValues: any;
  hideHeading?: boolean;
}

const QuestionPreview = ({ qIndex, question, likertValues, hideHeading = false }: PreviewState) => {
  return (
    <Row className="mx-0 mt-2">
      {!hideHeading && <h6 className="ms-1">Question Preview</h6>}
      <Card className="bg-light mb-3">
        <Card.Body>
          <Row>
            <Row>
              <h5 className="pb-2 app-header-text">
                <label className="app-orange-text">{qIndex + 1 + "."}</label>
                {" " + question}
              </h5>
            </Row>
            <LikertScalePreview fields={likertValues} />
          </Row>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default QuestionPreview;
