import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import useApp from "../../../utils/useApp";
import { useLocation } from "react-router-dom";
import { currentUrl } from "../../../App";
import { fetchJobs, fetchPrograms } from "../../../services/httpClient";
import PageTitle from "../../ui/PageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Programs from "./Programs";
import Jobs from "./Jobs";
import SaveChangesModal from "../../ui/SaveChangesModal";

interface OrgHouseholdState {
  setOrgToSave: any;
  getData: boolean;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
}

const OrgHousehold = ({ setOrgToSave, getData, setShowToast, setToastText, setToastBody }: OrgHouseholdState) => {
  const { auth, showMenu }: any = useApp();
  const location = useLocation();
  currentUrl.value = location.pathname;
  const [errMsg, setErrMsg] = useState("");
  const [programs, setPrograms] = useState<any[]>([]);
  const [showPrograms, setShowPrograms] = useState(false);
  const [jobs, setJobs] = useState<any[]>([]);
  const [showJobs, setShowJobs] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (getData) {
      setIsLoading(true);
      getPrograms(false);
      getJobs();
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [getData]);

  useEffect(() => {
    getPrograms(false);
    getJobs();
  }, [auth?.profile]);

  const getPrograms = (show: boolean) => {
    fetchPrograms()
      .then((res: any) => {
        const progs = res[0].data;
        setShowPrograms(show);
        setPrograms(progs);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const getJobs = () => {
    fetchJobs()
      .then((res: any) => {
        const jobs = res[0].data;
        const groupedByDepartment = Object.values(
          jobs.reduce((acc: any, job: any) => {
            acc[job.department] = acc[job.department] || [];
            acc[job.department].push(job);
            return acc;
          }, {} as Record<string, any[]>)
        );
        setShowJobs(false);
        setJobs(groupedByDepartment);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <>
      {isLoading ? (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <>
          <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p>
          <PageTitle title="Household Config" type="section" />
          <>
            <Row className="mb-3">
              <Col xs={8}>
                <h4 className="app-header-text mt-2 mb-0" onClick={() => setShowPrograms(!showPrograms)}>
                  Programs
                  <FontAwesomeIcon icon={!showPrograms ? faAngleRight : faAngleDown} className="ms-2 cursor-pointer" />
                </h4>
              </Col>
            </Row>
            {showPrograms === true && (
              <Programs
                programs={programs}
                getPrograms={getPrograms}
                setOrgToSave={setOrgToSave}
                setErrMessage={setErrMsg}
                setShowToast={setShowToast}
                setToastText={setToastText}
                setToastBody={setToastBody}
              />
            )}
            <Row className="mb-3">
              <Col xs={8}>
                <h4 className="app-header-text mb-0" onClick={() => setShowJobs(!showJobs)}>
                  Jobs <FontAwesomeIcon icon={!showJobs ? faAngleRight : faAngleDown} className="ms-2 cursor-pointer" />
                </h4>
              </Col>
            </Row>
            {showJobs === true && (
              <Jobs
                jobs={jobs}
                getJobs={getJobs}
                setOrgToSave={setOrgToSave}
                setErrMessage={setErrMsg}
                setShowToast={setShowToast}
                setToastText={setToastText}
                setToastBody={setToastBody}
              />
            )}
          </>
          <SaveChangesModal showModal={showModal} setShowModal={setShowModal} path="/settings" />
        </>
      )}
    </>
  );
};

export default OrgHousehold;
