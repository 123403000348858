import React, { useEffect, useState } from "react";
import MyCasesToday from "./MyCasesToday";
import useApp from "../../utils/useApp";
import { Badge, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Case } from "../../types/case";
import CaseCard from "./CaseCard";

interface CaseViewState {
  allCases: Case[];
  myCases: Case[];
  otherCases: Case[];
  filteredMyCases: Case[];
  setFilteredMyCases: any;
  filteredOtherCases: Case[];
  setFilteredOtherCases: any;
  setSearchText: any;
  clearFilters: boolean;
  setCasesFiltered: any;
  caseStatus: string;
}

const CaseView = ({
  allCases,
  myCases,
  otherCases,
  filteredMyCases,
  setFilteredMyCases,
  filteredOtherCases,
  setFilteredOtherCases,
  setSearchText,
  clearFilters,
  setCasesFiltered,
  caseStatus,
}: CaseViewState) => {
  const { auth, viewAllCases }: any = useApp();
  const [assessFilterClicked, setAssessFilterClicked] = useState<boolean>(false);
  const [msgsFilterClicked, setMsgsFilterClicked] = useState<boolean>(false);
  const [nextFilterClicked, setNextFilterClicked] = useState<boolean>(false);

  useEffect(() => {
    if (assessFilterClicked || msgsFilterClicked || nextFilterClicked) {
      setCasesFiltered(true);
    } else {
      setCasesFiltered(false);
    }

    var myfCases = myCases;
    var otherfCases = otherCases;

    if (assessFilterClicked && msgsFilterClicked && nextFilterClicked) {
      myfCases = myfCases.filter((c: any) => {
        return c.assessment_due && c.new_messages && c.task_review;
      });
      otherfCases = otherfCases.filter((c: any) => {
        return c.assessment_due && c.new_messages && c.task_review;
      });

      setFilteredMyCases(myfCases);
      setFilteredOtherCases(otherfCases);
    } else if (assessFilterClicked && msgsFilterClicked && !nextFilterClicked) {
      myfCases = myfCases.filter((c: any) => {
        return c.assessment_due && c.new_messages;
      });
      otherfCases = otherfCases.filter((c: any) => {
        return c.assessment_due && c.new_messages;
      });

      setFilteredMyCases(myfCases);
      setFilteredOtherCases(otherfCases);
    } else if (!assessFilterClicked && msgsFilterClicked && nextFilterClicked) {
      myfCases = myfCases.filter((c: any) => {
        return c.new_messages && c.task_review;
      });
      otherfCases = otherfCases.filter((c: any) => {
        return c.new_messages && c.task_review;
      });

      setFilteredMyCases(myfCases);
      setFilteredOtherCases(otherfCases);
    } else if (assessFilterClicked && !msgsFilterClicked && nextFilterClicked) {
      myfCases = myfCases.filter((c: any) => {
        return c.assessment_due && c.task_review;
      });
      otherfCases = otherfCases.filter((c: any) => {
        return c.assessment_due && c.task_review;
      });

      setFilteredMyCases(myfCases);
      setFilteredOtherCases(otherfCases);
    } else if (assessFilterClicked && !msgsFilterClicked && !nextFilterClicked) {
      myfCases = myfCases.filter((c: any) => {
        return c.assessment_due;
      });
      otherfCases = otherfCases.filter((c: any) => {
        return c.assessment_due;
      });

      setFilteredMyCases(myfCases);
      setFilteredOtherCases(otherfCases);
    } else if (!assessFilterClicked && msgsFilterClicked && !nextFilterClicked) {
      myfCases = myfCases.filter((c: any) => {
        return c.new_messages;
      });
      otherfCases = otherfCases.filter((c: any) => {
        return c.new_messages;
      });

      setFilteredMyCases(myfCases);
      setFilteredOtherCases(otherfCases);
    } else if (!assessFilterClicked && !msgsFilterClicked && nextFilterClicked) {
      myfCases = myfCases.filter((c: any) => {
        return c.task_review;
      });
      otherfCases = otherfCases.filter((c: any) => {
        return c.task_review;
      });

      setFilteredMyCases(myfCases);
      setFilteredOtherCases(otherfCases);
    } else {
      setFilteredMyCases(myCases);
      setFilteredOtherCases(otherCases);
    }
  }, [assessFilterClicked, msgsFilterClicked, nextFilterClicked]);

  const onClickClearFilters = () => {
    setFilteredMyCases(myCases);
    setFilteredOtherCases(otherCases);
    setAssessFilterClicked(false);
    setMsgsFilterClicked(false);
    setNextFilterClicked(false);
    setSearchText("");
  };

  useEffect(() => {
    setAssessFilterClicked(false);
    setMsgsFilterClicked(false);
    setNextFilterClicked(false);
    setCasesFiltered(false);
  }, [clearFilters]);

  return (
    <>
      {auth?.profile?.role !== "support" && caseStatus === "active" && (
        <MyCasesToday
          cases={viewAllCases ? allCases : myCases}
          assessFilter={assessFilterClicked}
          setAssessFilter={setAssessFilterClicked}
          msgsFilter={msgsFilterClicked}
          setMsgsFilter={setMsgsFilterClicked}
          nextFilter={nextFilterClicked}
          setNextFilter={setNextFilterClicked}
        />
      )}
      <Row className="mt-3">
        {assessFilterClicked && (
          <Col className="col-12 text-center">
            <Badge className="app-tertiary-color-inverse" bg="none">
              Assessment Due
            </Badge>
            <Badge className="app-tertiary-color ms-1 cursor-pointer" bg="none" onClick={onClickClearFilters}>
              <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
            </Badge>
          </Col>
        )}
        {msgsFilterClicked && (
          <Col className="col-12 text-center">
            <Badge className="app-tertiary-color-inverse" bg="none">
              Unread Messages
            </Badge>
            <Badge className="app-tertiary-color ms-1 cursor-pointer" bg="none" onClick={onClickClearFilters}>
              <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
            </Badge>
          </Col>
        )}
        {nextFilterClicked && (
          <Col className="col-12 text-center">
            <Badge className="app-tertiary-color-inverse" bg="none">
              Next Task Review
            </Badge>
            <Badge className="app-tertiary-color ms-1 cursor-pointer" bg="none" onClick={onClickClearFilters}>
              <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
            </Badge>
          </Col>
        )}
      </Row>
      <Row>
        <Col></Col>
        <Col xs={9}>
          <hr className="mt-3" />
        </Col>
        <Col></Col>
      </Row>
      {viewAllCases && filteredOtherCases.length > 0 && (
        <>
          <Row>
            <Col></Col>
            <Col className="col-9">
              <h4 className="hero-heading app-header-text"> {"Other Cases (" + filteredOtherCases.length + ")"}</h4>
            </Col>
            <Col></Col>
          </Row>
          <Row className="justify-content-center mb-5">
            <Container className="card-container">
              {filteredOtherCases.map((c: any) => (
                <CaseCard
                  key={c.id}
                  myCase={c}
                  assessFilter={assessFilterClicked}
                  msgsFilter={msgsFilterClicked}
                  nextFilter={nextFilterClicked}
                  caseStatus={caseStatus}
                />
              ))}
            </Container>
          </Row>
        </>
      )}

      {viewAllCases && filteredMyCases.length > 0 && (
        <>
          <Row>
            <Col></Col>
            <Col xs={9}>
              <h4 className="hero-heading app-header-text"> {"My Cases (" + filteredMyCases.length + ")"}</h4>
            </Col>
            <Col></Col>
          </Row>
        </>
      )}

      <Row className="justify-content-center mb-5 pb-5">
        <Container className="card-container">
          {filteredMyCases.map((c: any) => (
            <CaseCard
              key={c.id}
              myCase={c}
              assessFilter={assessFilterClicked}
              msgsFilter={msgsFilterClicked}
              nextFilter={nextFilterClicked}
              caseStatus={caseStatus}
            />
          ))}
        </Container>
      </Row>
    </>
  );
};

export default CaseView;
